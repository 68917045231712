import React from "react";

function ImageIcon({ className }) {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.63412 18.3337H14.3675C16.6675 18.3337 17.5841 16.9253 17.6925 15.2087L18.1258 8.32533C18.2425 6.52533 16.8091 5.00033 15.0008 5.00033C14.4925 5.00033 14.0258 4.70866 13.7925 4.25866L13.1925 3.05033C12.8091 2.29199 11.8091 1.66699 10.9591 1.66699H9.05079C8.19246 1.66699 7.19246 2.29199 6.80912 3.05033L6.20912 4.25866C5.97579 4.70866 5.50912 5.00033 5.00079 5.00033C3.19246 5.00033 1.75912 6.52533 1.87579 8.32533L2.30912 15.2087C2.40912 16.9253 3.33412 18.3337 5.63412 18.3337Z"
        stroke="#235643"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.75 6.66699H11.25"
        stroke="#235643"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.99935 14.9997C11.491 14.9997 12.7077 13.783 12.7077 12.2913C12.7077 10.7997 11.491 9.58301 9.99935 9.58301C8.50768 9.58301 7.29102 10.7997 7.29102 12.2913C7.29102 13.783 8.50768 14.9997 9.99935 14.9997Z"
        stroke="#235643"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default ImageIcon;
