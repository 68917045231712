import React,{useState, useEffect} from 'react'
import logo from '../../assets/icons/logo-green.svg'
import playstore from '../../assets/icons/googleplaystore.svg'
import appstore from '../../assets/icons/appstore.svg'
import { Form, Formik } from 'formik'
import { BuyCardRateValidator, SellCardValidator } from '../../validationSchema/validator'
import { getAvailableCountries, getBuyGiftcardByCountry, getCategories, getGiftcardCategories, getSpecificGiftcard } from '../../redux/Trade/tradeActions'
import { useSelector, useDispatch } from 'react-redux'
import chevron_grey from '../../assets/icons/chevron_grey.svg'
import { Link, useNavigate } from 'react-router-dom'
import { clearBuyGiftcards } from '../../redux/Trade/trade'


const RatesPage = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
   
    const {categories, giftcards, buygiftcards,countries} = useSelector((state) => state.trade);

    const [tagId, setTagId] =  useState(1)
    const types = [
        {
            id: 1,
            name: 'Sell Card'
        },
        {
            id: 2,
            name: 'Buy Card'
        }
    ]

    const [cardSelected, setCardSelected] = useState("")
    const [rateInfo, setRateInfo] = useState({})
    const [payoutAmount, setPayoutAmount] = useState(0)
    const [buyAmount, setBuyAmount] = useState(0)
    const [cardUnit, setCardUnit] = useState([])
    const [rate, setRate] = useState(0)
    const [cardUnitSelected, setCardUnitSelected] = useState(null)
    const [quantity, setQuantity] = useState(1)

    const toggleTag =  (val) =>{
        setTagId(val)
        setCardSelected("")
        setPayoutAmount(0)
        setBuyAmount(0)
        if(val === 2){
           dispatch(getAvailableCountries())
           setCardUnit([])
           dispatch(clearBuyGiftcards())
         }
    }


    const handleCategory = (val) =>{
        if(tagId === 1){
            dispatch(getGiftcardCategories({
                type: 'sell',
                id: val
            }))
        }
        else{
            dispatch(getGiftcardCategories({
                type: 'buy',
                id: val
            }))
        } 
    }

    const handleGiftcard = (id) =>{
        let card = giftcards.find((val => val.id === parseInt(id)))
        let cardName = giftcards.find((val => val.id === parseInt(id))).name
        setCardSelected(cardName)
        setRateInfo({
            ...rateInfo,
            rate: card.rate,
            GHSRate: card.rates.GHS,
            NGNRate: card.rates.NGN
        })
        // get a specific card
        dispatch(getSpecificGiftcard({
            id: id
        }))
    }

    const handleCountrySelected = (countryCode) =>{
        setCardUnit([])
        setQuantity(1)
        setCardUnitSelected(0)
        setCardSelected("")
        dispatch(getBuyGiftcardByCountry({
            isoName: countryCode
        }))
    }


    const handleBuyGiftcard = (id) =>{
        let card = buygiftcards.find((val => val.id === parseInt(id)))
        let cardName = buygiftcards.find((val => val.id === parseInt(id))).name
        setCardSelected(cardName) 
        setCardUnit(card.denominations)
    
        // get rate of the card selected
        setRate(card.rate)
    }

    const handleCardUnit = (val) =>{
        setCardUnitSelected(parseInt(val))
    }

  

    const handleQuantity = (val) =>{
        setQuantity(parseInt(val))
    }

    useEffect(()=>{
        if(quantity > 0){
            setBuyAmount(quantity * cardUnitSelected * rate)
        }
    },[quantity, cardUnitSelected])


    const handleCalculation = (val, wallet, card) =>{
        if(val === "" || card === ""){
            setPayoutAmount(0)
        }
        else{
            if(wallet === "GHS"){
                    let amt = parseFloat(val) * rateInfo.rate / rateInfo.GHSRate
                    setPayoutAmount(amt.toFixed(2))  
            }
            else{
                let amt = parseFloat(val) * rateInfo.rate / rateInfo.NGNRate
                setPayoutAmount(amt.toFixed(2))
            } 
        }  
    }


    
    const handlePayoutSelected = (currency,card, amount) =>{
        if(card && amount !== ""){
            if(currency === "GHS"){
                let amt = parseFloat(amount) * rateInfo.rate / rateInfo.GHSRate
                setPayoutAmount(amt.toFixed(2))  
            }
            else{
                let amt = parseFloat(amount) * rateInfo.rate / rateInfo.NGNRate
                setPayoutAmount(amt.toFixed(2))
            }
        }
    }

  

    const numberWithCommas = (x) => { 
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); 
    }

    const ProceedTrade = (values)=>{
       console.log(values)
       navigate('/register')
    }

    const BuyGiftcard = (values) =>{
        console.log(values)
        navigate('/register') 
    }

    useEffect(()=>{
        dispatch(getCategories())
        dispatch(getAvailableCountries())
    },[dispatch])



    return ( 
        <>
        <div className='py-6 px-6 md:px-0 container mx-auto'>
            <Link to="/">
             <img src={logo} className='' alt="logo" />
            </Link>

            <div className='flex flex-col md:flex-row items-center gap-4 mt-10'>
                    <div className='w-full md:basis-2/5 order-2 md:order-1 mb-14 md:mb-0'>
                        <div className='max-w-md mt-10 md:mt-0'>
                            <h1 className='text-[#235643] md:leading-[64px] text-center font-Satoshi700 text-2xl md:text-5xl'>
                            We’ve got <br/> real-time rates
                            </h1>
                            <p className='text-[#909090] font-Satoshi400 text-sm mt-3 text-center'>
                            The rates displayed on Cardvest are constantly <br/> updated to align with current market rates.
                            </p>
                            <div className='flex justify-center gap-3 mt-6'>
                                <a href="https://play.google.com/store/apps/details?id=com.flipdigitals.cardvest" target="_blank" rel="noreferrer">
                                <img src={playstore} alt="playstore" />
                                </a>
                                <a href='https://apps.apple.com/ng/app/cardvest/id1598288580' target="_blank" rel="noreferrer">
                                <img src={appstore} alt="appstore" />
                                </a>
                            </div>
                        </div>
                       
                    </div>
                    <div className='w-full md:basis-3/5 order-1 md:order-2'>

                        <ul className='flex'>
                                {
                            types.map((val)=>(
                                <li key={val.id} onClick={() => toggleTag(val.id)} 
                                    className={
                                        tagId === val.id ?
                                        "border-b-[#235643] border-b-4 flex-1 cursor-pointer"
                                        :
                                        "flex-1 border-b-[#D9D9D9] border-b-4 cursor-pointer"
                                    }
                                    >
                                <p className={
                                    tagId === val.id
                                    ?
                                    "text-[#235643] font-Satoshi700 text-sm uppercase text-center mb-1"
                                    :
                                    "text-[#D9D9D9] font-Satoshi700 text-sm uppercase text-center mb-1"
                                }
                                >{val.name}</p>
                            </li>
                            ))  
                            }
                         </ul>

                         <div className='mt-8'>                 
                            <p className='text-[#909090] font-Satoshi500 mt-1 text-xs'>
                            Get the current value for your transaction
                            </p>
                         </div>

                         {tagId === 1 && 
                            <Formik
                                    onSubmit={(values, { setSubmitting }) =>
                                    ProceedTrade(values, setSubmitting)
                                    }
                                    validationSchema={SellCardValidator}
                                    initialValues={{
                                        category: '',
                                        giftcard: '',
                                        amount: '',
                                        wallet: 'NGN',
                                }}
                                >
                                    {({
                                    handleChange,
                                    isSubmitting,
                                    handleSubmit,
                                    handleBlur,
                                    setFieldValue,
                                    setFieldTouched,
                                    values,
                                    touched,
                                    errors,
                                    isValid,
                                    dirty
                                    }) => (
                                    <Form className="mt-10" onSubmit={handleSubmit}>
                                        <div className='grid md:grid-cols-2 gap-4 mt-10'>
                                        <div>
                                                <label htmlFor='category' className="block mb-2 font-Satoshi500 text-[#909090] text-xs">
                                                Gift Card<sup className='text-[#DC0000] text-xs top-[-0.2em] left-[-0.01em]'>*</sup>
                                                </label>
                                                <div className='relative'>
                                                    <select
                                                        type="text"
                                                        name="category"
                                                        value={values.category}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            handleCategory(e.currentTarget.value);
                                                            setFieldTouched('category', false)
                                                            setFieldValue('giftcard', "")
                                                            setFieldValue('amount', "")
                                                            setCardSelected("")
                                                            setPayoutAmount(0)
                                                        }}
                                                        placeholder="Select Giftcard"
                                                        className={
                                                            touched.category && errors.category
                                                                ? 'appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                                                : 'appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                                            }  
                                                    >
                                                        <option value="" selected disabled>Select Giftcard</option>
                                                        {categories.map((opt, index) => {
                                                                return <option key={index} value={opt.id}>{opt.name}</option>
                                                        })}   
                                                    </select>
                                                    <img className='absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer' src={chevron_grey} alt="arrow" />
                                                </div>
                                                {touched.category && errors.category ? (
                                                        <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                                            {touched.category && errors.category}
                                                        </small>
                                                ) : null}
                                            </div>
                                            <div>
                                                <label htmlFor='giftcard' className="block mb-2 font-Satoshi500 text-[#909090] text-xs">
                                                Category<sup className='text-[#DC0000] text-xs top-[-0.2em] left-[-0.01em]'>*</sup>
                                                </label>
                                                <div className='relative'>
                                                    <select
                                                        type="text"
                                                        name="giftcard"
                                                        value={values.giftcard}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            setFieldValue('amount', "")
                                                            handleGiftcard(e.currentTarget.value);
                                                            setPayoutAmount(0)
                                                            setFieldTouched('giftcard', false)
                                                        }}
                                                        placeholder="Select Category"
                                                        className={
                                                            touched.giftcard && errors.giftcard
                                                                ? 'appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                                                : 'appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                                            }   
                                                    >
                                                        <option value="" selected disabled>Select Category</option>
                                                        {giftcards.map((opt, index) => {
                                                                return <option key={index} value={opt.id}>{opt.name}</option>
                                                        })}   
                                                    </select>
                                                    <img className='absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer' src={chevron_grey} alt="arrow" />
                                                </div>
                                                {touched.giftcard && errors.giftcard ? (
                                                        <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                                            {touched.giftcard && errors.giftcard}
                                                        </small>
                                                ) : null}
                                            </div>
                                            <div className='mt-4'>
                                                <label htmlFor='amount' className="block mb-2 font-Satoshi500 text-[#909090] text-xs">
                                                Amount<sup className='text-[#DC0000] text-xs top-[-0.2em] left-[-0.01em]'>*</sup>
                                                </label>
                                                <input
                                                    type="number"
                                                    name="amount"
                                                    maxLength={10}
                                                    placeholder="100"
                                                    value={values.amount}
                                                    className={
                                                        touched.amount && errors.amount
                                                            ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                                            : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                                    } 
                                                    onChange={(e) => {
                                                        handleChange(e)
                                                        setFieldTouched('amount', false)
                                                        handleCalculation(e.currentTarget.value, values.wallet, values.giftcard);
                                                    }}
                                                />
                                                {touched.amount && errors.amount ? (
                                                    <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                                                {touched.amount && errors.amount}
                                                            </small>
                                                ) : null}
                                            </div>
                                            <div className='mt-4'>
                                                <label htmlFor='wallet' className="block mb-2 font-Satoshi500 text-[#909090] text-xs">
                                                Currency
                                                </label>
                                                <div className='relative'>
                                                    <select
                                                        type="text"
                                                        name="wallet"
                                                        placeholder="Select Wallet"
                                                        className={
                                                            touched.wallet && errors.wallet
                                                                ? 'appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                                                : 'appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                                        } 
                                                        value={values.wallet}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            handlePayoutSelected(e.currentTarget.value, values.giftcard, values.amount);
                                                        }}  
                                                    >
                                                        <option value="NGN" selected>Naira (NGN)</option>
                                                        <option value="GHS">Cedis (GHS)</option>
                                                        
                                                    </select>
                                                    <img className='absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer' src={chevron_grey} alt="arrow" />
                                                </div>
                                                {touched.wallet && errors.wallet ? (
                                                        <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                                            {touched.wallet && errors.wallet}
                                                        </small>
                                                ) : null}
                                            </div>
                                            <div className='mt-4'>
                                                <p className='mb-2 text-[#909090] font-Satoshi500 text-xs'>Calculated Amount</p>
                                                <div className='bg-[#EEF0F2] py-4 px-6 mt-3'>
                                                <p className='text-[#212121] font-Satoshi500 text-sm'>{cardSelected ? cardSelected : 'Giftcard selected shows here'}</p>
                                                </div>
                                                    <div className='bg-[#F7F9FB] rounded-[4px] py-4 px-6 flex justify-between'>
                                                    <p className='text-[#212121] font-Satoshi500 text-sm'>Total</p>
                                                    <p className='text-[#212121] font-Satoshi500 text-sm'>{values.wallet} {payoutAmount === 0  ? "0.00" : numberWithCommas(payoutAmount)}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='grid md:grid-cols-2 gap-4'>
                                            <div className='mt-10 '>
                                                    <button 
                                                    disabled={false}
                                                    className='bg-[#235643] rounded-[4px]
                                                        disabled:bg-[#235643] 
                                                        disabled:opacity-[0.7]
                                                        text-sm
                                                        font-Satoshi400
                                                        justify-center w-full
                                                        flex items-center 
                                                        py-4 px-6 text-[#FFFFFF]
                                                        hover:bg-[#235643] hover:opacity-[0.9] transition-all'> 
                                                        Proceed to Trade
                                                    </button>
                                            </div>
                                        </div>
                                    </Form>
                                    )}
                            </Formik>
                            }

                            {tagId === 2 && 
                            <Formik
                                    onSubmit={(values, { setSubmitting }) =>
                                    BuyGiftcard(values, setSubmitting)
                                    }
                                    validationSchema={BuyCardRateValidator}
                                    initialValues={{
                                        country: "",
                                        giftcard: "",
                                        cardUnit: "",
                                        wallet: 'NGN',
                                        quantity: "1"
                                }}
                                >
                                    {({
                                    handleChange,
                                    isSubmitting,
                                    handleSubmit,
                                    handleBlur,
                                    setFieldValue,
                                    setFieldTouched,
                                    values,
                                    touched,
                                    errors,
                                    isValid,
                                    dirty
                                    }) => (
                                    <Form className="mt-10" onSubmit={handleSubmit}>
                                        <div className='grid md:grid-cols-2 gap-4 mt-10'>     
                                            <div>
                                                <label htmlFor='country' className="block mb-2 font-Satoshi500 text-[#909090] text-xs">
                                                Giftcard country<sup className='text-[#DC0000] text-xs top-[-0.2em] left-[-0.01em]'>*</sup>
                                                </label>
                                                <div className='relative'>
                                                      <select
                                                        type="text"
                                                        name="country"
                                                        placeholder="Select Country"
                                                        className={
                                                            touched.country && errors.country
                                                                ? 'appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                                                : 'appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                                        } 
                                                        value={values.country}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            setFieldValue('giftcard', "")
                                                            setFieldValue('cardUnit', "")
                                                            setFieldValue('quantity', 1)
                                                            handleCountrySelected(e.currentTarget.value);
                                                        }}  
                                                    >
                                                    <option value="" selected disabled>Select Country</option>
                                                    {countries.map((opt) => {
                                                                    return <option key={opt.isoName} value={opt.isoName}>{opt.name}</option>
                                                            })} 
                                                        
                                                    </select>
                                                    <img className='absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer' src={chevron_grey} alt="arrow" />
                                                </div>
                                                {touched.country && errors.country ? (
                                                        <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                                            {touched.country && errors.country}
                                                        </small>
                                                ) : null}
                                            </div>
                                            <div>
                                                <label htmlFor='giftcard' className="block mb-2 font-Satoshi500 text-[#909090] text-xs">
                                                Gift Card<sup className='text-[#DC0000] text-xs top-[-0.2em] left-[-0.01em]'>*</sup>
                                                </label>
                                                <div className='relative'>
                                                <select
                                                    type="text"
                                                    name="giftcard"
                                                    value={values.giftcard}
                                                    onChange={(e) => {
                                                        handleChange(e)
                                                        handleBuyGiftcard(e.currentTarget.value);
                                                    }}
                                                    placeholder="Select Giftcard"
                                                    className={
                                                        touched.giftcard && errors.giftcard
                                                            ? 'appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                                            : 'appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                                        }   
                                                >
                                                    <option value="" selected disabled>Select Giftcard</option>
                                                    {buygiftcards.map((opt, index) => {
                                                            return <option key={index} value={opt.id}>{opt.name}</option>
                                                    })}   
                                                </select>
                                                    <img className='absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer' src={chevron_grey} alt="arrow" />
                                                </div>
                                                {touched.giftcard && errors.giftcard ? (
                                                        <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                                            {touched.giftcard && errors.giftcard}
                                                        </small>
                                                ) : null}
                                            </div>
                                            <div className='mt-4'>
                                                <label htmlFor='cardUnit' className="block mb-2 font-Satoshi500 text-[#909090] text-xs">
                                                Card Unit<sup className='text-[#DC0000] text-xs top-[-0.2em] left-[-0.01em]'>*</sup>
                                                </label>
                                                <div className='relative'>
                                                <select
                                                    type="text"
                                                    name="cardUnit"
                                                    value={values.cardUnit}
                                                    onChange={(e) => {
                                                        handleChange(e)
                                                        handleCardUnit(e.currentTarget.value);
                                                    }}
                                                    placeholder="Select Card Unit"
                                                    className={
                                                        touched.cardUnit && errors.cardUnit
                                                            ? 'appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                                            : 'appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                                        }   
                                                >
                                                    <option value="" selected disabled>Select Card Unit</option>
                                                    {cardUnit.map((opt, index) => {
                                                        return <option key={index} value={opt}>${opt}</option>
                                                       })}   
                                                </select>
                                                    <img className='absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer' src={chevron_grey} alt="arrow" />
                                                </div>
                                                {touched.cardUnit && errors.cardUnit ? (
                                                    <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                                                {touched.cardUnit && errors.cardUnit}
                                                            </small>
                                                ) : null}
                                            </div>
                                          
                                            <div className='mt-4'>
                                                <label htmlFor='quantity' className="block mb-2 font-Satoshi500 text-[#909090] text-xs">
                                                Quantity<sup className='text-[#DC0000] text-xs top-[-0.2em] left-[-0.01em]'>*</sup>
                                                </label>
                                                <input
                                                    type="number"
                                                    name="quantity"
                                                    maxLength={10}
                                                    placeholder="1"
                                                    value={values.quantity}
                                                    className={
                                                        touched.quantity && errors.quantity
                                                            ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                                            : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                                    } 
                                                    onChange={(e) => {
                                                        handleChange(e)
                                                        handleQuantity(e.currentTarget.value);
                                                    }}
                                                />
                                                {touched.quantity && errors.quantity ? (
                                                    <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                                                {touched.quantity && errors.quantity}
                                                            </small>
                                                ) : null}
                                            </div>
                                            <div className='mt-4'>
                                                <label htmlFor='wallet' className="block mb-2 font-Satoshi500 text-[#909090] text-xs">
                                                Billing Wallet
                                                </label>
                                                <div className='relative'>
                                                    <select
                                                        type="text"
                                                        name="wallet"
                                                        placeholder="Select Wallet"
                                                        className={
                                                            touched.wallet && errors.wallet
                                                                ? 'appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                                                : 'appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                                        } 
                                                        value={values.wallet}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                        }}  
                                                    >
                                                        <option value="NGN" selected>Naira (NGN)</option>
                                                    </select>
                                                    <img className='absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer' src={chevron_grey} alt="arrow" />
                                                </div>
                                                {touched.wallet && errors.wallet ? (
                                                        <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                                            {touched.wallet && errors.wallet}
                                                        </small>
                                                ) : null}
                                            </div>
                                            <div className='mt-4'>
                                                <p className='mb-2 text-[#909090] font-Satoshi500 text-xs'>Calculated Amount</p>
                                                <div className='bg-[#EEF0F2] py-4 px-6 mt-3'>
                                                <p className='text-[#212121] font-Satoshi500 text-sm'>{cardSelected ? cardSelected : 'Giftcard selected shows here'}</p>
                                                </div>
                                                    <div className='bg-[#F7F9FB] rounded-[4px] py-4 px-6 flex justify-between'>
                                                    <p className='text-[#212121] font-Satoshi500 text-sm'>Total</p>
                                                    <p className='text-[#212121] font-Satoshi500 text-sm'>{values.wallet} {buyAmount === 0  ? "0.00" : numberWithCommas(buyAmount)}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='grid md:grid-cols-2 gap-4'>
                                            <div className='mt-10 '>
                                                    <button 
                                                    disabled={false}
                                                    className='bg-[#235643] rounded-[4px]
                                                        disabled:bg-[#235643] 
                                                        disabled:opacity-[0.7]
                                                        text-sm
                                                        font-Satoshi400
                                                        justify-center w-full
                                                        flex items-center 
                                                        py-4 px-6 text-[#FFFFFF]
                                                        hover:bg-[#235643] hover:opacity-[0.9] transition-all'> 
                                                        Proceed to Trade
                                                    </button>
                                            </div>
                                        </div>
                                    </Form>
                                    )}
                            </Formik>
                            }
                           


                    </div>
            </div>
        </div>
        </>
     );
}
 
export default RatesPage;