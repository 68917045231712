import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics'

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyDx1kbQU1SMFrcs7Tqvpfvn_ykzq5Q1BYA",
    authDomain: "cardvest-ed2b7.firebaseapp.com",
    databaseURL: "https://cardvest-ed2b7-default-rtdb.firebaseio.com",
    projectId: "cardvest-ed2b7",
    storageBucket: "cardvest-ed2b7.appspot.com",
    messagingSenderId: "607499492274",
    appId: "1:607499492274:web:1d847e82cd6274650ae2ab",
    measurementId: "G-4GKL6CR1S9"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
