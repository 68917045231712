const InfoCircleIcon = ({ className }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M10.0007 1.66683C5.39828 1.66683 1.66732 5.39779 1.66732 10.0002C1.66732 14.6025 5.39828 18.3335 10.0007 18.3335C14.603 18.3335 18.334 14.6025 18.334 10.0002C18.334 5.39779 14.603 1.66683 10.0007 1.66683Z"
        fill="#F40000"
      />
      <path
        d="M10 8.54183C9.65833 8.54183 9.375 8.82516 9.375 9.16683V13.3335C9.375 13.6752 9.65833 13.9585 10 13.9585C10.3417 13.9585 10.625 13.6752 10.625 13.3335V9.16683C10.625 8.82516 10.3417 8.54183 10 8.54183Z"
        fill="white"
      />
      <path
        d="M9.23398 6.98311C9.27565 7.08311 9.33398 7.17477 9.40898 7.25811C9.49232 7.33311 9.58398 7.39144 9.68398 7.43311C9.88398 7.51644 10.1173 7.51644 10.3173 7.43311C10.4173 7.39144 10.509 7.33311 10.5923 7.25811C10.6673 7.17477 10.7257 7.08311 10.7673 6.98311C10.809 6.88311 10.834 6.77477 10.834 6.66644C10.834 6.55811 10.809 6.44977 10.7673 6.34977C10.7257 6.24144 10.6673 6.15811 10.5923 6.07477C10.509 5.99977 10.4173 5.94144 10.3173 5.89977C10.2173 5.8581 10.109 5.83311 10.0007 5.83311C9.89232 5.83311 9.78398 5.8581 9.68398 5.89977C9.58398 5.94144 9.49232 5.99977 9.40898 6.07477C9.33398 6.15811 9.27565 6.24144 9.23398 6.34977C9.19232 6.44977 9.16732 6.55811 9.16732 6.66644C9.16732 6.77477 9.19232 6.88311 9.23398 6.98311Z"
        fill="white"
      />
    </svg>
  );
};

export default InfoCircleIcon;
