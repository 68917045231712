import React, { useState } from "react";
import ImageIcon from "../icons/image-icon";
import { apiUrl } from "../../redux/config";
import axios from "axios";

function ImageUploader({
  placeholder = "Upload Images",
  name,
  id = `image_upload_${Math.ceil(Math.random() * 4000)}`,
  className,
  errorMessage,
  isNotValid = false,
  helperText = "",
  onChange,
  onError,
  limit = 2048,
}) {
  const [imageUrl, setImageUrl] = useState();
  const [progress, setProgress] = useState(null);
  const [error, setError] = useState(null);

  const getToken = () => {
    const token = localStorage.getItem("token");
    return token;
  };

  const handleFileChange = async (event) => {
    setError(null);
    const file = event.target.files[0];
    if (!file) return;

    // Check if file size exceeds 2MB
    if (file.size > limit * 1024) {
      setError("File size exceeds 2MB limit");
      onError && onError("File size exceeds 2MB limit");
      return;
    }

    try {
      // Create a FormData object
      const formData = new FormData();
      formData.append("file", file);
      formData.append("directory", "kyc-uploads");

      // Get the signed upload URL for the file
      // const signedUrl = await getSignedUploadUrl(file);

      // Send a POST request to upload the file
      const response = await axios.post(`${apiUrl}file/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          // Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );

          setProgress(progress);
        },
      });

      // Get the uploaded image URL from the response
      const uploadedImageUrl = response.data.url;

      // Set the uploaded image URL
      setImageUrl(uploadedImageUrl);

      // Invoke the onChange callback if provided
      if (onChange) {
        onChange(uploadedImageUrl);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  return (
    <div className="space-y-2">
      <div className="px-4 bg-[#F7F9FB] block relative">
        {progress && (
          <span className="absolute top-0 text-xs bg-primary text-white px-3 rounded-b py-0.5">
            {progress >= 100 ? "Upload Complete" : "Uploading"}: {progress ?? 0}
            %
          </span>
        )}

        {error && (
          <span className="absolute top-0 text-xs bg-red-600 text-white px-3 rounded-b py-0.5">
            {error}
          </span>
        )}
        <div className="flex items-center justify-between">
          <label htmlFor={id} className="flex gap-5">
            <ImageIcon />
            <span className="text-primary underline text-sm font-bold">
              {placeholder}
            </span>
          </label>
          {/* Uploaded Image Preview */}

          <div className="w-20 h-20 flex items-center justify-center">
            {imageUrl && (
              <img
                src={imageUrl}
                alt="uploaded file"
                className="object-cover w-[60px] h-[60px] rounded border-primary border-4"
              />
            )}
          </div>
        </div>

        <input
          type="file"
          id={id}
          name={name}
          placeholder={placeholder}
          className="hidden"
          onChange={handleFileChange}
          accept="image/*"
        />
      </div>

      <p className="text-sm text-[#BABABA] mt-4">{helperText}</p>
    </div>
  );
}

export default ImageUploader;
