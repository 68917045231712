import { useState } from "react";

function useCanTradeCrypto() {
  // const { userInfo } = useSelector((state) => state.auth);
  const [canTradeCrypto] = useState(true);

  // useEffect(() => {
  //   const allowUsers = [
  //     "coldedmaarich@gmail.com",
  //     "anabellynda11@gmail.com",
  //     "busarihamzat1987@gmail.com",
  //     "kiyeb47787@cnurbano.com",
  //     "josephajibodu@gmail.com",
  //     "rhozario1650@gmail.com",
  //   ];

  //   if (userInfo && allowUsers.includes(userInfo.email.toLowerCase())) {
  //     setCanTradeCrypto(true);
  //   } else {
  //     setCanTradeCrypto(false);
  //   }
  // }, [userInfo]);

  return canTradeCrypto;
}

export default useCanTradeCrypto;
