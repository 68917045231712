import React,{useEffect, useState, useRef} from 'react'
import card1 from '../../assets/images/cards.svg'
import trash from '../../assets/icons/trash.svg'
import document_upload from '../../assets/icons/document-upload.svg'
import Modal from '../../components/Modals/modal'
import chevron_grey from '../../assets/icons/chevron_grey.svg'
import green_checked from '../../assets/icons/green_checked.svg'
import chevron_down_white from '../../assets/icons/chevron_down_white.svg'
import chevron_up_white from '../../assets/icons/chevron_up_white.svg'
import { useDispatch, useSelector } from 'react-redux'
import { BuyCard, getAvailableCountries, getBuyGiftcardByCountry, getCategories, getGiftcardCategories, getSpecificGiftcard, SellGiftcard } from '../../redux/Trade/tradeActions'
import { Form, Formik } from 'formik'
import { BuyCardValidator, SellCardValidator } from '../../validationSchema/validator'
import parse from 'html-react-parser';
import ProgressBar from "@ramonak/react-progress-bar";
import { apiUrl } from '../../redux/config'
import { clearTerms, clearTradePlaced, removeCard, uploadCard } from '../../redux/Trade/trade'
import cogoToast from 'cogo-toast'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
// import axios from 'axios'


const TradeNowPage = () => {
    const [tagId, setTagId] =  useState(1)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {categories, giftcards, terms, cardImages, tradeLoader, isTradePlaced, buygiftcards, countries} = useSelector((state) => state.trade);

    // change slice to 'url.slice(43, url.length)' when switching to production and 'url.slice(47, url.length)' on staging

    const types = [
        {
            id: 1,
            name: 'Sell Card'
        },
        {
            id: 2,
            name: 'Buy Card'
        }
    ]

    const toggleTag =  (val) =>{
       setTagId(val)
       setCardSelected("")
       setPayoutAmount(0)
       setBuyAmount(0)
       dispatch(clearTerms())
    }


    const [showTradeModal, setShowTradeModal] = useState(false)
    const TradeModal = () =>{
        setShowTradeModal(!showTradeModal)
    }

    const [quantity, setQuantity] = useState(1)
    const handleDecrement = () =>{
        if(quantity > 0){
            setQuantity(quantity - 1)
        }
    }

    const handleIncrement = () =>{
        setQuantity(quantity + 1)
    }

    useEffect(() => {
        if (quantity === 0) {
          setQuantity(1);
        }
      }, [quantity]);

    const [showBuyGiftcardModal, setShowBuyGiftcardModal] = useState(false)
    const BuyGiftcardModal = () =>{
        setShowBuyGiftcardModal(!showBuyGiftcardModal)
    }


    const handleCategory = (val) =>{
        if(tagId === 1){
            dispatch(getGiftcardCategories({
                type: 'sell',
                id: val
            }))
        }
        else{
            dispatch(getGiftcardCategories({
                type: 'buy',
                id: val
            }))
        } 
    }

    const [cardSelected, setCardSelected] = useState("")
    const [cardUnit, setCardUnit] = useState([])
    const [cardUnitSelected, setCardUnitSelected] = useState(null)
    const [totalPrice, setTotalPrice] = useState(0)
    const [rate, setRate] = useState(0)
    
    const handleCardUnit = (val) =>{
        setCardUnitSelected(parseInt(val))
    }

    useEffect(()=>{
        if(quantity > 0){
            setTotalPrice(quantity * cardUnitSelected * rate)
        }
    },[quantity, cardUnitSelected])

    const [rateInfo, setRateInfo] = useState({})
    const [payoutAmount, setPayoutAmount] = useState(0)
    const [buyAmount, setBuyAmount] = useState(0)

    const [progressLevel, setProgressLevel] = useState(0);
    const [imageLoading, setImageLoading] = useState(false);
    const [fileName, setFileName] = useState("");

    const getToken = () => {
        const token = localStorage.getItem("token");
        return token;
    };

      
    const handleGiftcard = (id) =>{
        let card = giftcards.find((val => val.id === parseInt(id)))
        let cardName = giftcards.find((val => val.id === parseInt(id))).name
        setCardSelected(cardName)
        setRateInfo({
            ...rateInfo,
            rate: card.rate,
            GHSRate: card.rates.GHS,
            NGNRate: card.rates.NGN
        })
        // get a specific card
        dispatch(getSpecificGiftcard({
            id: id
        }))
    }

    const handleBuyGiftcard = (id) =>{
        let card = buygiftcards.find((val => val.id === parseInt(id)))
        let cardName = buygiftcards.find((val => val.id === parseInt(id))).name
        setCardSelected(cardName) 
        setCardUnit(card.denominations)
        setCardUnitSelected(card.denominations.length > 0 ? card.denominations[0]: 0)
    
        // get rate of the card selected
        setRate(card.rate)

    }

    const handleCalculation = (val, wallet, card) =>{
        if(val === "" || card === ""){
            setPayoutAmount(0)
        }
        else{
            if(wallet === "GHS"){
                    let amt = parseFloat(val) * rateInfo.rate / rateInfo.GHSRate
                    setPayoutAmount(amt.toFixed(2))  
            }
            else{
                let amt = parseFloat(val) * rateInfo.rate / rateInfo.NGNRate
                setPayoutAmount(amt.toFixed(2))
            } 
        }
       
    }


    const handlePayoutSelected = (currency,card, amount) =>{
        if(card && amount !== ""){
            if(currency === "GHS"){
                let amt = parseFloat(amount) * rateInfo.rate / rateInfo.GHSRate
                setPayoutAmount(amt.toFixed(2))  
            }
            else{
                let amt = parseFloat(amount) * rateInfo.rate / rateInfo.NGNRate
                setPayoutAmount(amt.toFixed(2))
            }
        }
    }

    const handleCountrySelected = (countryCode) =>{
        dispatch(getBuyGiftcardByCountry({
            isoName: countryCode
        }))
    }

    const numberWithCommas = (x) => { 
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); 
    }

    const fileInputRef = useRef();

//     const fileInputClicked = () => {
//         fileInputRef.current.click();
//     }

//     const dragOver = (e) => {
//         e.preventDefault();
//    }
   
//    const dragEnter = (e) => {
//         e.preventDefault();
//    }
//    const dragLeave = (e) => {
//         e.preventDefault();
//     }

//     const fileDrop = (e) => {
//         e.preventDefault();
//         const files = e.dataTransfer.files[0];         
//     }

    const changedHandler = () => {
        let files = fileInputRef.current.files[0];
        setFileName(files && files.name ? files.name: 'giftcard')
        uploadFile(files)
        setImageLoading(true)
    }

    const uploadFile = (file) =>{
        const url = `${apiUrl}transactions/image-upload`;
        const xhr = new XMLHttpRequest();
        const fd = new FormData();
        xhr.open("POST", url, true);
        xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        xhr.setRequestHeader("Authorization", `Bearer ${getToken()}`);
    
        // Update progress (can be used to show progress indicator)
        xhr.upload.addEventListener("progress", (e) => {
          setProgressLevel(Math.round((e.loaded * 100.0) / e.total));
        });
    
        xhr.onreadystatechange = (e) => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            const response = JSON.parse(xhr.responseText);
            cogoToast.success("Image uploaded successfully!",{
                position: "top-right",
            });
            // dispatch and add card images
            dispatch(uploadCard(response))

            setProgressLevel(0)
            setFileName("")
            setImageLoading(false)
          }
        //   cors errors
          if(xhr.readyState === 4 && xhr.status === 0){
            console.log(xhr)
            cogoToast.warn("There might be an issue with this image, please optimize or try another image.")
            setProgressLevel(0)
            setFileName("")
            setImageLoading(false)
          }
          if(xhr.status === 413 || xhr.status === 422){
            cogoToast.warn("There might be an issue with this image, please optimize or try another image.")
            setProgressLevel(0)
            setFileName("")
            setImageLoading(false)
          }
        };

        fd.append("tags", "browser_upload");
        fd.append("file", file);
        xhr.send(fd);

    }



  const deleteCardImage = (index) =>{
        dispatch(removeCard(index))
    }

    const [tradeDetails, setTradeDetails] = useState({})
    const [isChecked, setIsChecked] = useState(false);

    const handleOnChange = () => {
        setIsChecked(!isChecked);
      };

    const ProceedTrade = (values)=>{
        if(cardImages.length === 0){
            cogoToast.info("Please upload at least a card image for this trade!")
        }
        else{
            setTradeDetails(values)
            setTimeout(()=>{
                setShowTradeModal(true)
            },500)
        }
      
    }
    
    const PlaceOrder = () =>{
        // place trade
        dispatch(SellGiftcard({
            card_id: tradeDetails ? parseInt(tradeDetails.giftcard): 0,
            amount: tradeDetails ? parseFloat(tradeDetails.amount): 0,
            images: cardImages,
            currency: tradeDetails ? tradeDetails.wallet: 'NGN',
            comment: tradeDetails ? tradeDetails.comment : ''
        }))
    }


    const [buyCardDetails, setBuyCardDetails] = useState({})
    const validateBuyGiftcard = (values) =>{
        setBuyCardDetails(values)
        setTimeout(()=>{
            setShowBuyGiftcardModal(true)
        },300)
    }

    const BuyGiftcard = () =>{
        dispatch(BuyCard({
            card_id: buyCardDetails ? parseInt(buyCardDetails.giftcard): 0,
            amount: totalPrice,
            currency: buyCardDetails ? buyCardDetails.wallet: 'NGN',  
            card_name: cardSelected, 
            card_unit: cardUnitSelected,
            quantity: quantity,
            country_code: buyCardDetails ? buyCardDetails.country: 'NGN',
            email:  buyCardDetails ? buyCardDetails.email: "N/A",
        }))
    }

    useEffect(()=>{
        if(isTradePlaced){
            setShowTradeModal(false)
            setShowBuyGiftcardModal(false)
            setTimeout(() => {
                navigate('/transactions')
                dispatch(clearTradePlaced())
            }, 1000);
        }
    },[isTradePlaced, dispatch, navigate])
          

    useEffect(()=>{
        dispatch(getCategories())
        dispatch(clearTradePlaced())
        dispatch(getAvailableCountries())
    },[dispatch])

    return ( 
        <>
          {/* Trade summary modal */}
          <Modal show={showTradeModal} showModal={TradeModal} 
         title="Trade Summary"
         >
            <div className='mt-10'>

                <div className='grid grid-cols-2 md:grid-cols-3 gap-4'>
                    <div>
                        <p className='text-xs text-[#909090] font-Satoshi500'>Date & Time</p>
                         <h6 className='text-[13px] text-[#212121] font-Satoshi500 mt-1'>
                            {moment().format('MMM Do YYYY, h:mma')}
                         </h6>
                    </div>
                    <div>
                        <p className='text-xs text-[#909090] font-Satoshi500'>Giftcard Value</p>
                         <h6 className='text-[13px] text-[#212121] font-Satoshi500 mt-1'>{tradeDetails ? tradeDetails.amount : 0}</h6>
                    </div>
                    <div>
                        <p className='text-xs text-[#909090] font-Satoshi500'>Payout Amount</p>
                         <h6 className='text-[13px] text-[#212121] font-Satoshi500 mt-1'>{tradeDetails ? tradeDetails.wallet: 'NGN'} {numberWithCommas(payoutAmount)}</h6>
                    </div>
                </div>


                <div className='grid grid-cols-2 md:grid-cols-3 gap-4 mt-5'>
                    <div>
                        <p className='text-xs text-[#909090] font-Satoshi500'>Payout Currency</p>
                         <h6 className='text-[13px] text-[#212121] font-Satoshi500 mt-1'>{tradeDetails ? tradeDetails.wallet: 'NGN'}</h6>
                    </div>
                    <div>
                        <p className='text-xs text-[#909090] font-Satoshi500'>Giftcard Category</p>
                         <h6 className='text-[13px] text-[#212121] font-Satoshi500 mt-1'>
                            {tradeDetails && tradeDetails.category
                              ?
                                categories.find((val => val.id === parseInt(tradeDetails.category))).name
                                :
                                "N/A"
                           }
                         </h6>
                    </div>
                    <div>
                        <p className='text-xs text-[#909090] font-Satoshi500'>Giftcard Type</p>
                         <h6 className='text-[13px] text-[#212121] font-Satoshi500 mt-1'>{cardSelected}</h6>
                    </div>
                </div>

                <div className='mt-5'>
                    <p className='text-xs text-[#909090] font-Satoshi500'>Uploaded Images</p>
                    <div className='flex gap-4 flex-wrap mt-3'>

                        {
                            cardImages && cardImages.length ?
                            cardImages.map((url, index)=>(
                            <div key={index} className='flex gap-3 items-center'>
                                <img src={url} alt="card" className='w-[24px] h-[24px] rounded-md' />
                                <p className='text-[#212121] text-xs font-Satoshi500'>{process.env.REACT_APP_SERVER === 'staging'? url.slice(47, url.length) : url.slice(72, url.length) }</p>
                            </div>
                            ))
                            :
                            <div className='text-center '>
                                <p className='text-[#212121] text-xs font-Satoshi500'>No cards uploaded yet</p>
                            </div>
                            }
                    </div>
                         
                </div>

                <div className='bg-[#F7F9FB] py-3 px-4 mt-6'>
                        <p className='text-[#C32121] text-[13px] font-Satoshi400 text-center'>Please note that the amount payable can change if you upload in the wrong (Sub)category. To be safe, please read the trade terms.</p>
                </div>

                <div className='flex justify-center mt-5'>
                    <div className='flex gap-2'>
                        <div>
                        <input id="checked-checkbox" type="checkbox"
                         checked={isChecked}
                         onChange={handleOnChange}
                         value="" 
                         className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                        </div>
                        <div>
                        <p className='text-[#212121] text-[13px] font-Satoshi400'>I hereby agree to the <span className='font-Satoshi700'>Trade Terms & Agreement</span></p>
                        </div>
                    </div>

                </div>

                <div className="mt-10">
                    <button
                    onClick={() => PlaceOrder()}
                        type="submit"
                        disabled={!isChecked || tradeLoader}
                        className="bg-[#235643] 
                        disabled:bg-[#235643] 
                        disabled:opacity-[0.7]
                        text-sm
                        rounded-md w-full py-4 text-[#fff] font-Satoshi400 hover:bg-[#235643] hover:opacity-[0.9] transition-all"
                    >
                        Place Order
                    </button>
                 </div>  


                
             </div>            
        </Modal>

         {/* Buy Giftcard  modal */}
         <Modal show={showBuyGiftcardModal} showModal={BuyGiftcardModal} 
         title={cardSelected ? cardSelected : 'Card Name'}
         >
            <div className='mt-10'>
                
                <div className='flex justify-center max-w-xs mx-auto text-center'>
                    <p className='text-[#909090] text-sm font-Satoshi400'>Go to apple.com/redeem to add to your Apple Account balance</p>
                </div>

                <div className='bg-[#F7F9FB] max-w-lg mx-auto rounded-lg py-6 px-8 mt-5'>           
                    <div className='flex items-center  max-w-xs mx-auto'>
                        <div className='flex-1'>
                            <p className='text-[#235643] text-sm font-Satoshi500'>Card Unit:</p>
                        </div>
                        <div className='flex-1'>
                        <div className='relative max-w-[92px]'>
                              <select
                                type="text"
                                name="giftcard"
                                placeholder="$2"
                                onChange={(e) => {
                                    handleCardUnit(e.currentTarget.value);
                                }}
                                className='appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm border border-[#DBDBDB] focus:outline-none rounded-[9px] bg-[#FFFFFF] py-3 px-4'  
                            >
                                {/* <option value="" >Select</option> */}
                                 {cardUnit.map((opt, index) => {
                                    return <option key={index} value={opt}>${opt}</option>
                                    })} 
                            </select>
                                <img className='absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer' src={chevron_grey} alt="arrow" />
                            </div>
                        </div>
                    </div>

                    <div className='flex items-center  mt-6 max-w-xs mx-auto'>
                        <div className='flex-1'>
                            <p className='text-[#235643] text-sm font-Satoshi500'>Quantity:</p>
                        </div>
                        <div className='flex items-center gap-2 flex-1'>
                           <div>
                              <input
                                type="number"
                                name="quantity"
                                placeholder="1"
                                value={quantity}
                                onChange={(e) => setQuantity(parseInt(e.target.value))}
                                className='appearance-none max-w-[92px] w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm border border-[#DBDBDB] focus:outline-none rounded-[9px] bg-[#FFFFFF] py-3 px-4'  
                            />    
                            </div>
                            <div onClick={handleDecrement} className='bg-[#235643] w-[24px] h-[24px] cursor-pointer flex justify-center items-center rounded-full'>
                                <img src={chevron_down_white} alt="chevro down" />
                            </div>
                            <div onClick={handleIncrement} className='bg-[#235643] w-[24px] h-[24px] cursor-pointer flex justify-center items-center rounded-full'>
                              <img src={chevron_up_white} alt="chevron up" />
                            </div>
                        </div>
                    </div>

                    <div className='mt-8 w-full h-[1px] bg-[#235643] border border-dashed'></div>

                    <div className='flex mt-6 max-w-xs mx-auto'>
                        <div className='flex-1'>
                            <p className='text-[#000000] text-xs font-Satoshi500'>Total Price:</p>
                        </div>
                        <div className='flex-1'>
                            <p className='text-[#000000] text-xs font-Satoshi500'>{buyCardDetails ? buyCardDetails.wallet: 'NGN'} {numberWithCommas(totalPrice)}</p>
                        </div>
                    </div>
                 </div>

                 <div className='flex gap-3 justify-center mt-6 items-center'>
                    <div className='bg-[#235643] w-[20px] h-[20px] rounded-full bg-opacity-[20%] flex justify-center items-center'>
                        <img src={green_checked} alt="green checked" />
                    </div>
                    <div>
                        <p className='text-[#212121] text-xs font-Satoshi400'>Agree to <span className='text-[#235643] font-Satoshi500 underline cursor-pointer'>Terms and Conditions</span></p>
                    </div>
                 </div>

                 <div className='mt-8 flex justify-center'>
                    <button
                    type="submit"
                    disabled={tradeLoader}
                    onClick={BuyGiftcard}
                    className='bg-[#235643] rounded-[4px]
                            disabled:bg-[#235643] 
                        disabled:opacity-[0.7]
                        text-sm 
                        font-Satoshi400
                        justify-center
                        flex items-center
                        py-4 px-10 text-[#FFFFFF]
                        hover:bg-[#235643] hover:opacity-[0.9] transition-all'> 
                        Proceed to Payment
                    </button>
                </div>

             </div>            
        </Modal>

            <div>
                  <h4 className='text-[#212121] font-Satoshi700 text-sm md:text-lg'>Trade Now</h4>

                 
                          <div className='grid md:grid-cols-3 gap-6'>

                            <div className='mt-8 md:col-span-2'>
                                <ul className='flex'>
                                {
                            types.map((val)=>(
                                <li key={val.id} onClick={() => toggleTag(val.id)} 
                                    className={
                                        tagId === val.id ?
                                        "border-b-[#235643] border-b-4 flex-1 cursor-pointer"
                                        :
                                        "flex-1 border-b-[#D9D9D9] border-b-4 cursor-pointer"
                                    }
                                    >
                                <p className={
                                    tagId === val.id
                                    ?
                                    "text-[#235643] font-Satoshi700 text-sm uppercase text-center mb-1"
                                    :
                                    "text-[#D9D9D9] font-Satoshi700 text-sm uppercase text-center mb-1"
                                }
                                >{val.name}</p>
                            </li>
                            ))  
                            }
                                </ul>

                                <div className='mt-8'>
                                    <h6 className='text-[#212121] font-Satoshi700 text-sm'>Trade details</h6>
                                        <p className='text-[#909090] font-Satoshi500 mt-1 text-xs'>Provide more information to complete your trade</p>
                                </div>

                          {tagId === 1 && 
                           <Formik
                        onSubmit={(values, { setSubmitting }) =>
                        ProceedTrade(values, setSubmitting)
                        }
                        validationSchema={SellCardValidator}
                        initialValues={{
                            category: '',
                            giftcard: '',
                            amount: '',
                            wallet: 'NGN',
                            comment: ''
                     }}
                    >
                        {({
                        handleChange,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        setFieldValue,
                        setFieldTouched,
                        values,
                        touched,
                        errors,
                        }) => (
                        <Form className="mt-10" onSubmit={handleSubmit}>
                                <div className='grid md:grid-cols-2 gap-4 mt-10'>
                                    <div>
                                        <label htmlFor='category' className="block mb-2 font-Satoshi500 text-[#909090] text-xs">
                                        Gift Card<sup className='text-[#DC0000] text-xs top-[-0.2em] left-[-0.01em]'>*</sup>
                                        </label>
                                        <div className='relative'>
                                            <select
                                                type="text"
                                                name="category"
                                                value={values.category}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    handleCategory(e.currentTarget.value);
                                                    setFieldTouched('category', false)
                                                    setFieldValue('giftcard', "")
                                                    setFieldValue('amount', "")
                                                    setPayoutAmount(0)
                                                }}
                                                placeholder="Select Giftcard"
                                                className={
                                                    touched.category && errors.category
                                                        ? 'appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                                        : 'appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                                    }  
                                            >
                                                <option value="" selected disabled>Select Giftcard</option>
                                                {categories.map((opt, index) => {
                                                        return <option key={index} value={opt.id}>{opt.name}</option>
                                                })}   
                                            </select>
                                            <img className='absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer' src={chevron_grey} alt="arrow" />
                                        </div>
                                        {touched.category && errors.category ? (
                                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                                    {touched.category && errors.category}
                                                </small>
                                        ) : null}
                                    </div>
                                    <div>
                                        <label htmlFor='giftcard' className="block mb-2 font-Satoshi500 text-[#909090] text-xs">
                                        Category<sup className='text-[#DC0000] text-xs top-[-0.2em] left-[-0.01em]'>*</sup>
                                        </label>
                                        <div className='relative'>
                                            <select
                                                type="text"
                                                name="giftcard"
                                                value={values.giftcard}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    setFieldValue('amount', "")
                                                    handleGiftcard(e.currentTarget.value);
                                                    setPayoutAmount(0)
                                                }}
                                                placeholder="Select Category"
                                                className={
                                                    touched.giftcard && errors.giftcard
                                                        ? 'appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                                        : 'appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                                    }   
                                            >
                                                <option value="" selected disabled>Select Category</option>
                                                {giftcards.map((opt, index) => {
                                                        return <option key={index} value={opt.id}>{opt.name}</option>
                                                })}   
                                            </select>
                                            <img className='absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer' src={chevron_grey} alt="arrow" />
                                        </div>
                                        {touched.giftcard && errors.giftcard ? (
                                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                                    {touched.giftcard && errors.giftcard}
                                                </small>
                                        ) : null}
                                    </div>
                                    <div className='mt-4'>
                                        <label htmlFor='amount' className="block mb-2 font-Satoshi500 text-[#909090] text-xs">
                                        Amount<sup className='text-[#DC0000] text-xs top-[-0.2em] left-[-0.01em]'>*</sup>
                                        </label>
                                        <input
                                            type="number"
                                            name="amount"
                                            maxLength={10}
                                            placeholder="100"
                                            value={values.amount}
                                            className={
                                                touched.amount && errors.amount
                                                    ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                                    : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                            } 
                                            onChange={(e) => {
                                                handleChange(e)
                                                handleCalculation(e.currentTarget.value, values.wallet, values.giftcard);
                                            }}
                                        />
                                        {touched.amount && errors.amount ? (
                                            <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                                        {touched.amount && errors.amount}
                                                    </small>
                                        ) : null}
                                    </div>
                                    <div className='mt-4'>
                                        <label htmlFor='wallet' className="block mb-2 font-Satoshi500 text-[#909090] text-xs">
                                        Payout Wallet
                                        </label>
                                        <div className='relative'>
                                            <select
                                                type="text"
                                                name="wallet"
                                                placeholder="Select Category"
                                                className={
                                                    touched.wallet && errors.wallet
                                                        ? 'appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                                        : 'appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                                } 
                                                value={values.wallet}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    handlePayoutSelected(e.currentTarget.value, values.giftcard, values.amount);
                                                }}  
                                            >
                                                <option value="NGN" selected>Naira (NGN)</option>
                                                <option value="GHS">Cedis (GHS)</option>
                                                
                                            </select>
                                            <img className='absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer' src={chevron_grey} alt="arrow" />
                                        </div>
                                        {touched.wallet && errors.wallet ? (
                                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                                    {touched.wallet && errors.wallet}
                                                </small>
                                        ) : null}
                                    </div>
                                    <div className='mt-4'>
                                        <p className='mb-2 text-[#909090] font-Satoshi500 text-xs'>Calculated Amount</p>
                                        <div className='bg-[#EEF0F2] py-4 px-6 mt-3'>
                                        <p className='text-[#212121] font-Satoshi500 text-sm'>{cardSelected ? cardSelected : 'Card selected shows here'}</p>
                                        </div>
                                            <div className='bg-[#F7F9FB] rounded-[4px] py-4 px-6 flex justify-between'>
                                            <p className='text-[#212121] font-Satoshi500 text-sm'>Total</p>
                                            <p className='text-[#212121] font-Satoshi500 text-sm'>{values.wallet} {payoutAmount === 0  ? "0.00" : numberWithCommas(payoutAmount)}</p>
                                        </div>
                                    </div>
                                    <div className='mt-4'>
                                        <label htmlFor='comment' className="block mb-2 font-Satoshi500 text-[#909090] text-xs">
                                    Comments (Optional)
                                        </label>
                                        <textarea
                                            type="text"
                                            name="comment"
                                            rows="4"
                                            placeholder="Comments"
                                            value={values.comment}
                                            onChange={handleChange}
                                            onBlur={handleBlur} 
                                            className="appearance-none w-full placeholder:text-[#B9B9B9] resize-none placeholder:text-[13px] text-[#212121] text-sm  border-none 
                                            focus:outline-none rounded-[4px]  bg-[#F7F9FB] py-4 px-4"  
                                        />
                                    </div>
                                </div>

                                <div className='mt-4'>
                                    <label htmlFor='giftcard' className="block mb-2 font-Satoshi500 text-[#909090] text-xs">
                                        Gift Card Image<sup className='text-[#DC0000] text-xs top-[-0.2em] left-[-0.01em]'>*</sup>
                                    </label>
                                    <div className='bg-[#F7F9FB] rounded-[4px] border border-[#D6D8DA] py-8 md:py-6 flex flex-col md:flex-row'>
                                        <div 
                                        
                                        className='flex-1 px-6 flex flex-col items-center justify-center'>
                                            <div className='flex justify-center'>
                                                <img src={document_upload} alt="document_upload" />
                                            </div>
                                            <div className='text-center mt-2'>
                                                <p className='text-[#000000] font-Satoshi400 text-[13px]'>
                                                Drag and Drop file
                                                </p>
                                                <p className='text-[#000000] font-Satoshi400 text-[13px] mt-1'>
                                                    OR
                                                </p>
                                            </div>
                                            <div className='flex justify-center mt-3'>
                                            <label className='
                                                    bg-[#235643] rounded-[4px] 
                                                    disabled:bg-[#235643] 
                                                disabled:opacity-[0.7]
                                                text-sm cursor-pointer text-center
                                                min-w-[200px]
                                                font-Satoshi400
                                                py-4 px-6 text-[#FFFFFF]
                                                hover:bg-[#235643] hover:opacity-[0.9] transition-all'>
                                                    Select a File
                                                <input 
                                                ref={fileInputRef}
                                                type="file"
                                                accept="image/png, image/jpg, image/jpeg"  
                                                onChange={() => changedHandler()}
                                                className='hidden'/>
                                            </label>
                                        
                                            </div>

                                        </div>
                                        <div className='flex-1 border-l border-l-[#D6D8DA] px-6'>
                                            <h6 className='text-[#212121] font-Satoshi700 text-sm mt-10 md:mt-0'>Uploaded Cards</h6>
                                            
                                            {
                                                cardImages && cardImages.length ?
                                                cardImages.map((url, index)=>(
                                                <div key={index} className='flex justify-between items-center mt-5'>
                                                    <div className='flex gap-3 items-center'>
                                                        <img src={url} alt="card" className='w-[24px] h-[24px] rounded-md' />
                                                        <p className='text-[#212121] text-xs font-Satoshi500'>{process.env.REACT_APP_SERVER === 'staging' ? url.slice(47, url.length) : url.slice(72, url.length) }</p>
                                                    </div>
                                                    <div onClick={()=> deleteCardImage(index)} className='cursor-pointer'>
                                                        <img src={trash} alt="trash" />
                                                    </div>
                                                </div>
                                                ))
                                                :
                                                <>
                                                {
                                                !imageLoading &&
                                                    <div className='text-center mt-10 md:mt-16'>
                                                        <p className='text-[#212121] text-xs font-Satoshi500'>No cards uploaded yet</p>
                                                    </div>
                                                }
                                               </>
                                            }

                                        

                                    {
                                        progressLevel === 0 && !imageLoading
                                            ?
                                            ""
                                            :
                                            <div className='flex gap-3 items-center mt-5'>
                                                <div className=''>
                                                    <img src={card1} className='w-[24px] h-[24px] rounded-md' alt="card" />
                                                </div>
                                                <div className='w-full'>
                                                    <p className='text-[#212121] text-xs font-Satoshi500'>{fileName}</p>
                                                    <ProgressBar 
                                                    className='mt-1'
                                                        isLabelVisible={false}
                                                        bgColor="#235643"
                                                        baseBgColor="#D9D9D9"
                                                        completed={progressLevel} borderRadius={0} height={4} maxCompleted={100} />
                                                </div>
                                            </div>
                                            }
                                            
                                        
                                        </div>

                                    </div>
                                </div>

                                <div className='mt-8 flex justify-center'>
                                    <button
                                    type="submit"
                                    disabled={tradeLoader}
                                    className='bg-[#235643] rounded-[4px]
                                            disabled:bg-[#235643] 
                                        disabled:opacity-[0.7]
                                        text-sm
                                        font-Satoshi400
                                        justify-center
                                        flex items-center min-w-[248px]
                                        py-4 px-6 text-[#FFFFFF]
                                        hover:bg-[#235643] hover:opacity-[0.9] transition-all'> 
                                        Proceed to Trade
                                    </button>
                                </div>
                                </Form>
                            )}
                            </Formik>
                             }

                             {
                                tagId === 2 &&
                                <Formik
                                onSubmit={(values, { setSubmitting }) =>
                                validateBuyGiftcard(values, setSubmitting)
                                }
                                validationSchema={BuyCardValidator}
                                initialValues={{
                                    giftcard: '',
                                    country: '',
                                    wallet: 'NGN',
                                    email: '',
                             }}
                            >
                                {({
                                handleChange,
                                isSubmitting,
                                handleSubmit,
                                values,
                                touched,
                                setFieldValue,
                                errors,
                                }) => (
                                <Form className="mt-10 " onSubmit={handleSubmit}>
                                    <div className='grid grid-cols-2 gap-4'>
                                       <div className=''>
                                        <label htmlFor='wallet' className="block mb-2 font-Satoshi500 text-[#909090] text-xs">
                                        Payout Wallet
                                        </label>
                                        <div className='relative'>
                                            <select
                                                type="text"
                                                name="wallet"
                                                placeholder="Select Category"
                                                className={
                                                    touched.wallet && errors.wallet
                                                        ? 'appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                                        : 'appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                                } 
                                                value={values.wallet}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                }}  
                                            >
                                                <option value="NGN" selected>Naira (NGN)</option>
                                                {/* <option value="GHS">Cedis (GHS)</option> */}
                                                
                                            </select>
                                            <img className='absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer' src={chevron_grey} alt="arrow" />
                                        </div>
                                        {touched.wallet && errors.wallet ? (
                                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                                    {touched.wallet && errors.wallet}
                                                </small>
                                        ) : null}
                                        </div>
                                        
                                        <div className=''>
                                        <label htmlFor='country' className="block mb-2 font-Satoshi500 text-[#909090] text-xs">
                                        Giftcard country
                                        </label>
                                        <div className='relative'>
                                            <select
                                                type="text"
                                                name="country"
                                                placeholder="Select Country"
                                                className={
                                                    touched.country && errors.country
                                                        ? 'appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                                        : 'appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                                } 
                                                value={values.country}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    setFieldValue('giftcard', "")
                                                    handleCountrySelected(e.currentTarget.value);
                                                }}  
                                            >
                                               <option value="" selected disabled>Select Country</option>
                                               {countries.map((opt) => {
                                                            return <option key={opt.isoName} value={opt.isoName}>{opt.name}</option>
                                                    })} 
                                                
                                            </select>
                                            <img className='absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer' src={chevron_grey} alt="arrow" />
                                        </div>
                                        {touched.country && errors.country ? (
                                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                                    {touched.country && errors.country}
                                                </small>
                                        ) : null}
                                        </div>

                                        <div className='mt-4'>
                                            <label htmlFor='giftcard' className="block mb-2 font-Satoshi500 text-[#909090] text-xs">
                                            Gift Card<sup className='text-[#DC0000] text-xs top-[-0.2em] left-[-0.01em]'>*</sup>
                                            </label>
                                            <div className='relative'>
                                                <select
                                                    type="text"
                                                    name="giftcard"
                                                    value={values.giftcard}
                                                    onChange={(e) => {
                                                        handleChange(e)
                                                        handleBuyGiftcard(e.currentTarget.value);
                                                    }}
                                                    placeholder="Select Giftcard"
                                                    className={
                                                        touched.giftcard && errors.giftcard
                                                            ? 'appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                                            : 'appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                                        }   
                                                >
                                                    <option value="" selected disabled>Select Giftcard</option>
                                                    {buygiftcards.map((opt, index) => {
                                                            return <option key={index} value={opt.id}>{opt.name}</option>
                                                    })}   
                                                </select>
                                                <img className='absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer' src={chevron_grey} alt="arrow" />
                                            </div>
                                            {touched.giftcard && errors.giftcard ? (
                                                    <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                                        {touched.giftcard && errors.giftcard}
                                                    </small>
                                            ) : null}
                                        </div>

                                        <div className='mt-4'>
                                        <label htmlFor='email' className="block mb-2 font-Satoshi500 text-[#909090] text-xs">
                                        Recipients Email
                                        </label>
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="Enter email"
                                            value={values.email}
                                            className={
                                                touched.email && errors.email
                                                    ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                                    : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                            } 
                                            onChange={(e) => {
                                                handleChange(e)
                                            }}
                                        />
                                        {touched.email && errors.email ? (
                                            <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                                        {touched.email && errors.email}
                                                    </small>
                                        ) : null}
                                       </div>

                                      
                                      
                                       
                                    </div>

                                    <div className='mt-8 max-w-sm'>
                                    <button
                                    type="submit"
                                    className='bg-[#235643] rounded-[4px]
                                            disabled:bg-[#235643] 
                                        disabled:opacity-[0.7]
                                        text-sm 
                                        font-Satoshi400
                                        justify-center w-full
                                        flex items-center
                                        py-4 px-6 text-[#FFFFFF]
                                        hover:bg-[#235643] hover:opacity-[0.9] transition-all'> 
                                        Proceed
                                    </button>
                                </div>

                                </Form>
                                )}
                                </Formik>
                             }

                            </div>
                            {
                            tagId === 1 &&
                              <div className=''>
                                <div className='bg-[#F9F9F9] rounded-[4px] mt-6 max-w-full max-h-[500px] overflow-y-scroll'>
                                    <div className='bg-[#EEF0F2] py-4 px-4'>
                                        <h6 className='text-[#000000] font-Satoshi700 text-sm'>Terms of Trade</h6>
                                    </div>
                                    <div className='pt-4 pb-10 px-4 text-[#212121] text-[13px] font-Satoshi400'>
                                    {
                                        terms
                                        ?
                                        parse(terms) 
                                        :
                                        <p className='text-center  mt-5'>
                                        Terms would appear here once a giftcard is selected!
                                        </p>
                                    }
                                    
                                    
                                    </div>

                                </div>
                            </div> 
                            }   
                         </div>
                 

            </div>
        </>
     );
}
 
export default TradeNowPage;