import { createSlice } from '@reduxjs/toolkit'
import { getNotifications, markSpecificNotification } from './notificationsActions'

const initialState = {
  loading: false,
  notifications: [],
  total: 0,
  isRead: false
}

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    clearIsRead: (state) =>{
      state.isRead = false
    }
  },
  extraReducers:{
    
    //   get notifications
    [getNotifications.pending]: (state) => {
        state.loading = true
      },
      [getNotifications.fulfilled]: (state, { payload }) => {
         state.loading = false
         state.notifications = payload.data
          state.total = payload.meta.total
      },
      [getNotifications.rejected]: (state, { payload }) => {
        state.loading = false
      },

      // mark notifications
      [markSpecificNotification.pending] :(state) =>{

      },
      [markSpecificNotification.fulfilled]: (state) =>{
          state.isRead = true
      },
      [markSpecificNotification.rejected]: (state) =>{

      }

  }
})

// Action creators are generated for each case reducer function
export const {increment, clearIsRead } = notificationsSlice.actions

export default notificationsSlice.reducer