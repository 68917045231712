import React, { useEffect, useState } from "react";
import search_icon from "../../assets/icons/search-normal.svg";
import notification_icon from "../../assets/icons/notification.svg";
import avatar from "../../assets/icons/avatar.svg";
import logo_white from "../../assets/icons/logo-white.svg";
import home_icon from "../../assets/icons/home.svg";
import trade_icon from "../../assets/icons/trade.svg";
import sell_crypto from "../../assets/icons/sell-crypto-light.svg";
import transactions_icon from "../../assets/icons/wallet-minus.svg";
import calculator_icon from "../../assets/icons/calculator.svg";
import withdraw_icon from "../../assets/icons/card-receive.svg";
import settings_icon from "../../assets/icons/settings.svg";
import logout_icon from "../../assets/icons/logout.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Logout } from "../../redux/Auth/auth";
import {
  getNotifications,
  markSpecificNotification,
} from "../../redux/Notifications/notificationsActions";
// import arrow_right from '../../assets/icons/arrow-right-green.svg'
import moment from "moment";
import { clearIsRead } from "../../redux/Notifications/notifications";
import no_notification from "../../assets/icons/notification_icon.svg";
import menu_icon from "../../assets/icons/hamburger.svg";
import close_icon from "../../assets/icons/close_icon.svg";
import "./sidebar.css";
import useCanTradeCrypto from "../../hooks/useCanTradeCrypto";

const SidebarComp = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.auth);

  const canTradeCrypto = useCanTradeCrypto();
  const { notifications, total, isRead } = useSelector(
    (state) => state.notification
  );

  const [showDrop, setShowDrop] = useState(false);

  const toggleDropdown = () => {
    setShowDrop(!showDrop);
  };

  const handleMarkRead = (id) => {
    dispatch(
      markSpecificNotification({
        id: id,
      })
    );
  };

  const [sideShow, setSideShow] = useState(false);

  const toggleMobileMenu = () => {
    setSideShow(sideShow ? false : true);
  };

  useEffect(() => {
    if (isRead) {
      setTimeout(() => {
        dispatch(getNotifications());
        dispatch(clearIsRead());
      }, 800);
    }
  }, [isRead, dispatch]);

  const handleLogout = () => {
    dispatch(Logout());
    navigate("/");
  };

  useEffect(() => {
    dispatch(getNotifications());
  }, [dispatch]);

  return (
    <>
      <div className="flex h-full min-h-screen">
        <div className="bg-sidebar md:w-64 flex-shrink-0 oveflow-y-scroll py-6 hidden lg:block">
          <div className="flex justify-center">
            <img src={logo_white} alt="logo" />
          </div>

          <div className="mt-10">
            <Link
              to="/dashboard"
              className={
                location.pathname.includes("/dashboard")
                  ? "flex items-center w-full py-4 mt-3 px-6 rounded-[4px] bg-[#174A37] text-white font-Satoshi500 border-l-2 border-[#F7F2DD]"
                  : "flex items-center w-full mt-3 py-4 px-6 rounded-[4px] text-white font-Satoshi400 hover:bg-[#174A37] duration-300"
              }
            >
              <img
                src={home_icon}
                alt="home icon"
                className="w-[18px] h-[18px]"
              />
              <span className="ml-3 text-sm">Dashboard</span>
            </Link>

            <Link
              to="/trade-now"
              className={
                location.pathname.includes("/trade")
                  ? "flex items-center w-full py-4 mt-3 px-6 rounded-[4px] bg-[#174A37] text-white font-Satoshi500 border-l-2 border-[#F7F2DD]"
                  : "flex items-center w-full mt-3 py-4 px-6 rounded-[4px] text-white font-Satoshi400 hover:bg-[#174A37] duration-300"
              }
            >
              <img
                src={trade_icon}
                alt="trade icon"
                className="w-[18px] h-[18px]"
              />
              <span className="ml-3 text-sm">Trade Now</span>
            </Link>

            {canTradeCrypto && (
              <Link
                to="/sell-crypto"
                className={
                  location.pathname.includes("crypto")
                    ? "flex items-center w-full py-4 mt-3 px-6 rounded-[4px] bg-[#174A37] text-white font-Satoshi500 border-l-2 border-[#F7F2DD]"
                    : "flex items-center w-full mt-3 py-4 px-6 rounded-[4px] text-white font-Satoshi400 hover:bg-[#174A37] duration-300"
                }
              >
                <img
                  src={sell_crypto}
                  alt="trade icon"
                  className="w-[18px] h-[18px]"
                />
                <span className="ml-3 text-sm">Sell Crypto</span>
              </Link>
            )}

            <Link
              to="/transactions"
              className={
                location.pathname.includes("/transactions")
                  ? "flex items-center w-full py-4 mt-3 px-6 rounded-[4px] bg-[#174A37] text-white font-Satoshi500 border-l-2 border-[#F7F2DD]"
                  : "flex items-center w-full mt-3 py-4 px-6 rounded-[4px] text-white font-Satoshi400 hover:bg-[#174A37] duration-300"
              }
            >
              <img
                src={transactions_icon}
                alt="transactions icon"
                className="w-[18px] h-[18px]"
              />
              <span className="ml-3 text-sm">Transactions</span>
            </Link>

            <Link
              to="/rate-calculator"
              className={
                location.pathname.includes("/rate-calculator")
                  ? "flex items-center w-full py-4 mt-3 px-6 rounded-[4px] bg-[#174A37] text-white font-Satoshi500 border-l-2 border-[#F7F2DD]"
                  : "flex items-center w-full mt-3 py-4 px-6 rounded-[4px] text-white font-Satoshi400 hover:bg-[#174A37] duration-300"
              }
            >
              <img
                src={calculator_icon}
                alt="calculator icon"
                className="w-[18px] h-[18px]"
              />
              <span className="ml-3 text-sm">Calculator</span>
            </Link>

            <Link
              to="/withdrawals"
              className={
                location.pathname.includes("/withdrawal")
                  ? "flex items-center w-full py-4 mt-3 px-6 rounded-[4px] bg-[#174A37] text-white font-Satoshi500 border-l-2 border-[#F7F2DD]"
                  : "flex items-center w-full mt-3 py-4 px-6 rounded-[4px] text-white font-Satoshi400 hover:bg-[#174A37] duration-300"
              }
            >
              <img
                src={withdraw_icon}
                alt="withdraw icon"
                className="w-[18px] h-[18px]"
              />
              <span className="ml-3 text-sm">Withdrawals</span>
            </Link>

            <Link
              to="/settings"
              className={
                location.pathname.includes("/settings")
                  ? "flex items-center w-full py-4 mt-3 px-6 rounded-[4px] bg-[#174A37] text-white font-Satoshi500 border-l-2 border-[#F7F2DD]"
                  : "flex items-center w-full mt-3 py-4 px-6 rounded-[4px] text-white font-Satoshi400 hover:bg-[#174A37] duration-300"
              }
            >
              <img
                src={settings_icon}
                alt="settings icon"
                className="w-[18px] h-[18px]"
              />
              <span className="ml-3 text-sm">Settings</span>
            </Link>

            <div
              onClick={handleLogout}
              className="flex items-center w-full mt-28 cursor-pointer py-4 px-6 rounded-[4px] text-white  font-Satoshi400 hover:bg-[#174A37] duration-300"
            >
              <img
                src={logout_icon}
                alt="logout icon"
                className="w-[18px] h-[18px]"
              />
              <span className="ml-3 text-sm">Logout</span>
            </div>
          </div>
        </div>

        {/* mobile toggle */}
        <div
          className={
            sideShow
              ? "bg-sidebar w-64 fixed userside show flex-shrink-0 oveflow-y-scroll py-6 md:hidden"
              : "bg-sidebar w-64 fixed h-screen userside flex-shrink-0 oveflow-y-scroll py-6 md:hidden"
          }
        >
          <div className="flex justify-between items-center px-4">
            <div className="">
              <img src={logo_white} alt="logo" />
            </div>
            <div
              onClick={toggleMobileMenu}
              className="cursor-pointer bg-[#EDEDED] w-[25px] h-[25px] rounded-full flex items-center justify-center"
            >
              <img src={close_icon} alt="close" />
            </div>
          </div>

          <div className="mt-10">
            <Link
              to="/dashboard"
              className={
                location.pathname.includes("/dashboard")
                  ? "flex items-center w-full py-4 mt-3 px-6 rounded-[4px] bg-[#174A37] text-white font-Satoshi500 border-l-2 border-[#F7F2DD]"
                  : "flex items-center w-full mt-3 py-4 px-6 rounded-[4px] text-white font-Satoshi400 hover:bg-[#174A37] duration-300"
              }
            >
              <img
                src={home_icon}
                alt="home icon"
                className="w-[18px] h-[18px]"
              />
              <span className="ml-3 text-sm">Dashboard</span>
            </Link>

            <Link
              to="/trade-now"
              className={
                location.pathname.includes("/trade")
                  ? "flex items-center w-full py-4 mt-3 px-6 rounded-[4px] bg-[#174A37] text-white font-Satoshi500 border-l-2 border-[#F7F2DD]"
                  : "flex items-center w-full mt-3 py-4 px-6 rounded-[4px] text-white font-Satoshi400 hover:bg-[#174A37] duration-300"
              }
            >
              <img
                src={trade_icon}
                alt="trade icon"
                className="w-[18px] h-[18px]"
              />
              <span className="ml-3 text-sm">Trade Now</span>
            </Link>

            <Link
              to="/transactions"
              className={
                location.pathname.includes("/transactions")
                  ? "flex items-center w-full py-4 mt-3 px-6 rounded-[4px] bg-[#174A37] text-white font-Satoshi500 border-l-2 border-[#F7F2DD]"
                  : "flex items-center w-full mt-3 py-4 px-6 rounded-[4px] text-white font-Satoshi400 hover:bg-[#174A37] duration-300"
              }
            >
              <img
                src={transactions_icon}
                alt="transactions icon"
                className="w-[18px] h-[18px]"
              />
              <span className="ml-3 text-sm">Transactions</span>
            </Link>

            <Link
              to="/rate-calculator"
              className={
                location.pathname.includes("/rate-calculator")
                  ? "flex items-center w-full py-4 mt-3 px-6 rounded-[4px] bg-[#174A37] text-white font-Satoshi500 border-l-2 border-[#F7F2DD]"
                  : "flex items-center w-full mt-3 py-4 px-6 rounded-[4px] text-white font-Satoshi400 hover:bg-[#174A37] duration-300"
              }
            >
              <img
                src={calculator_icon}
                alt="calculator icon"
                className="w-[18px] h-[18px]"
              />
              <span className="ml-3 text-sm">Calculator</span>
            </Link>

            <Link
              to="/withdrawals"
              className={
                location.pathname.includes("/withdrawal")
                  ? "flex items-center w-full py-4 mt-3 px-6 rounded-[4px] bg-[#174A37] text-white font-Satoshi500 border-l-2 border-[#F7F2DD]"
                  : "flex items-center w-full mt-3 py-4 px-6 rounded-[4px] text-white font-Satoshi400 hover:bg-[#174A37] duration-300"
              }
            >
              <img
                src={withdraw_icon}
                alt="withdraw icon"
                className="w-[18px] h-[18px]"
              />
              <span className="ml-3 text-sm">Withdrawals</span>
            </Link>

            <Link
              to="/settings"
              className={
                location.pathname.includes("/settings")
                  ? "flex items-center w-full py-4 mt-3 px-6 rounded-[4px] bg-[#174A37] text-white font-Satoshi500 border-l-2 border-[#F7F2DD]"
                  : "flex items-center w-full mt-3 py-4 px-6 rounded-[4px] text-white font-Satoshi400 hover:bg-[#174A37] duration-300"
              }
            >
              <img
                src={settings_icon}
                alt="settings icon"
                className="w-[18px] h-[18px]"
              />
              <span className="ml-3 text-sm">Settings</span>
            </Link>

            <div
              onClick={handleLogout}
              className="flex items-center w-full mt-28 cursor-pointer py-4 px-6 rounded-[4px] text-white  font-Satoshi400 hover:bg-[#174A37] duration-300"
            >
              <img
                src={logout_icon}
                alt="logout icon"
                className="w-[18px] h-[18px]"
              />
              <span className="ml-3 text-sm">Logout</span>
            </div>
          </div>
        </div>

        <div className="py-6 flex-grow flex-auto flex-shrink overflow-y-scroll">
          {/* navbar */}
          <div className="flex px-4 md:px-6 justify-between items-center">
            <div className="relative hidden md:block">
              <input
                type="text"
                name="text"
                placeholder="Search..."
                className="appearance-none 
                             placeholder:text-[#B9B9B9] placeholder:text-[13px]
                              text-[#212121] text-sm  md:min-w-[500px]
                              border-none focus:outline-none rounded-[4px] bg-[#F7F7F7] py-3 px-4"
              />
              <img
                src={search_icon}
                alt="search icon"
                className="absolute w-[18px] h-[18px] top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
              />
            </div>
            <div
              onClick={toggleMobileMenu}
              className="cursor-pointer block md:hidden"
            >
              <img src={menu_icon} alt="menu" />
            </div>
            <div className="flex gap-4 items-center">
              <div
                onClick={toggleDropdown}
                className="bg-[#F9F9F9] w-[40px] h-[40px] flex justify-center cursor-pointer items-center rounded-full relative"
              >
                <img src={notification_icon} alt="notification" className="" />
                <div className="w-[8px] h-[8px] rounded-full bg-[#E40000] absolute right-3 top-3"></div>

                <div
                  className={
                    showDrop
                      ? "bg-white border-[#F1F1F1] border-[2px] absolute visible top-12 -right-[50px] md:right-0 min-w-[300px] md:min-w-[500px] max-h-[600px] overflow-y-scroll px-4 py-6 rounded-[10px] z-[12] transition-all ease-in-out duration-[0.3s]"
                      : "bg-white border-[#F1F1F1] border-[2px] absolute top-16 hidden right-0 min-w-[500px] px-4 py-6 rounded-[10px] z-[12] transition-all ease-in-out duration-[0.3s]"
                  }
                >
                  <div className="flex justify-between items-center">
                    <p className="text-[#212121] font-Satoshi500 text-[15px]">
                      Notifications
                    </p>
                    {total > 0 && (
                      <p className="text-[#212121] font-Satoshi500 text-[15px]">
                        ({total})
                      </p>
                    )}
                  </div>

                  {notifications && notifications.length > 0 ? (
                    notifications.map((item) => (
                      <div
                        key={item.id}
                        className="mt-6 mb-4 bg-[#F9F9F9] rounded-[6px] py-4 px-3"
                      >
                        <div>
                          <p className="text-#212121] text-sm font-Satoshi500">
                            {moment(item.created_at).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )}
                          </p>
                          <div className="flex flex-col md:flex-row justify-between mt-2 items-start">
                            <h6 className="text-[#8D8D8D] font-Satoshi500 text-sm mt-1 max-w-[300px]">
                              {item.description}
                            </h6>
                            <button
                              onClick={() => handleMarkRead(item.id)}
                              className="text-[#235643] mt-3 md:mt-0 text-xs font-Satoshi700 underline"
                            >
                              Mark as Read
                            </button>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <>
                      <div className="flex flex-col min-h-[400px] justify-center items-center">
                        <div className="bg-[#F7F7F7] w-[164px] h-[164px] rounded-full flex justify-center items-center">
                          <img
                            src={no_notification}
                            alt="no transaction icon"
                          />
                        </div>
                        <div className="mt-2">
                          <p className="text-[#235643] text-sm font-Satoshi500">
                            You have no notifications yet.
                          </p>
                        </div>
                      </div>
                    </>
                  )}

                  {/* <div className='flex justify-center'>
                                <button className='bg-[#F9F9F9] rounded-[4px]
                                    disabled:bg-[##F9F9F9] 
                                    disabled:opacity-[0.7]
                                    text-sm
                                    font-Satoshi500
                                    justify-center
                                    flex items-center gap-2
                                     py-3 px-8 text-[#235643] group
                                    hover:bg-[#F9F9F9] hover:opacity-[0.9] transition-all'> 
                                    Show All
                                    <img src={arrow_right} className="group-hover:transition group-hover:duration-300 group-hover:transform group-hover:translate-x-1" alt="arrow right" />
                                </button>
                                </div> */}
                </div>
              </div>
              <Link to="/settings" className="cursor-pointer">
                {userInfo && userInfo.image_url !== "" ? (
                  <img
                    src={userInfo.image_url}
                    alt="avatar"
                    className="w-[40px] h-[40px] rounded-full"
                  />
                ) : (
                  <img
                    src={avatar}
                    alt="avatar"
                    className="w-[40px] h-[40px]"
                  />
                )}
              </Link>
            </div>
          </div>
          <div className="bg-[#F1F1F1] w-full h-[1px] mt-4"></div>
          <div className="px-4 md:px-8 py-6">{children}</div>
        </div>
      </div>
    </>
  );
};

export default SidebarComp;
