import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import SidebarComp from "./components/UserSidebar/sidebar";
import ForgotPasswordPage from "./pages/Auth/forgot";
import LoginPage from "./pages/Auth/login";
import ResetPasswordPage from "./pages/Auth/reset";
import SetPinPage from "./pages/Auth/setPin";
import SignUpPage from "./pages/Auth/signup";
import VerifyInfoPage from "./pages/Auth/verifyInfo";
import VerifySuccessPage from "./pages/Auth/verifySuccess";
import DashboardPage from "./pages/Dashboard/dashboard";
import RateCalculatorPage from "./pages/Rate Calculator/rate";
import SettingsPage from "./pages/Settings/settings";
import TradeNowPage from "./pages/Trade/trade";
import TransactionsPage from "./pages/Transactions/transactions";
import WithdrawalPage from "./pages/Withdrawals/withdrawals";
import ProtectedRoute from "./components/PrivateRoutes/userRoute";
import NotFoundPage from "./pages/404/NotFound";
import ResendVerifyEmailPage from "./pages/Auth/resendVerifyEmail";
import SignInPage from "./pages/Auth/signin";
import RatesPage from "./pages/Rates/rates";
import SellCryptoPage from "./pages/Crypto/crypto";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" exact element={<LoginPage />} />
          <Route path="/login" exact element={<SignInPage />} />
          <Route path="/register" element={<SignUpPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/verify-info" element={<VerifyInfoPage />} />
          <Route
            path="/email-verification"
            element={<ResendVerifyEmailPage />}
          />
          <Route path="/auth/email/verify" element={<VerifySuccessPage />} />
          <Route path="/set-pin" element={<SetPinPage />} />
          <Route
            path="/reset-password/:token"
            element={<ResetPasswordPage />}
          />
          <Route path="/rates" element={<RatesPage />} />

          <Route element={<ProtectedRouteWithSidebar />}>
            <Route
              path="/dashboard"
              element={<DashboardPage />}
            />
            <Route
              path="/trade-now"
              element={<TradeNowPage />}
            />
            <Route
              path="/sell-crypto"
              element={<SellCryptoPage />}
            />
            <Route
              path="/rate-calculator"
              element={<RateCalculatorPage />}
            />
            <Route
              path="/transactions"
              element={<TransactionsPage />}
            />
            <Route
              path="/withdrawals"
              element={<WithdrawalPage />}
            />
            <Route
              path="/settings"
              element={<SettingsPage />}
            />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </div>
  );
}

function ProtectedRouteWithSidebar() {
  return (
    <ProtectedRoute>
      <SidebarComp>
        <Outlet />
      </SidebarComp>
    </ProtectedRoute>
  );
}

export default App;
