import { useEffect, useState } from "react";
import Modal from "../Modals/modal";
import KYCDetail from "./KYCDetail";
import LevelTwo from "./forms/LevelTwo";
import LevelThree from "./forms/LevelThree";
import LevelFour from "./forms/LevelFour";
import LevelTwoGH from "./forms/gh/LevelTwoGH";
import LevelThreeGH from "./forms/gh/LevelThreeGH";
import LevelFourGH from "./forms/gh/LevelFourGH";
import { useDispatch, useSelector } from "react-redux";
import { getKYCSettings, getUserInfo } from "../../redux/Auth/kycActions";
import cogoToast from "cogo-toast";

function KYCStatus() {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const { userInfo } = useSelector((state) => state.auth);
  const [currentLevel, setCurrentLevel] = useState(
    Number(userInfo?.kyc?.current_level)
  );
  const [selectedLevel, setSelectedLevel] = useState(null);

  const handleKYCCompletion = () => {
    dispatch(getUserInfo())
      .unwrap()
      .then((data) => {
        setCurrentLevel(data.data.kyc.current_level);
      });
    setSelectedLevel(null);
    setShow(false);
  };

  useEffect(() => {
    dispatch(getKYCSettings());
  }, [dispatch]);

  return (
    <>
      <div
        className="py-5 px-4 flex justify-between w-full max-w-lg cursor-pointer rounded hover:bg-[#F1F1F1]"
        onClick={() => setShow(true)}
      >
        <span className="text-[#235643] text-[20px]">KYC Level</span>
        <div className="flex gap-4 items-center">
          <span className="bg-[#FAC915] px-2 py-1 rounded text-[10px]">
            Level {currentLevel}
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m8.25 4.5 7.5 7.5-7.5 7.5"
            />
          </svg>
        </div>
      </div>

      {/* <button onClick={() => setSelectedLevel(true)}>Show form</button> */}

      {/* KYC STATUS MODAL */}
      <Modal show={show} showModal={() => setShow(!show)} title="KYC Levels">
        <div className="mt-8 mb-6 grid gap-4 grid-cols-2">
          <KYCDetail
            level="1"
            isCurrent={currentLevel === 1}
            onSelect={() => setSelectedLevel(1)}
          />
          <KYCDetail
            level="2"
            isCurrent={currentLevel === 2}
            onSelect={() => setSelectedLevel(2)}
          />
          <KYCDetail
            level="3"
            isCurrent={currentLevel === 3}
            onSelect={() => setSelectedLevel(3)}
          />
          <KYCDetail
            level="4"
            isCurrent={currentLevel === 4}
            onSelect={() => {
              cogoToast.info("You've reached the current maximum level.", {
                position: "top-right",
              });
            }}
          />
        </div>
      </Modal>

      {/* KYC FORMS based on each level */}
      <Modal
        show={selectedLevel}
        showModal={() => setSelectedLevel(null)}
        title="Upgrade Account"
      >
        <div className="mt-8 mb-6">
          <p className="text-black text-[15px] text-center">
            Upgrade your Cardvest account with more personal information
          </p>

          {userInfo.nationality === "Nigeria" && (
            <div>
              {selectedLevel === 1 && (
                <LevelTwo onComplete={handleKYCCompletion} />
              )}
              {selectedLevel === 2 && (
                <LevelThree onComplete={handleKYCCompletion} />
              )}
              {selectedLevel === 3 && (
                <LevelFour onComplete={handleKYCCompletion} />
              )}
            </div>
          )}

          {userInfo.nationality === "Ghana" && (
            <div>
              {selectedLevel === 1 && (
                <LevelTwoGH onComplete={handleKYCCompletion} />
              )}
              {selectedLevel === 2 && (
                <LevelThreeGH onComplete={handleKYCCompletion} />
              )}
              {selectedLevel === 3 && (
                <LevelFourGH onComplete={handleKYCCompletion} />
              )}
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}

export default KYCStatus;
