import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUrl } from "../config";
import cogoToast from "cogo-toast";
import { Mixpanel } from "../../components/Helpers/MixPanel";
import "../../components/Helpers/firebase";
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

export const getDataBundles = createAsyncThunk(
  "getDataBundles",
  async ({ network }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${apiUrl}transactions/data/plans`,
        {
          product: network,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

export const purchaseDataBundles = createAsyncThunk(
  "purchaseDataBundles",
  async (
    { currency, phone_no, code, product, amount },
    { rejectWithValue, dispatch, getState }
  ) => {
    const userId = getState().auth.userInfo.id;
    try {
      const res = await axios.post(
        `${apiUrl}transactions/data/purchase`,
        {
          currency,
          phone_no,
          code,
          product,
          amount,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        Mixpanel.identify(userId);
        Mixpanel.track("Data Purchase", {
          "Phone Number": phone_no,
          Amount: amount,
          Currency: currency,
          Product: product,
        });
        firebase.analytics().logEvent("buy_data", {
          user_id: userId,
          network: product,
          amount: amount,
          number: phone_no,
          currency: currency,
        });
        cogoToast.success("Data purchase successful!");
        return res.data;
      }
    } catch (error) {
      if (error.response.status === 403) {
        cogoToast.error("You have insufficient funds in your wallet!");
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

export const purchaseAirtime = createAsyncThunk(
  "purchaseAirtime",
  async (
    { currency, phone_no, product, amount },
    { rejectWithValue, dispatch, getState }
  ) => {
    const userId = getState().auth.userInfo.id;
    try {
      const res = await axios.post(
        `${apiUrl}transactions/airtime`,
        {
          currency,
          phone_no,
          product,
          amount,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        Mixpanel.identify(userId);
        Mixpanel.track("Airtime Purchase", {
          "Phone Number": phone_no,
          Amount: amount,
          Currency: currency,
          Provider: product,
        });
        firebase.analytics().logEvent("buy_airtime", {
          user_id: userId,
          network: product,
          amount: amount,
          number: phone_no,
          currency: currency,
        });
        cogoToast.success("Airtime purchase successful!");
        return res.data;
      }
    } catch (error) {
      if (error.response.status === 400) {
        cogoToast.info("Your airtime is on its way!");
      }
      if (error.response.status === 403) {
        cogoToast.error("You have insufficient funds in your wallet!");
      }
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

export const getCablePlans = createAsyncThunk(
  "getCablePlans",
  async ({ provider }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${apiUrl}transactions/cable/plans`,
        {
          product: provider,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

export const purchaseCable = createAsyncThunk(
  "purchaseCable",
  async (
    { currency, phone_no, product, amount, code, smart_card_no },
    { rejectWithValue, dispatch, getState }
  ) => {
    const userId = getState().auth.userInfo.id;
    try {
      const res = await axios.post(
        `${apiUrl}transactions/cable/purchase`,
        {
          currency,
          phone_no,
          product,
          amount,
          code,
          smart_card_no,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        Mixpanel.identify(userId);
        Mixpanel.track("Cable Plan Purchase", {
          "Phone Number": phone_no,
          Amount: amount,
          Currency: currency,
          Product: product,
          "Smart Card Number": smart_card_no,
        });
        firebase.analytics().logEvent("buy_cable", {
          user_id: userId,
          package: product,
          amount: amount,
          currency: currency,
        });
        cogoToast.success("Cable plan purchase successful!");
        return res.data;
      }
    } catch (error) {
      if (error.response.status === 400) {
        cogoToast.info("Your cable plan is on its way!");
      }
      if (error.response.status === 403) {
        cogoToast.error("You have insufficient funds in your wallet!");
      }
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

export const purchaseElectricity = createAsyncThunk(
  "purchaseElectricity",
  async (
    { currency, phone_no, product, amount, meter_no, meter_type },
    { rejectWithValue, dispatch, getState }
  ) => {
    const userId = getState().auth.userInfo.id;
    try {
      const res = await axios.post(
        `${apiUrl}transactions/electricity/purchase/token`,
        {
          currency,
          phone_no,
          product,
          amount,
          meter_no,
          meter_type,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        Mixpanel.identify(userId);
        Mixpanel.track("Electricity Purchase", {
          "Phone Number": phone_no,
          Amount: amount,
          Currency: currency,
          Product: product,
          "Meter Type": meter_type,
          "Meter Number": meter_no,
        });
        firebase.analytics().logEvent("buy_electricity", {
          user_id: userId,
          provider: product,
          meter_no: meter_no,
          meter_type: meter_type,
          amount: amount,
          currency: currency,
        });
        cogoToast.success("Electricity purchase successful!");
        return res.data;
      }
    } catch (error) {
      if (error.response.status === 400) {
        cogoToast.info("Your electricity purchase is on its way!");
      }
      if (error.response.status === 403) {
        cogoToast.error("You have insufficient funds in your wallet!");
      }
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

export const getWifiPlans = createAsyncThunk(
  "getWifiPlans",
  async ({ provider }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${apiUrl}transactions/wifi/plans`,
        {
          product: provider,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

export const purchaseWifi = createAsyncThunk(
  "purchaseWifi",
  async (
    { currency, device_no, product, code, amount },
    { rejectWithValue, dispatch, getState }
  ) => {
    const userId = getState().auth.userInfo.id;
    try {
      const res = await axios.post(
        `${apiUrl}transactions/wifi/purchase`,
        {
          currency,
          device_no,
          code,
          product,
          amount,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        Mixpanel.identify(userId);
        Mixpanel.track("Wifi Purchase", {
          "Device Number": device_no,
          Amount: amount,
          Currency: currency,
          Product: product,
        });
        firebase.analytics().logEvent("buy_wifi", {
          user_id: userId,
          provider: product,
          amount: amount,
          currency: currency,
        });
        cogoToast.success("Wifi purchase successful!");
        return res.data;
      }
    } catch (error) {
      if (error.response.status === 400) {
        cogoToast.info("Your wifi purchase is on its way!");
      }
      if (error.response.status === 403) {
        cogoToast.error("You have insufficient funds in your wallet!");
      }
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);
