const ClipboardIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7828 13.4V16.4C15.7828 20.4 14.2974 22 10.584 22H7.05623C3.3428 22 1.85742 20.4 1.85742 16.4V12.6C1.85742 8.6 3.3428 7 7.05623 7H9.8413"
        stroke="#235643"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7813 13.4H12.8106C10.5825 13.4 9.83984 12.6 9.83984 10.2V7L15.7813 13.4Z"
        stroke="#235643"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7695 2H14.483"
        stroke="#235643"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.49805 5C6.49805 3.34 7.74205 2 9.28312 2H11.7154"
        stroke="#235643"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.4231 8V14.19C20.4231 15.74 19.2534 17 17.8145 17"
        stroke="#235643"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.4237 8H17.6386C15.5498 8 14.8535 7.25 14.8535 5V2L20.4237 8Z"
        stroke="#235643"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ClipboardIcon;
