import { useFormik } from "formik";
import React from "react";
import QuestionMark from "../../icons/question-mark";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo, levelTwoKYC } from "../../../redux/Auth/kycActions";
import cogoToast from "cogo-toast";
import Button from "../../ui/button";
import Input from "../../ui/input";

const validator = Yup.object({
  bvn: Yup.string()
    .length(11, "BVN must be exactly 11 digits")
    .required("BVN is required")
    .label("BVN"),
});

function LevelTwo({ onComplete }) {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.auth);

  const { handleChange, handleSubmit, values, touched, errors, isValid } =
    useFormik({
      initialValues: {
        bvn: "",
      },
      validationSchema: validator,
      onSubmit: async (values) => {
        try {
          const response = await dispatch(
            levelTwoKYC({
              bvn: values.bvn,
            })
          ).unwrap();

          cogoToast.success(response.message, {
            position: "top-right",
          });

          dispatch(getUserInfo()).then(() => {
            onComplete && onComplete();
          });
        } catch (error) {
          cogoToast.error(error.message, {
            position: "top-right",
          });
        }
      },
    });

  return (
    <form className="mt-10" onSubmit={handleSubmit}>
      <div className="flex flex-col gap-4 gap-y-8 min-h-[300px]">
        <div className="">
          <Input
            type="text"
            id="bvn"
            name="bvn"
            placeholder="Enter 11-digit BVN"
            value={values.bvn}
            onChange={handleChange}
            error={touched.bvn && errors.bvn}
          />
          <div className="flex gap-1 items-center">
            <QuestionMark className={"w-4 h-4"} />
            <small>Why do you need my BVN?</small>
          </div>
        </div>
      </div>

      <div className="mt-8">
        <Button
          type="submit"
          disabled={!isValid || isLoading}
          loading={isLoading}
        >
          Upgrade Account
        </Button>
      </div>
    </form>
  );
}

export default LevelTwo;
