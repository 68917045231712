import classNames from "classnames";
import { useState } from "react";
import { useSelector } from "react-redux";
import { formatCurrency } from "../../utils/functions";

function KYCDetail({ isCurrent = false, onSelect, level = 1 }) {
  const { userInfo, kycSettings } = useSelector((state) => state.auth);
  const [kycData] = useState(userInfo.kyc);
  const isPending =
    kycData && kycData.pending_level && kycData.upgrade_status === "pending";
  const isFailed =
    kycData && kycData.pending_level && kycData.upgrade_status === "failed";

  const levelSettings = kycSettings?.find((elem) => elem.level === level) || {
    level: "1",
    description: "level 1",
    daily_withdrawal_limit: 0,
    cummulative_withdrawal_limit: 0,
  };

  return (
    <div className="bg-primary rounded overflow-hidden pt-4 h-[155px] relative">
      {isCurrent && (
        <span className="bg-[#809225] text-xs text-white px-3 py-0.5 rounded-b absolute top-0 left-4">
          Current
        </span>
      )}

      {isPending && kycData.pending_level === level && (
        <span className="bg-[#ed7e3e] text-xs text-white px-3 py-0.5 rounded-b absolute top-0 left-4">
          Pending
        </span>
      )}

      {isFailed && kycData.pending_level === level && (
        <span className="bg-[#ed3e3e] text-xs text-white px-3 py-0.5 rounded-b absolute top-0 left-4">
          Failed: {kycData.comment}
        </span>
      )}
      <div className="flex justify-between items-center px-8">
        {/* verification badge */}
        <div className="relative w-[41px] h-[41px] flex items-center justify-center">
          <svg
            width="41"
            height="41"
            viewBox="0 0 41 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute top-0"
          >
            <path
              d="M20.5 0L24.336 2.45318L28.8381 1.77232L31.3446 5.57364L35.7345 6.78282L36.4782 11.275L39.9967 14.1652L38.8489 18.5714L40.8877 22.6428L38.047 26.2014L38.2535 30.75L34.211 32.8455L32.5496 37.0848L28.0043 37.3549L24.7622 40.552L20.5 38.95L16.2378 40.552L12.9957 37.3549L8.4504 37.0848L6.78898 32.8455L2.74648 30.75L2.95301 26.2014L0.112301 22.6428L2.15107 18.5714L1.00334 14.1652L4.52183 11.275L5.26553 6.78282L9.65536 5.57364L12.1619 1.77232L16.664 2.45318L20.5 0Z"
              fill={isCurrent ? "#FFCD2E" : "#FFFFFF"}
            />
          </svg>
          <span className="z-10 font-bold">{level}</span>
        </div>

        <div className="space-y-2">
          {/* cummulative */}
          <div className="flex flex-col text-right">
            <span className="text-[#518471] text-[10px]">
              Cummulative Limit
            </span>
            <span className="text-white font-bold text-sm">
              NGN {formatCurrency(levelSettings.cummulative_withdrawal_limit)}
            </span>
          </div>

          {/* daily */}
          <div className="flex flex-col text-right">
            <span className="text-[#518471] text-[10px]">
              Daily Withdrawal Limit
            </span>
            <span className="text-white font-bold text-sm">
              NGN {formatCurrency(levelSettings.daily_withdrawal_limit)}
            </span>
          </div>
        </div>
      </div>

      {/* CTA */}
      {isCurrent && (
        <div
          role="button"
          className={classNames(
            "bg-[#1C4F3C] text-[12px] flex text-white items-center justify-center gap-2 py-3 mt-4 cursor-pointer",
            {
              "cursor-not-allowed": isPending,
            }
          )}
          onClick={() => !isPending && onSelect && onSelect()}
        >
          <span
            className={classNames({
              "opacity-60": isPending,
            })}
          >
            Upgrade Now{" "}
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-3 h-3"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m8.25 4.5 7.5 7.5-7.5 7.5"
            />
          </svg>
        </div>
      )}
    </div>
  );
}

export default KYCDetail;
