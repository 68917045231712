import { createSlice } from '@reduxjs/toolkit'
import {  getUSDTNetworks, getUSDTWithdrawalRate, getWithdrawalAccount, getWithdrawals, withdrawToFiat, withdrawUSDT } from './withdrawalActions'

const initialState = {
  value: 0,
  loading: false,
  total: 0,
  withdrawals: [],
  withdrawalAccounts: [],
  withdrawLoader: false,
  networks: [],
  USDTRate: {},
  withdrawSuccess: false
}

export const withdrawalSlice = createSlice({
  name: 'withdrawals',
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    clearWithdrawal: (state) =>{
      state.withdrawSuccess = false
    }
   },
  extraReducers:{
    
    [getWithdrawals.pending]: (state) =>{
      state.loading = true
    },
    [getWithdrawals.fulfilled]: (state, {payload}) =>{
        state.loading = false
        state.withdrawals = payload.data
        state.total = payload.meta.total
    },
    [getWithdrawals.rejected]: (state) =>{
      state.loading = false
    },
    [getUSDTNetworks.pending]: (state) =>{

    },
    [getUSDTNetworks.fulfilled]: (state,{payload}) =>{
        state.networks = payload.data
    },
    [getUSDTNetworks.rejected]: (state)=>{

    },
    [getUSDTWithdrawalRate.pending]: (state) =>{

    },
    [getUSDTWithdrawalRate.fulfilled]: (state,{payload}) =>{
        state.USDTRate = payload.data
    },
    [getUSDTWithdrawalRate.rejected]: (state) =>{

    },
    //   get withdrawal accounts
    [getWithdrawalAccount.pending]: (state) => {
        state.loading = true
      },
      [getWithdrawalAccount.fulfilled]: (state, { payload }) => {
         state.loading = false
         state.withdrawalAccounts = payload.data
      },
      [getWithdrawalAccount.rejected]: (state, { payload }) => {
        state.loading = false
      },

      [withdrawToFiat.pending]: (state) =>{
        state.withdrawLoader = true
      },
      [withdrawToFiat.fulfilled]: (state,{payload}) =>{
        state.withdrawLoader = false
        state.withdrawSuccess = true
      },
      [withdrawToFiat.rejected]: (state) =>{
        state.withdrawLoader = false
      },
      [withdrawUSDT.pending]:(state) =>{
        state.withdrawLoader = true
      },
      [withdrawUSDT.fulfilled]:(state) =>{
        state.withdrawLoader = false
        state.withdrawSuccess = true
      },
      [withdrawUSDT.rejected]:(state) =>{
        state.withdrawLoader = false
      }

   

  }
})

// Action creators are generated for each case reducer function
export const {increment, clearWithdrawal } = withdrawalSlice.actions

export default withdrawalSlice.reducer