import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import cogoToast from "cogo-toast";
import Button from "../../../ui/button";
import {
  getUserInfo,
  ghanaLevelFourKYC,
} from "../../../../redux/Auth/kycActions";
import Input from "../../../ui/input";

const validator = Yup.object({
  state: Yup.string().required("Your state is required").label("State"),
  city: Yup.string().required("Your city is required").label("City"),
  district: Yup.string()
    .required("Your district is required")
    .label("District"),
  street: Yup.string()
    .required("Street address is important")
    .label("Street Address"),
  house_no: Yup.string()
    .required("Please enter your house number or apartment description")
    .label("House No"),
});

function LevelFourGH({ onComplete }) {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.auth);

  const { handleChange, handleSubmit, values, isValid } = useFormik({
    initialValues: {
      state: "",
      district: "",
      city: "",
      street: "",
      house_no: "",
    },
    validationSchema: validator,
    onSubmit: async (values) => {
      try {
        const response = await dispatch(
          ghanaLevelFourKYC({
            state: values.state,
            city: values.city,
            district: values.district,
            street: values.street,
            house_no: values.house_no,
          })
        ).unwrap();

        cogoToast.success(response.message, {
          position: "top-right",
        });

        dispatch(getUserInfo()).then(() => {
          onComplete && onComplete();
        });
      } catch (error) {
        cogoToast.error(error.message, {
          position: "top-right",
        });
      }
    },
  });

  return (
    <form className="mt-10 " onSubmit={handleSubmit}>
      <div className="flex flex-col gap-4 gap-y-8 min-h-[300px]">
        {/* State */}
        <Input
          type="text"
          id="state"
          name="state"
          placeholder="Enter State e.g. Lagos"
          label={"State"}
          value={values.state}
          onChange={handleChange}
        />

        {/* City */}
        <Input
          type="text"
          id="city"
          name="city"
          placeholder="Enter City e.g. Ikeja"
          label={"City"}
          value={values.city}
          onChange={handleChange}
        />

        {/* District */}
        <Input
          type="text"
          id="district"
          name="district"
          placeholder="District"
          label={"District"}
          value={values.district}
          onChange={handleChange}
        />

        {/* Enter Street Address */}
        <Input
          type="text"
          id="street"
          name="street"
          placeholder="Enter Street Address"
          label={"Street Address"}
          value={values.street}
          onChange={handleChange}
        />

        {/* Enter House No */}
        <Input
          type="text"
          id="house_no"
          name="house_no"
          placeholder="Enter house no"
          label="House No./Description"
          value={values.house_no}
          onChange={handleChange}
        />
      </div>

      <div className="mt-8">
        <Button
          type="submit"
          disabled={!isValid || isLoading}
          loading={isLoading}
        >
          Upgrade Account
        </Button>
      </div>
    </form>
  );
}

export default LevelFourGH;
