import { useFormik } from "formik";
import { SellCryptoValidator } from "./validator";
import { useDispatch, useSelector } from "react-redux";
import chevron_grey from "../../assets/icons/chevron_grey.svg";
import { formatCurrency, toMoney } from "../../utils/functions";
import { useEffect } from "react";
import {
  fetchAvailableCoins,
  sellCrypto,
} from "../../redux/Crypto/cryptoActions";
import Modal from "../../components/Modals/modal";
import {
  resetSellForm,
  setAmountInDollars,
  setCoin,
  setCoinAmount,
  setShowPaymentWallet,
  setWallet,
} from "../../redux/Crypto/crypto";
import InfoCircleIcon from "../../components/icons/info-circle";
import ClipboardIcon from "../../components/icons/clipboard";
import ShareIcon from "../../components/icons/share";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import cogoToast from "cogo-toast";
import Button from "../../components/ui/button";

const SellCryptoPage = () => {
  const dispatch = useDispatch();
  const {
    selectedWallet,
    selectedCoin,
    availableCoins,
    coinAmount,
    amountInDollars,
    amountInSelectedCurrency,
    exchangeRulesToSelectedCurrency,
    tradeDetails,
    showPaymentModal,
    status,
    error,
  } = useSelector((state) => state.crypto);
  console.log("load status:", status);

  const minAmount = Number(exchangeRulesToSelectedCurrency?.[0]?.min ?? 5);
  const maxAmount = Number(
    exchangeRulesToSelectedCurrency?.[
      exchangeRulesToSelectedCurrency.length - 1
    ]?.max
  );

  const [, copyWalletAddress] = useCopyToClipboard();
  const [, copyWalletAmount] = useCopyToClipboard();

  const {
    setFieldValue,
    handleChange,
    handleSubmit,
    values,
    touched,
    errors,
    isValid,
    resetForm,
  } = useFormik({
    initialValues: {
      currency: selectedWallet,
      coin: selectedCoin,
      amount: coinAmount,
      amountInUSD: amountInDollars,
    },
    validationSchema: SellCryptoValidator,
    onSubmit: (values) => {
      // check if the amount selected is within the right range
      if (minAmount > values.amountInUSD || maxAmount < values.amountInUSD) {
        const minAmountFormatted = toMoney(minAmount, "USD");
        const maxAmountFormatted = toMoney(maxAmount, "USD");

        cogoToast.error(
          `The amount in USD must be within USD ${minAmountFormatted} and USD ${maxAmountFormatted}`,
          { position: "top-right" }
        );

        return;
      }

      dispatch(
        sellCrypto({
          currency: values.currency,
          coin: values.coin,
          amount: values.amountInUSD,
        })
      )
        .unwrap()
        .catch((error) => {
          cogoToast.error(
            `This coin/service is currently not available. If problem persist, reach out to support.`,
            { position: "top-right" }
          );
        });
    },
  });

  const handleTransactionComplete = () => {
    const localCurrency = selectedWallet;

    // Reset form fields and close modal
    dispatch(setShowPaymentWallet(false));
    dispatch(resetSellForm());

    cogoToast.success(
      `Once your payment is confirmed, the ${localCurrency} equivalent will be credited to your account. You can also check you payment status on the transaction history page.`,
      {
        position: "top-right",
      }
    );
  };

  useEffect(() => {
    dispatch(fetchAvailableCoins()).unwrap().catch(console.log);
  }, [dispatch]);

  useEffect(() => {
    setFieldValue("amount", coinAmount);
    setFieldValue("amountInUSD", amountInDollars);
  }, [amountInDollars, coinAmount]);

  useEffect(() => {
    return () => {
      resetForm();
      dispatch(resetSellForm());
    };
  }, []);

  return (
    <>
      <div>
        <h4 className="text-[#212121] font-Satoshi700 text-sm md:text-lg">
          Sell Crypto
        </h4>

        <div className="grid gap-6 md:grid-cols-3">
          <div className="mt-8 md:col-span-2">
            <div className="mt-8">
              <h6 className="text-[#212121] font-Satoshi700 text-sm">
                Trade details
              </h6>
              <p className="text-[#909090] font-Satoshi500 mt-1 text-xs">
                Get the current value for your transactions
              </p>
            </div>

            <form className="mt-10 " onSubmit={handleSubmit}>
              <div className="grid grid-cols-2 gap-4 gap-y-8">
                <div className="">
                  <label
                    htmlFor="wallet"
                    className="block mb-2 font-Satoshi500 text-[#909090] text-xs"
                  >
                    Payout Wallet
                    <sup className="text-[#DC0000] text-xs top-[-0.2em] left-[-0.01em]">
                      *
                    </sup>
                  </label>
                  <div className="relative">
                    <select
                      type="text"
                      name="currency"
                      placeholder="Select Category"
                      className={
                        touched.currency && errors.currency
                          ? "appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4"
                          : "appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4"
                      }
                      value={values.currency}
                      onChange={(e) => {
                        handleChange(e);
                        dispatch(setWallet(e.target.value));

                        setFieldValue("amount", "");
                        setFieldValue("amountInUSD", "");
                      }}
                    >
                      <option value="" selected>
                        Select Wallet
                      </option>
                      <option value="NGN" selected>
                        Naira (NGN)
                      </option>
                      <option value="GHS">Cedis (GHS)</option>
                    </select>
                    <img
                      className="absolute transform -translate-y-1/2 cursor-pointer top-1/2 right-4"
                      src={chevron_grey}
                      alt="arrow"
                    />
                  </div>
                  {touched.wallet && errors.wallet ? (
                    <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                      {touched.wallet && errors.wallet}
                    </small>
                  ) : null}
                </div>

                <div className="">
                  <label
                    htmlFor="wallet"
                    className="block mb-2 font-Satoshi500 text-[#909090] text-xs"
                  >
                    Coin{" "}
                    <sup className="text-[#DC0000] text-xs top-[-0.2em] left-[-0.01em]">
                      *
                    </sup>
                  </label>
                  <div className="relative">
                    <select
                      type="text"
                      name="wallet"
                      placeholder="Select Coin"
                      className={
                        touched.wallet && errors.wallet
                          ? "appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4"
                          : "appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4"
                      }
                      value={values.coin}
                      onChange={(e) => {
                        setFieldValue("coin", e.target.value);
                        const selectedCoin = availableCoins.find(
                          (coin) => coin.name === e.target.value
                        );
                        dispatch(setCoin(selectedCoin));

                        setFieldValue("amount", "");
                        setFieldValue("amountInUSD", "");
                      }}
                    >
                      <option value="" selected>
                        Select Coin
                      </option>
                      {availableCoins.map((coin) => (
                        <option key={coin.name} value={coin.name}>
                          {coin.label} ({coin.name})
                        </option>
                      ))}
                    </select>
                    <img
                      className="absolute transform -translate-y-1/2 cursor-pointer top-1/2 right-4"
                      src={chevron_grey}
                      alt="arrow"
                    />
                  </div>
                  {touched.wallet && errors.wallet ? (
                    <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                      {touched.wallet && errors.wallet}
                    </small>
                  ) : null}
                </div>

                <div className="">
                  <label
                    htmlFor="amount"
                    className="block mb-2 font-Satoshi500 text-[#909090] text-xs"
                  >
                    Amount {selectedCoin ? `(of ${selectedCoin}) to Sell` : ""}
                    <sup className="text-[#DC0000] text-xs top-[-0.2em] left-[-0.01em]">
                      *
                    </sup>
                  </label>
                  <input
                    type="number"
                    id="amount"
                    name="amount"
                    placeholder="Enter Amount"
                    step={0.00000001}
                    disabled={!(selectedCoin && selectedWallet)}
                    value={values.amount}
                    className={
                      touched.amount && errors.amount
                        ? "appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4"
                        : "appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4"
                    }
                    onChange={(e) => {
                      handleChange(e);
                      dispatch(setCoinAmount(e.target.value));
                    }}
                  />
                  {touched.amount && errors.amount ? (
                    <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                      {touched.amount && errors.amount}
                    </small>
                  ) : null}
                </div>

                <div className="">
                  <label
                    htmlFor="amountInUSD"
                    className="block mb-2 font-Satoshi500 text-[#909090] text-xs"
                  >
                    Amount in USD{" "}
                    {exchangeRulesToSelectedCurrency && (
                      <span className="text-[#000] text-xs ">
                        ({minAmount ? toMoney(minAmount, "USD") : "Nil"}
                        {" - "}
                        {maxAmount ? toMoney(maxAmount, "USD") : "Nil"})
                      </span>
                    )}
                  </label>
                  <input
                    type="number"
                    id="amountInUSD"
                    name="amountInUSD"
                    placeholder="USD Equivalent"
                    disabled={!(selectedCoin && selectedWallet)}
                    step={0.01}
                    value={values.amountInUSD}
                    className={
                      touched.amountInUSD && errors.amountInUSD
                        ? "appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4"
                        : "appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4"
                    }
                    onChange={(e) => {
                      handleChange(e);
                      dispatch(setAmountInDollars(e.target.value));
                    }}
                  />
                  {touched.amountInUSD && errors.amountInUSD ? (
                    <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                      {touched.amountInUSD && errors.amountInUSD}
                    </small>
                  ) : null}

                  {exchangeRulesToSelectedCurrency && (
                    <div className="flex gap-1">
                      <InfoCircleIcon />
                      <small>
                        The minimum amount is $
                        {exchangeRulesToSelectedCurrency[0].min}
                      </small>
                    </div>
                  )}
                </div>

                <div className="mt-4">
                  <div className="bg-[#EEF0F2] py-4 px-6 mt-3">
                    <p className="text-[#212121] font-Satoshi500 text-sm uppercase">
                      You receive
                    </p>
                  </div>
                  <div className="bg-[#F7F9FB] rounded-[4px] py-4 px-6 flex justify-between">
                    <p className="text-[#212121] font-Satoshi500 text-sm">
                      Total
                    </p>
                    <p className="text-[#212121] font-Satoshi500 text-sm">
                      {selectedWallet}{" "}
                      {amountInSelectedCurrency
                        ? formatCurrency(amountInSelectedCurrency)
                        : "0.00"}
                    </p>
                  </div>
                </div>
              </div>

              <div className="max-w-sm mt-8">
                <Button
                  type="submit"
                  disabled={!isValid || status === "loading"}
                  loading={status === "loading"}
                >
                  Proceed
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Payment Details Modal */}
      <Modal
        show={tradeDetails !== null && showPaymentModal}
        showModal={() => console.log("can't be closed")}
        title="Trade Details"
      >
        {tradeDetails && (
          <div className="flex flex-col items-center gap-4">
            <div className="mt-10">
              <p className="text-sm">
                Send {coinAmount} <b>{tradeDetails.coin}</b> to the address
                provided and it will be automatically converted to NAIRA or
                CEDIS depending on the preferred payout wallet, and based on the
                current rate.
              </p>
            </div>

            <p className="w-full font-bold text-left">
              Amount in {tradeDetails.coin}:{" "}
            </p>
            <div
              onClick={() => {
                copyWalletAmount(coinAmount);
                cogoToast.success(
                  "Amount of coin to send copied to clipboard",
                  {
                    position: "top-right",
                  }
                );
              }}
              className="bg-[#EEEEEE] flex px-4 py-4 gap-2 cursor-pointer justify-center w-full"
            >
              <p className="overflow-ellipsis">
                {coinAmount} {tradeDetails.coin}
              </p>
              <ClipboardIcon />
            </div>

            <p className="mt-8 font-bold text-center">
              Scan QR code to send {tradeDetails.coin}
            </p>

            <img
              src={tradeDetails.payment_details.qrCode}
              alt="Payment QR code"
              className={"h-48 w-48"}
            />

            <p className="mt-8 font-bold text-center">OR</p>

            <div
              onClick={() => {
                copyWalletAddress(tradeDetails.payment_details.address);
                cogoToast.success("Wallet address copied to clipboard", {
                  position: "top-right",
                });
              }}
              className="bg-[#EEEEEE] flex px-4 py-4 gap-2 cursor-pointer justify-center w-full"
            >
              <p className="overflow-ellipsis">
                {tradeDetails.payment_details.address}
              </p>
              <ClipboardIcon />
            </div>

            <button className="bg-[#235643] rounded-[4px] disabled:bg-[#235643] disabled:opacity-[0.7] text-sm font-Satoshi400 justify-center w-full flex gap-2 items-center py-4 px-6 text-[#FFFFFF] hover:bg-[#235643] hover:opacity-[0.9] transition-all">
              <ShareIcon /> Share Address
            </button>

            <div className="mt-8"></div>
            {/* <a href="#">How it works</a> */}
            <button
              onClick={handleTransactionComplete}
              className="bg-[hsl(47,96%,53%)] rounded-[4px] text-[#212121] disabled:bg-[hsl(47,96%,80%)]  disabled:opacity-[0.7] text-sm  font-Satoshi400 justify-center w-full flex items-center py-4 px-6  hover:bg-[hsl(47,50%,53%)] hover:opacity-[0.9] transition-all"
            >
              I have sent the Crypto
            </button>
          </div>
        )}
      </Modal>
    </>
  );
};

export default SellCryptoPage;
