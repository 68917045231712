import React,{useEffect, useState} from 'react'
import { Form, Formik } from 'formik'
import { resetPasswordValidator } from '../../validationSchema/validator'
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom'
import eye_off from '../../assets/icons/Hide.svg'
import eye from '../../assets/icons/eye.svg'
import logo from '../../assets/icons/logo.svg'
import { useDispatch, useSelector } from 'react-redux'
import { userResetPassword } from '../../redux/Auth/authActions'
import { clearIsResetSuccess } from '../../redux/Auth/auth'
import OnboardingSidebar from '../../components/OnboardingSidebar/sidebar'

const ResetPasswordPage = () => {
    const search = useLocation().search;
    const {token} = useParams()
    const dispatch = useDispatch()
    const [passwordShown, setPasswordShown] = useState(false)
    const [passwordConfirmShown, setPasswordConfirmShown] = useState(false);
    const navigate = useNavigate()
    const {loading, isResetSuccess} = useSelector((state) => state.auth);

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true)
    }

    const toggleConfirmPasswordVisiblity = () => {
        setPasswordConfirmShown(passwordConfirmShown ? false : true);
    };

    const handleSubmit = (values) => {
        const email = new URLSearchParams(search).get("email");
        dispatch(userResetPassword({
            email: email,
            token: token,
            password: values.password,
            password_confirmation: values.confirm_password
        }))
    }

    useEffect(()=>{
        if(isResetSuccess){
            setTimeout(() => {
                navigate('/') 
                dispatch(clearIsResetSuccess()) 
            }, 2000);
        }
    },[isResetSuccess])

    return ( 
        <>
             <div className='grid md:grid-cols-2 font-Satoshi400'>
                <OnboardingSidebar />
                <div className='px-6 md:px-20 2xl:px-40 flex flex-col h-full min-h-screen justify-center'>
                   <div className='flex justify-center md:hidden'>
                         <img src={logo} alt="logo" />
                    </div>

                    <div className='text-center mt-5'>
                        <h6 className='text-[#212121] font-Satoshi700 text-xl md:text-2xl'>Reset Password</h6>
                            <p className='text-[#909090] text-xs'>Enter your new password below</p>
                    </div>

                    <Formik
                        onSubmit={(values, { setSubmitting }) =>
                        handleSubmit(values, setSubmitting)
                        }
                        validationSchema={resetPasswordValidator}
                        initialValues={{
                        password: '',
                        confirm_password: '',
                        }}
                    >
                        {({
                        handleChange,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        values,
                        touched,
                        errors,
                        }) => (
                        <Form className="mt-10 md:mt-16" onSubmit={handleSubmit}>
                        
                           <div className="mt-6">
                            <label htmlFor='password' className="block mb-2 text-[#909090] text-xs">
                                New Password
                            </label>
                            <div className="relative">
                                <input
                                type={passwordShown ? 'text' : 'password'}
                                name="password"
                                placeholder="Enter your new password"
                                className={
                                    touched.password && errors.password
                                        ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                        : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                    }
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                />
                                <img
                                onClick={togglePasswordVisiblity}
                                src={passwordShown ? eye_off : eye}
                                alt="eye"
                                className="absolute w-[18px] h-[18px] top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
                                />
                            </div>
                            {touched.password && errors.password ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.password && errors.password}
                                </small>
                            ) : null}
                            </div>

                            <div className="mt-6">
                            <label htmlFor='confirm_password' className="block mb-2 text-[#909090] text-xs">
                                Confirm Password
                            </label>
                            <div className="relative">
                                <input
                                type={passwordConfirmShown ? 'text' : 'password'}
                                name="confirm_password"
                                placeholder="Confirm your password"
                                className={
                                    touched.confirm_password && errors.confirm_password
                                        ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                        : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                    }
                                value={values.confirm_password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                />
                                <img
                                onClick={toggleConfirmPasswordVisiblity}
                                src={passwordConfirmShown ? eye_off : eye}
                                alt="eye"
                                className="absolute w-[18px] h-[18px] top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
                                />
                            </div>
                            {touched.confirm_password && errors.confirm_password ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.confirm_password && errors.confirm_password}
                                </small>
                            ) : null}
                            </div>

                            
                            <div className="mt-6">
                            <button
                                type="submit"
                                disabled={loading}
                                className="bg-[#235643] 
                                disabled:bg-[#235643] 
                                disabled:opacity-[0.7]
                                text-sm
                                rounded-md w-full py-4 text-[#fff] font-medium hover:bg-[#235643] hover:opacity-[0.9] transition-all"
                            >
                                Submit
                            </button>
                            </div>

                           
                            <div className='text-center mt-10'>
                                <p className='text-[#235643] text-sm'>Remember password? <Link to="/" className='text-[#235643] font-Satoshi500 text-sm'>Login</Link> </p>
                            </div>

                        
                        </Form>
                        )}
                    </Formik>

                </div>
            </div>
        </>
     );
}
 
export default ResetPasswordPage;