import { useFormik } from "formik";
import React from "react";
import chevron_grey from "../../../assets/icons/chevron_grey.svg";
import ImageUploader from "../../ui/image-uploader";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo, levelThreeKYC } from "../../../redux/Auth/kycActions";
import cogoToast from "cogo-toast";
import Button from "../../ui/button";
import Input from "../../ui/input";

const availableID = [
  { key: "passport", value: "International Passport" },
  { key: "driver", value: "Driver's License" },
  { key: "nin", value: "NIN Card" },
  { key: "voter", value: "Voters Card" },
];

const validator = Yup.object({
  selfie: Yup.string().required("Please upload your selfie").label("Selfie"),
  id_type: Yup.string()
    .oneOf(["passport", "nin", "voter", "driver"], "Invalid ID Type")
    .required("Select your preferred Identification Document")
    .label("ID Type"),
  id_number: Yup.string()
    .required("You ID unique number is required")
    .label("ID Number"),
  id_image: Yup.string()
    .required("Please upload the image of your ID Document")
    .label("ID Image"),

  dob: Yup.string().when("id_type", {
    is: (value) => value === "driver" || value === "voter",
    then: Yup.string().required("Date of birth is required"),
  }),
  firstname: Yup.string().when("id_type", {
    is: (value) => value === "driver" || value === "voter",
    then: Yup.string().required("First name is required"),
  }),
  lastname: Yup.string().when("id_type", {
    is: (value) =>
      value === "passport" || value === "driver" || value === "voter",
    then: Yup.string().required("Last name is required"),
  }),
  lga: Yup.string().when("id_type", {
    is: "voter",
    then: Yup.string().required("Local Government Area is required"),
  }),
  state: Yup.string().when("id_type", {
    is: "voter",
    then: Yup.string().required("State is required"),
  }),
});

function LevelThree({ onComplete }) {
  const dispatch = useDispatch();
  const { userInfo, isLoading } = useSelector((state) => state.auth);

  const {
    setFieldValue,
    handleChange,
    handleSubmit,
    values,
    touched,
    errors,
    isValid,
  } = useFormik({
    initialValues: {
      selfie: "",
      id_type: "",
      id_number: "",
      id_image: "",

      dob: "",
      firstname: userInfo.firstname,
      lastname: userInfo.lastname,
      lga: "",
      state: "",
    },
    validationSchema: validator,
    onSubmit: async (values) => {
      try {
        const response = await dispatch(
          levelThreeKYC({
            selfie: values.selfie,
            id_type: values.id_type,
            id_number: values.id_number,
            id_image: values.id_image,

            dob: values.dob,
            firstname: userInfo.firstname,
            lastname: userInfo.lastname,
            lga: values.lga,
            state: values.state,
          })
        ).unwrap();

        console.log(response);
        cogoToast.success(response.message, {
          position: "top-right",
        });

        dispatch(getUserInfo()).then(() => {
          onComplete && onComplete();
        });
      } catch (error) {
        cogoToast.error(error.message, {
          position: "top-right",
        });
      }
    },
  });

  return (
    <form className="mt-10 " onSubmit={handleSubmit}>
      <div className="flex flex-col gap-4 gap-y-8 min-h-[300px]">
        {/* Upload Selfie */}
        <div className="">
          <label
            htmlFor="selfie"
            className="inline-block mb-2 font-Satoshi500 text-[#909090] text-xs"
          >
            Upload Selfie
          </label>
          <ImageUploader
            id={"selfie"}
            helperText="Please take or upload your current Passport photo"
            onChange={(value) => setFieldValue("selfie", value)}
          />
          {touched.selfie && errors.selfie ? (
            <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
              {touched.selfie && errors.selfie}
            </small>
          ) : null}
        </div>

        {/* ID Type */}
        <div className="">
          <label
            htmlFor="id_type"
            className="block mb-2 font-Satoshi500 text-[#909090] text-xs"
          >
            ID Type
          </label>
          <div className="relative">
            <select
              type="text"
              name="id_type"
              placeholder="Select Coin"
              className={
                touched.id_type && errors.id_type
                  ? "appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4"
                  : "appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4"
              }
              value={values.coin}
              onChange={handleChange}
            >
              <option value="" selected>
                Select
              </option>
              {availableID.map((idCard) => (
                <option key={idCard.key} value={idCard.key}>
                  {idCard.value}
                </option>
              ))}
            </select>
            <img
              className="absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
              src={chevron_grey}
              alt="arrow"
            />
          </div>
          {touched.id_type && errors.id_type ? (
            <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
              {touched.id_type && errors.id_type}
            </small>
          ) : null}
        </div>

        {/* ID Number Input */}
        <Input
          type="text"
          id="id_number"
          name="id_number"
          placeholder="Enter ID number"
          label="ID Number"
          value={values.id_number}
          onChange={handleChange}
        />

        {/* ID Image */}
        <div className="">
          <label
            htmlFor="id_image"
            className="inline-block mb-2 font-Satoshi500 text-[#909090] text-xs"
          >
            Upload ID Image (Front)
          </label>
          <ImageUploader
            id={"id_image"}
            helperText="Upload a copy of your International Passport, Driver’s license, NIN Card or Voter’s Card."
            onChange={(value) => setFieldValue("id_image", value)}
          />
          {touched.id_image && errors.id_image ? (
            <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
              {touched.id_image && errors.id_image}
            </small>
          ) : null}
        </div>

        {/* Conditional fields based on id_type */}
        {["driver", "voter", "passport"].includes(values.id_type) && (
          <>
            {values.id_type !== "passport" && (
              <>
                {/* Date of Birth */}
                <Input
                  label="Date of Birth"
                  type="date"
                  id="dob"
                  name="dob"
                  placeholder="Enter Date of Birth"
                  value={values.dob}
                  onChange={handleChange}
                />

                {/* First Name */}
                <Input
                  type="hidden"
                  id="firstname"
                  name="firstname"
                  placeholder="Enter First Name"
                  label={"First Name"}
                  value={values.firstname}
                  onChange={handleChange}
                />
              </>
            )}

            {/* Last Name */}
            <Input
              type="hidden"
              id="lastname"
              name="lastname"
              placeholder="Enter Last Name"
              label={"Last Name"}
              value={values.lastname}
              onChange={handleChange}
            />
          </>
        )}

        {/* Additional fields for Voter's Card */}
        {values.id_type === "voter" ? (
          <>
            {/* Local Government Area */}
            <Input
              type="text"
              id="lga"
              name="lga"
              placeholder="Enter Local Government Area e.g. Ikeja"
              label={"Local Government Area"}
              value={values.lga}
              onChange={handleChange}
            />

            {/* State */}
            <Input
              type="text"
              id="state"
              name="state"
              placeholder="Enter State e.g. Lagos"
              label={"State"}
              value={values.state}
              onChange={handleChange}
            />
          </>
        ) : null}
      </div>

      <div className="mt-8">
        <Button
          type="submit"
          disabled={!isValid || isLoading}
          loading={isLoading}
        >
          Upgrade Account
        </Button>
      </div>
    </form>
  );
}

export default LevelThree;
