import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUrl } from "../config";
import cogoToast from "cogo-toast";
import "../../components/Helpers/firebase";
import "firebase/compat/analytics";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

export const fetchAvailableCoins = createAsyncThunk(
  "crypto/coins",
  async (arg, { rejectWithValue, dispatch, getState }) => {
    try {
      const res = await axios.get(`${apiUrl}transactions/crypto/coins`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });

      return res.data.data;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const sellCrypto = createAsyncThunk(
  "crypto/sell",
  async ({ currency, amount, coin }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${apiUrl}transactions/crypto`,
        {
          currency,
          amount,
          coin,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );

      return res.data.data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 400 || error.response.status === 422) {
        cogoToast.error(
          "Oops! Looks like there was an error with this trade, please try again!"
        );
        return rejectWithValue(error.response);
      }
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);
