import React,{useState} from 'react'
import logo_icon from '../../assets/icons/logo_icon.svg'
import PinInput from "react-pin-input";


const SetPinPage = () => {

    const [valid] = useState(false)
    const [pin, setPin] = useState("");

    const handlePin = (val) => {
        setPin(val);
    };

    return (  
        <>
            <div className='flex justify-center pt-24'>
                <img src={logo_icon} alt="logo" />
            </div>

            <div className='max-w-lg  py-10 mx-auto'>
                <div className='md:bg-[#F8F8F8] rounded-[16px] py-10 px-10 md:px-16'>
                    <div className='flex justify-center'>
                        <h4 className='text-[#212121] font-Satoshi500 text-lg'>Set Transaction PIN</h4>
                    </div>
                    <div className='text-center mt-8'>
                       <p className='text-[#909090] font-Satoshi400 text-sm'>
                        Create a 4-digit PIN.
                        <br/>
                        This will be used for all transactions
                        </p> 
                    </div>

                    <div className='text-center mt-8'>
                         <PinInput
                            length={4}
                            initialValue={pin}
                            focus
                            secret
                            type="numeric"
                            onChange={handlePin}
                            inputStyle={{ borderColor: "#CCCCCC", color: '#212121' }}
                            inputFocusStyle={{ borderColor: "#235643" }}
                        />
                         <small className="text-[#FF002A] font-[300] text-[10px] pt-[4px]" >
                                 {valid ? "Incorrect Pin!": ""}
                          </small>
                      </div>

                    <div className='mt-12'>
                        <button
                            type="submit"
                            className="bg-[#235643] 
                            disabled:bg-[#235643] 
                            disabled:opacity-[0.7]
                            text-sm
                            rounded-md w-full py-4 text-[#fff]  hover:bg-[#235643] hover:opacity-[0.9] transition-all"
                        >
                            Continue
                        </button>
                    </div>

                </div>

            </div>
        </>
    );
}
 
export default SetPinPage;