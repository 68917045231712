import { createSlice } from "@reduxjs/toolkit";
import {
  getATransaction,
  getBillTransactions,
  getCardTransactions,
  getCryptoTransactions,
  getRecentTransactions,
  getWalletTransactions,
} from "./transactionsActions";

const initialState = {
  value: 0,
  loading: false,
  recentTransactions: [],
  transactions: [],
  total: 0,
  transaction: {},
};

export const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
  },
  extraReducers: {
    //   get recent transactions
    [getRecentTransactions.pending]: (state) => {
      state.loading = true;
    },
    [getRecentTransactions.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.recentTransactions = payload.data;
      state.total = payload.meta.total;
    },
    [getRecentTransactions.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    //   get card transactions
    [getCardTransactions.pending]: (state) => {
      state.loading = true;
    },
    [getCardTransactions.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.transactions = payload.data;
      state.total = payload.meta.total;
    },
    [getCardTransactions.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    //   get wallet transactions
    [getWalletTransactions.pending]: (state) => {
      state.loading = true;
    },
    [getWalletTransactions.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.transactions = payload.data;
      state.total = payload.meta.total;
    },
    [getWalletTransactions.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    //   get bill transactions
    [getBillTransactions.pending]: (state) => {
      state.loading = true;
    },
    [getBillTransactions.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.transactions = payload.data;
      state.total = payload.meta.total;
    },
    [getBillTransactions.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    //   get a transaction
    [getATransaction.pending]: (state) => {},
    [getATransaction.fulfilled]: (state, { payload }) => {
      state.transaction = payload.data;
    },
    [getATransaction.rejected]: (state, { payload }) => {},

    //   get crypto transactions
    [getCryptoTransactions.pending]: (state) => {
      state.loading = true;
    },
    [getCryptoTransactions.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.transactions = payload.data;
      state.total = payload.meta.total;
    },
    [getCryptoTransactions.rejected]: (state, { payload }) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { increment } = transactionsSlice.actions;

export default transactionsSlice.reducer;
