import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUrl } from "../config";
import cogoToast from "cogo-toast";
import { Mixpanel } from "../../components/Helpers/MixPanel";
import "../../components/Helpers/firebase";
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import moment from "moment";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

export const userLogin = createAsyncThunk(
  "login",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${apiUrl}auth/login`, {
        email: email,
        password,
        device_name: "Web Platform",
      });
      if (res.status === 200) {
        let isVerified = res.data.data.user.email_verified;
        // If email verification is false, user needs to verify their email address...
        if (!isVerified) {
          return res.data;
        } else {
          cogoToast.success("Login Successful!", {
            position: "bottom-right",
          });
          Mixpanel.identify(res.data.data.user.id);
          Mixpanel.track("Login");
          Mixpanel.people.set({
            $name:
              res.data.data.user.firstname + " " + res.data.data.user.lastname,
            $email: res.data.data.user.email,
          });
          // firebase analytics
          firebase.analytics().logEvent("login", {
            user_id: res.data.data.user.id,
            login_time: moment().format("h:mm:ss a"),
            login_date: moment().format("MMMM Do YYYY"),
          });
          return res.data;
        }
      }
    } catch (error) {
      if (error.response.status === 401) {
        cogoToast.error("Invalid Credentials!");
        return rejectWithValue(error.response);
      } else {
        cogoToast.error("Invalid Credentials!");
        return rejectWithValue(error.response);
      }
    }
  }
);

export const userRegister = createAsyncThunk(
  "register",
  async (
    { email, password, username, phonenumber, nationality, referrer },
    { rejectWithValue, dispatch }
  ) => {
    dispatch({ type: "auth/UserEmail", email });
    try {
      const res = await axios.post(`${apiUrl}auth/register`, {
        email: email,
        password,
        username,
        phonenumber,
        nationality,
        referrer,
        terms: true,
      });
      if (res.status === 201 || res.status === 204) {
        cogoToast.success("Registration Successful!, Kindly login to proceed");
        Mixpanel.track("Signup");
        firebase.analytics().logEvent("registered", {
          username: username,
          phone_number: phonenumber,
          email_address: email,
          country: nationality,
          reg_time: moment().format("h:mm:ss a"),
          reg_date: moment().format("MMMM Do YYYY"),
        });
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data) {
        cogoToast.error(
          "Oops! Looks like a user already exist with this credentials."
        );
        return rejectWithValue(error.response.data.error);
      } else {
        cogoToast.error(
          "Oops! Looks like a user already exist with this credentials."
        );
        return rejectWithValue(error.response.data.error);
      }
    }
  }
);

export const verifyEmail = createAsyncThunk(
  "verifyEmail",
  async ({ url }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${url}`);
      console.log(res);
      if (res.status === 200 || res.status === 204) {
        return res.data;
      }
    } catch (error) {
      console.log(error);
      // return custom error message from API if any
      if (error.response.status === 400 || error.response.status === 403) {
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

export const resendVerifyEmail = createAsyncThunk(
  "resendVerifyEmail",
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(`${apiUrl}users/email-verification`, "", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200 || res.status === 202) {
        cogoToast.success(
          "A verification email has been sent to you, Check to verify your Cardvest Account.",
          {
            position: "top-center",
          }
        );
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 400 || error.response.status === 422) {
        cogoToast.error("Kindly check that the credentials entered is valid!");
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

export const userForgot = createAsyncThunk(
  "forgot",
  async ({ email }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(`${apiUrl}auth/forgot-password`, {
        email: email,
      });
      if (res.status === 200) {
        cogoToast.success("Check your email for password reset instructions!", {
          position: "top-center",
        });
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 400 || error.response.status === 422) {
        cogoToast.error("Kindly check that the credentials entered is valid!");
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

export const userResetPassword = createAsyncThunk(
  "userResetPassword",
  async (
    { email, token, password, password_confirmation },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const res = await axios.post(`${apiUrl}auth/reset-password`, {
        email,
        token,
        password,
        password_confirmation,
      });
      if (res.status === 200) {
        cogoToast.success(
          "Your password has been changed successfully, Kindly login again!",
          {
            position: "top-center",
          }
        );
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 400 || error.response.status === 422) {
        cogoToast.error(
          "Oops, looks like the link has either expired or is invalid, please request again."
        );
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  "updateUserProfile",
  async (
    { username, phonenumber, firstname, lastname },
    { rejectWithValue, getState, dispatch }
  ) => {
    const id = getState().auth.userInfo.id;

    try {
      const res = await axios.put(
        `${apiUrl}users/${id}`,
        {
          username,
          phonenumber,
          firstname,
          lastname,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        cogoToast.success("Profile details updated successfully!", {
          position: "top-right",
        });
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

export const updateUserPassword = createAsyncThunk(
  "updateUserPassword",
  async (
    { current_password, password, password_confirmation },
    { rejectWithValue, getState, dispatch }
  ) => {
    const id = getState().auth.userInfo.id;

    try {
      const res = await axios.put(
        `${apiUrl}users/${id}/password`,
        {
          current_password,
          password,
          password_confirmation,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        localStorage.setItem("token", "");
        dispatch({ type: "auth/Logout" });
        cogoToast.success("Password successfully changed, Login to continue!", {
          position: "top-right",
        });
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 400) {
        cogoToast.error("Check that your old password is correct!");
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

export const updateProfilePicture = createAsyncThunk(
  "updateProfilePicture",
  async ({ file }, { rejectWithValue, dispatch }) => {
    const formData = new FormData();
    formData.append("image", file);
    try {
      const res = await axios({
        method: "post",
        url: `${apiUrl}users/upload`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        cogoToast.success("Profile image updated successfully!", {
          position: "top-right",
        });
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 400 || error.response.status === 422) {
        cogoToast.error("Ooops!,an error occured while uploading image!");
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

export const setTransactionPin = createAsyncThunk(
  "setTransactionPin",
  async ({ pin }, { rejectWithValue, getState, dispatch }) => {
    const userId = getState().auth.userInfo.id;
    try {
      const res = await axios.post(
        `${apiUrl}security/pin/setup`,
        {
          pin,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200 || res.status === 204) {
        firebase.analytics().logEvent("crated_pin", { user_id: userId });
        cogoToast.success("Transaction pin created successfully!", {
          position: "top-right",
        });
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

export const confirmTransactionPin = createAsyncThunk(
  "confirmTransactionPin",
  async ({ pin }, { rejectWithValue, getState, dispatch }) => {
    try {
      const res = await axios.post(
        `${apiUrl}security/pin/confirm`,
        {
          pin,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 400 || error.response.status === 422) {
        cogoToast.error("Invalid transaction PIN!");
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

export const changeTransactionPin = createAsyncThunk(
  "changeTransactionPin",
  async ({ current_pin, pin }, { rejectWithValue, getState, dispatch }) => {
    try {
      const res = await axios.post(
        `${apiUrl}security/pin/update`,
        {
          current_pin,
          pin,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        cogoToast.success("Transaction pin successfully changed!", {
          position: "bottom-right",
        });
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 400 || error.response.status === 422) {
        cogoToast.error("Check that your old pin is correct!");
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

export const resetPinWithPassword = createAsyncThunk(
  "resetPinWithPassword",
  async ({ password }, { rejectWithValue, getState, dispatch }) => {
    try {
      const res = await axios.post(
        `${apiUrl}security/pin/reset`,
        {
          password,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200 || res.status === 204) {
        cogoToast.success("A PIN reset code has been sent to your email", {
          position: "top-right",
        });
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 400 || error.response.status === 422) {
        cogoToast.error("Check that your password is correct!");
        return rejectWithValue(error.response);
      }
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

export const resetTransactionPin = createAsyncThunk(
  "resetTransactionPin",
  async ({ token, pin }, { rejectWithValue, getState, dispatch }) => {
    try {
      const res = await axios.post(
        `${apiUrl}security/pin/token-reset`,
        {
          token,
          pin,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200 || res.status === 204) {
        cogoToast.success("Your pin has been changed successfully", {
          position: "top-right",
        });
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 400 || error.response.status === 422) {
        cogoToast.error("Please check that the token entered is valid!");
      }
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

export const getReferralsCode = createAsyncThunk(
  "getReferralsCode",
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}referrals/code`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

export const getWalletBalance = createAsyncThunk(
  "getWalletBalance",
  async (arg, { rejectWithValue, dispatch, getState }) => {
    const currency = getState().auth.currency;
    try {
      const res = await axios.get(`${apiUrl}wallets/${currency}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

export const getBanks = createAsyncThunk(
  "getBanks",
  async (arg, { rejectWithValue, dispatch, getState }) => {
    const currency = getState().auth.currency;
    try {
      const res = await axios.get(
        `${apiUrl}bank-accounts/banks?currency=${currency}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

export const verifyBankAccount = createAsyncThunk(
  "verifyBankAccount",
  async ({ bankname, banknumber }, { rejectWithValue, getState, dispatch }) => {
    const currency = getState().auth.currency;
    try {
      const res = await axios.post(
        `${apiUrl}bank-accounts/verify?currency=${currency}`,
        {
          bankname,
          banknumber,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

export const addAccountDetails = createAsyncThunk(
  "addAccountDetails",
  async (
    { code, bankname, banknumber, accountname },
    { rejectWithValue, getState, dispatch }
  ) => {
    const currency = getState().auth.currency;
    const userId = getState().auth.userInfo.id;
    try {
      const res = await axios.post(
        `${apiUrl}bank-accounts?currency=${currency}`,
        {
          code,
          bankname,
          banknumber,
          accountname,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        Mixpanel.identify(userId);
        Mixpanel.track("Add Bank Account", {
          "Bank Name": bankname,
          "Account Number": banknumber,
          "Account Name": accountname,
          Currency: currency,
        });
        firebase.analytics().logEvent("add_bank", {
          user_id: userId,
          bank_name: bankname,
          account_name: accountname,
          account_number: banknumber,
        });
        cogoToast.success("Account details added successfully!", {
          position: "top-right",
        });
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

export const getAccountDetails = createAsyncThunk(
  "getAccountDetails",
  async (arg, { rejectWithValue, getState, dispatch }) => {
    const currency = getState().auth.currency;
    try {
      const res = await axios.get(
        `${apiUrl}bank-accounts?currency=${currency}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

export const deleteAccountDetails = createAsyncThunk(
  "deleteAccountDetails",
  async ({ id }, { rejectWithValue, getState, dispatch }) => {
    try {
      const res = await axios.delete(`${apiUrl}bank-accounts/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        cogoToast.success("Account details deleted successfully!", {
          position: "top-right",
        });
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

export const sendReferralInvite = createAsyncThunk(
  "sendReferralInvite",
  async ({ email }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${apiUrl}referrals/invite`,
        {
          email,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200 || res.status === 204) {
        cogoToast.success("Invite sent successfully!", {
          position: "top-right",
        });
        return res.data;
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

export const verifyKYC = createAsyncThunk(
  "verifyKYC",
  async (
    { bvn, lastname, firstname },
    { rejectWithValue, getState, dispatch }
  ) => {
    const currency = getState().auth.currency;
    try {
      const res = await axios.post(
        `${apiUrl}users/bank-verification`,
        {
          bvn: bvn,
          lastname: lastname,
          firstname: firstname,
          currency: currency,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        cogoToast.success("BVN Verification successful!", {
          position: "top-right",
        });
        return res.data;
      }
    } catch (error) {
      let msg = error.response.data.message;
      if (error.response.status === 400 || error.response.status === 422) {
        cogoToast.error(`${msg}`, {
          position: "top-right",
        });
        return rejectWithValue(error.response);
      }
      // return custom error message from API if any
      else if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

export const createVBAAccount = createAsyncThunk(
  "createVBAAccount",
  async (arg, { rejectWithValue, getState, dispatch }) => {
    const currency = getState().auth.currency;
    try {
      const res = await axios.post(
        `${apiUrl}transactions/deposit/create-vba?currency=${currency}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        cogoToast.success(
          "Your request for virtual account is now been processed!",
          {
            position: "top-right",
          }
        );
        return res.data;
      }
    } catch (error) {
      if (error.response.status === 400 || error.response.status === 422) {
        cogoToast.error(
          "Ooops!, an error occured generating your virtual account!",
          {
            position: "bottom-right",
          }
        );
        return rejectWithValue(error.response);
      }
      // return custom error message from API if any
      else if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

export const getVBADetails = createAsyncThunk(
  "getVBADetails",
  async (arg, { rejectWithValue, getState, dispatch }) => {
    const currency = getState().auth.currency;
    try {
      const res = await axios.get(
        `${apiUrl}transactions/deposit/vba?currency=${currency}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);
