import React from 'react'
import { Link } from 'react-router-dom';
import img1 from '../../assets/images/404.svg'

const NotFoundPage = () => {
    return ( 
        <>
            <div className='relative'>
                <div className='absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2'>
                    <h4 className='text-[#F5F5F5] font-Satoshi900 md:text-[400px] text-9xl'>404</h4>
                </div>
                <div className='flex justify-center mt-20 md:mt-0'>
                    <img src={img1} className="z-[1]" alt="404" />
                </div>
                <div className='text-center md:-mt-20'>
                    <h4 className='text-[#212121] font-Satoshi900 md:text-5xl text-xl'>ERROR 404</h4>
                    <p className='text-[#212121] font-Satoshi400 text-base max-w-[260px] mx-auto md:max-w-full'>Whatever you’re looking for doesn’t seem to exist.</p>
                </div>
                <div className='flex justify-center mt-4'>
                 <Link to="/"
                    className="bg-[#235643] 
                    disabled:bg-[#235643] 
                    disabled:opacity-[0.7]
                    text-sm px-10
                    rounded-[4px] py-3 text-[#fff] 
                    font-Satoshi400 hover:bg-[#235643] hover:opacity-[0.9] transition-all"
                >
                    Go Back
                </Link>
                </div>
            </div>
        </>
     );
}
 
export default NotFoundPage;