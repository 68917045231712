import React, { useEffect, useState, useRef } from "react";
import profile_active from "../../assets/icons/settings_profileicon.svg";
import profile from "../../assets/icons/settings_profile.svg";
import security from "../../assets/icons/settings_lock-circle.svg";
import security_active from "../../assets/icons/settings_lock-circleActive.svg";
import account_active from "../../assets/icons/bank_large.svg";
import account from "../../assets/icons/bank_LargeA.svg";
import referrals from "../../assets/icons/settings_speaker.svg";
import avatar from "../../assets/icons/avatar.svg";
import edit from "../../assets/icons/edit-2.svg";
import copy_icon from "../../assets/icons/document-copy-white.svg";
import referral_icon from "../../assets/icons/referral_icon.svg";
import sms_tracking from "../../assets/icons/sms-tracking.svg";
import Modal from "../../components/Modals/modal";
import eye_off from "../../assets/icons/Hide.svg";
import eye from "../../assets/icons/eye.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  addAccountDetails,
  changeTransactionPin,
  deleteAccountDetails,
  getAccountDetails,
  getBanks,
  getReferralsCode,
  resetPinWithPassword,
  resetTransactionPin,
  sendReferralInvite,
  updateProfilePicture,
  updateUserPassword,
  updateUserProfile,
  verifyBankAccount,
} from "../../redux/Auth/authActions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import cogoToast from "cogo-toast";
import { Form, Formik } from "formik";
import {
  profileValidator,
  ChangePasswordValidator,
  ChangePinValidator,
  ResetPinPasswordValidator,
  bankAccountValidatorNG,
  bankAccountValidatorGH,
  sendReferralValidator,
} from "../../validationSchema/validator";
import { useNavigate } from "react-router-dom";
import nobank from "../../assets/icons/nobank.svg";
import bank_icon from "../../assets/icons/bank.svg";
import trash_outline from "../../assets/icons/trash-outline.svg";
import chevron_grey from "../../assets/icons/chevron_grey.svg";
import {
  clearAccountName,
  clearInviteSent,
  clearIsFirstReset,
  clearIsSecondReset,
} from "../../redux/Auth/auth";
import PinInput from "react-pin-input";
import { getUserInfo } from "../../redux/Auth/kycActions";
import KYCStatus from "../../components/KYC/kysstatus";

const SettingsPage = () => {
  const [tabValue, setTabValue] = useState("Profile");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const [showPINModal, setShowPINModal] = useState(false);
  const PINModal = () => {
    setShowPINModal(!showPINModal);
  };

  const [showResetPINModal, setShowResetPINModal] = useState(false);
  const ResetPINModal = () => {
    setShowResetPINModal(!showResetPINModal);
  };

  const [pinValid, setPinValid] = useState(false);
  const [token, setToken] = useState("");
  const [newpin, setNewPin] = useState("");
  const [confirmpin, setConfirmPin] = useState("");

  const handleToken = (val) => {
    setToken(val);
  };

  const handleNewPin = (val) => {
    setNewPin(val);
  };

  const handleConfirmPin = (val) => {
    setPinValid(false);
    setConfirmPin(val);
  };

  const [showAccountModal, setShowAccountModal] = useState(false);
  const AccountModal = () => {
    setShowAccountModal(!showAccountModal);
  };

  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const DeleteAccountModal = () => {
    setShowDeleteAccountModal(!showDeleteAccountModal);
  };

  const {
    referralCode,
    userInfo,
    updateLoader,
    imageLoader,
    accountDetails,
    loader,
    banks,
    accountName,
    addLoader,
    isFirstReset,
    isSecondReset,
    currency,
    inviteSent,
  } = useSelector((state) => state.auth);

  const handleCopy = () => {
    cogoToast.success("Copied to clipboard!");
  };

  const fileInputRef = useRef();

  const ref = useRef();

  const changedHandler = () => {
    let files = fileInputRef.current.files[0];
    console.log(files);
    dispatch(
      updateProfilePicture({
        file: files,
      })
    );
  };

  const handleProfileUpdate = (values) => {
    dispatch(
      updateUserProfile({
        username: values.username,
        phonenumber: values.phonenumber,
        lastname: values.lastname,
        firstname: values.firstname,
      })
    );
  };

  const handlePasswordUpdate = (values) => {
    dispatch(updateUserPassword(values));
    setTimeout(() => {
      navigate("/");
    }, 2000);
  };

  const handlePinUpdate = (values) => {
    dispatch(
      changeTransactionPin({
        current_pin: values.current_pin,
        pin: values.pin,
      })
    );
  };

  const handleResetPinPassword = (values) => {
    console.log(values);
    dispatch(
      resetPinWithPassword({
        password: values.password,
      })
    );
  };

  useEffect(() => {
    if (isFirstReset) {
      setShowPINModal(false);
      setTimeout(() => {
        setShowResetPINModal(true);
        dispatch(clearIsFirstReset());
      }, 1000);
    }
  }, [isFirstReset]);

  const resetTransactPin = () => {
    if (token !== "" && newpin !== "" && confirmpin !== "") {
      if (newpin !== confirmpin) {
        setPinValid(true);
      } else {
        dispatch(
          resetTransactionPin({
            token: token,
            pin: newpin,
          })
        );
      }
    } else {
      cogoToast.info("Please fill in all required fields");
    }
  };

  useEffect(() => {
    if (isSecondReset) {
      setTimeout(() => {
        setShowResetPINModal(false);
        dispatch(clearIsSecondReset());
      }, 1000);
    }
  }, [isSecondReset]);

  const handleVerifyAcct = (banknumber, code) => {
    let re = /^-?[0-9]+(.[0-9]{1-7})?$/;
    if (re.test(banknumber)) {
      if (currency === "NGN") {
        if (banknumber.length === 10 && code !== "") {
          dispatch(
            verifyBankAccount({
              bankname: code,
              banknumber: banknumber,
            })
          );
        }
      }
    }
  };

  const handleBank = (code, banknumber) => {
    if (currency === "NGN") {
      dispatch(clearAccountName());
      let re = /^-?[0-9]+(.[0-9]{1-7})?$/;
      if (re.test(banknumber)) {
        if (banknumber.length === 10) {
          dispatch(
            verifyBankAccount({
              bankname: code,
              banknumber: banknumber,
            })
          );
        }
      }
    }
  };

  const [GHAccountName, setGHAccountName] = useState("");
  const handleAccountName = (val) => {
    setGHAccountName(val);
  };

  const addAccountInfo = (values) => {
    let bankName = banks.find((val) => val.code === values.bank).name;
    dispatch(
      addAccountDetails({
        code: values.bank,
        bankname: bankName,
        banknumber: values.accountNumber,
        accountname: currency === "NGN" ? accountName : GHAccountName,
      })
    );
    setTimeout(() => {
      dispatch(getAccountDetails());
      setShowAccountModal(false);
    }, 1000);
  };

  const [deleteId, setDeleteId] = useState(null);
  const handleDeleteAccount = (id) => {
    setDeleteId(id);
    setShowDeleteAccountModal(true);
  };

  const deleteAccountDetail = () => {
    dispatch(
      deleteAccountDetails({
        id: deleteId,
      })
    );
    setTimeout(() => {
      dispatch(getAccountDetails());
      setShowDeleteAccountModal(false);
    }, 1000);
  };

  const handleSendReferral = (values) => {
    console.log(values);
    dispatch(
      sendReferralInvite({
        email: values.email,
      })
    );
  };

  useEffect(() => {
    if (inviteSent) {
      dispatch(clearInviteSent());
      // reset the form
      setTimeout(() => {
        ref.current.reset();
      }, 800);
    }
  }, [inviteSent]);

  useEffect(() => {
    dispatch(getReferralsCode());
    dispatch(getBanks());
    dispatch(getAccountDetails());
  }, [dispatch]);

  // refresh the userInfo data
  useEffect(() => {
    dispatch(getUserInfo()).unwrap();
  }, []);

  return (
    <>
      {/* Reset PIN - Password */}
      <Modal show={showPINModal} showModal={PINModal} title="">
        <div className="mt-10">
          <p className="text-[#212121] font-Satoshi500 text-[15px] text-center">
            Confirm Password
          </p>
          <p className="text-[#909090] text-[13px] mt-1 font-Satoshi400 text-center">
            Enter your account password to continue
          </p>

          <Formik
            onSubmit={(values, { setSubmitting }) =>
              handleResetPinPassword(values, setSubmitting)
            }
            validationSchema={ResetPinPasswordValidator}
            initialValues={{
              password: "",
            }}
          >
            {({
              handleChange,
              isSubmitting,
              handleSubmit,
              handleBlur,
              values,
              touched,
              errors,
            }) => (
              <Form className="mt-10 md:mt-16" onSubmit={handleSubmit}>
                <div className="mt-6">
                  <div className="relative">
                    <input
                      type={passwordShown ? "text" : "password"}
                      name="password"
                      placeholder="Enter your password"
                      className={
                        touched.password && errors.password
                          ? "appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4"
                          : "appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4"
                      }
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <img
                      onClick={togglePasswordVisiblity}
                      src={passwordShown ? eye_off : eye}
                      alt="eye"
                      className="absolute w-[18px] h-[18px] top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
                    />
                  </div>
                  {touched.password && errors.password ? (
                    <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                      {touched.password && errors.password}
                    </small>
                  ) : null}
                </div>

                <div className="mt-10">
                  <button
                    type="submit"
                    disabled={updateLoader}
                    className="bg-[#235643] 
                            disabled:bg-[#235643] 
                            disabled:opacity-[0.7]
                            text-sm
                            rounded-md w-full py-4 text-[#fff] font-Satoshi400 hover:bg-[#235643] hover:opacity-[0.9] transition-all"
                  >
                    Reset PIN
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>

      {/* Reset Pin with Token */}
      <Modal
        show={showResetPINModal}
        showModal={ResetPINModal}
        title="Set New PIN"
      >
        <div className="mt-10">
          <p className="text-[#909090] text-[13px] mt-1 font-Satoshi400 text-center">
            A 4-digit reset token has been sent to{" "}
            <span className="text-[#235643]">
              {userInfo && userInfo.email ? userInfo.email : "user@gmail.com"}
            </span>{" "}
            <br />
            Kindly input the token below
          </p>

          <div className="mt-6 text-center">
            <label className="block mb-2 text-[#909090] text-xs">Token</label>
            <div className="">
              <PinInput
                length={4}
                initialValue={token}
                focus
                type="numeric"
                onChange={handleToken}
                inputStyle={{ borderColor: "#CCCCCC", color: "#212121" }}
                inputFocusStyle={{ borderColor: "#235643" }}
              />
            </div>
          </div>

          <div className="mt-6 text-center">
            <label className="block mb-2 text-[#909090] text-xs">
              New PIN.
            </label>
            <div className="">
              <PinInput
                length={4}
                initialValue={newpin}
                secret
                type="numeric"
                onChange={handleNewPin}
                inputStyle={{ borderColor: "#CCCCCC", color: "#212121" }}
                inputFocusStyle={{ borderColor: "#235643" }}
              />
            </div>
          </div>

          <div className="mt-6 text-center">
            <label className="block mb-2 text-[#909090] text-xs">
              Confirm New PIN
            </label>
            <div className="">
              <PinInput
                length={4}
                initialValue={confirmpin}
                secret
                type="numeric"
                onChange={handleConfirmPin}
                inputStyle={{ borderColor: "#CCCCCC", color: "#212121" }}
                inputFocusStyle={{ borderColor: "#235643" }}
              />
              <small className="text-[#FF002A] font-[300] text-[10px] pt-[4px]">
                {pinValid ? "Pin must match!" : ""}
              </small>
            </div>
          </div>

          <div className="mt-10">
            <button
              type="submit"
              onClick={resetTransactPin}
              disabled={updateLoader}
              className="bg-[#235643] 
                            disabled:bg-[#235643] 
                            disabled:opacity-[0.7]
                            text-sm
                            rounded-md w-full py-4 text-[#fff] font-Satoshi400 hover:bg-[#235643] hover:opacity-[0.9] transition-all"
            >
              Set New PIN
            </button>
          </div>
        </div>
      </Modal>

      {/* add account modal */}
      <Modal
        show={showAccountModal}
        showModal={AccountModal}
        title="Add Account"
      >
        <div>
          <Formik
            onSubmit={(values, { setSubmitting }) =>
              addAccountInfo(values, setSubmitting)
            }
            validationSchema={
              currency === "NGN"
                ? bankAccountValidatorNG
                : bankAccountValidatorGH
            }
            initialValues={{
              accountNumber: "",
              bank: "",
            }}
          >
            {({
              handleChange,
              isSubmitting,
              handleSubmit,
              handleBlur,
              values,
              touched,
              errors,
            }) => (
              <Form className="mt-10" onSubmit={handleSubmit}>
                <div className="mt-6">
                  <label
                    htmlFor="accountNumber"
                    className="block mb-2 text-[#909090] text-xs"
                  >
                    Account Number
                  </label>
                  <input
                    type="text"
                    name="accountNumber"
                    placeholder="Enter an account number"
                    className={
                      touched.accountNumber && errors.accountNumber
                        ? "appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4"
                        : "appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4"
                    }
                    value={values.accountNumber}
                    onChange={(e) => {
                      handleChange(e);
                      handleVerifyAcct(e.currentTarget.value, values.bank);
                    }}
                    onBlur={handleBlur}
                  />
                  {touched.accountNumber && errors.accountNumber ? (
                    <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                      {touched.accountNumber && errors.accountNumber}
                    </small>
                  ) : null}
                </div>

                <div className="mt-6">
                  <label
                    htmlFor="bank"
                    className="block mb-2 text-[#909090] text-xs"
                  >
                    Select Bank
                  </label>
                  <div className="relative">
                    <select
                      type="text"
                      name="bank"
                      placeholder="Select a bank"
                      className={
                        touched.bank && errors.bank
                          ? "appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4"
                          : "appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4"
                      }
                      value={values.bank}
                      onChange={(e) => {
                        handleChange(e);
                        handleBank(e.currentTarget.value, values.accountNumber);
                      }}
                      onBlur={handleBlur}
                    >
                      <option value="" selected={values.bank === ""} disabled>
                        Select a bank
                      </option>
                      {banks.map((opt, index) => {
                        return (
                          <option key={index} value={opt.code}>
                            {opt.name}
                          </option>
                        );
                      })}
                    </select>
                    <img
                      className="absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
                      src={chevron_grey}
                      alt="arrow"
                    />
                  </div>

                  {touched.bank && errors.bank ? (
                    <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                      {touched.bank && errors.bank}
                    </small>
                  ) : null}
                </div>

                <div className="mt-6">
                  <label
                    htmlFor="accountname"
                    className="block mb-2 text-[#909090] text-xs"
                  >
                    Account Name
                  </label>
                  <input
                    type="text"
                    name="accountname"
                    placeholder="Account Name"
                    disabled={currency === "NGN" ? true : false}
                    className="appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4"
                    value={currency === "NGN" ? accountName : GHAccountName}
                    onChange={(e) => handleAccountName(e.target.value)}
                    onBlur={handleBlur}
                  />
                </div>

                <div className="mt-10">
                  <button
                    type="submit"
                    disabled={addLoader}
                    className="bg-[#235643] 
                                    disabled:bg-[#235643] 
                                    disabled:opacity-[0.7]
                                    text-sm
                                    rounded-md w-full py-4 text-[#fff] font-Satoshi400 hover:bg-[#235643] hover:opacity-[0.9] transition-all"
                  >
                    Add Account
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>

      {/* delete account modal */}
      <Modal
        show={showDeleteAccountModal}
        showModal={DeleteAccountModal}
        title=""
      >
        <div className="mt-8 mb-6">
          <p className="text-[#000000] font-Satoshi400 text-[15px] text-center">
            Are you sure you want to remove this account? <br />
            This cannot be undone
          </p>

          <div className="flex justify-center mt-8">
            <div className="flex gap-3 items-center">
              <button
                onClick={() => setShowDeleteAccountModal(false)}
                type="submit"
                className="bg-transparent 
                            border border-[#235643]
                            text-sm px-10 py-3
                            rounded-[4px] 
                             text-[#235643] font-Satoshi400 hover:bg-[#235643] hover:text-white hover:opacity-[0.9] transition-all"
              >
                Cancel
              </button>
              <button
                onClick={deleteAccountDetail}
                type="submit"
                disabled={addLoader}
                className="bg-[#D00000] 
                            disabled:bg-[#D00000] 
                            disabled:opacity-[0.7]
                            text-sm
                            rounded-[4px] px-10 py-3 text-[#fff] font-Satoshi400 hover:bg-[#D00000] hover:opacity-[0.9] transition-all"
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <div>
        <h4 className="text-[#212121] font-Satoshi700 text-sm md:text-lg">
          Settings
        </h4>
        <p className="text-[#909090] font-Satoshi400 text-sm mt-1">
          Manage all settings and preferences here
        </p>

        <div className="mt-10">
          <ul className="flex justify-between flex-col md:flex-row gap-2 md:gap-0 max-w-4xl border-b border-[#F1F1F1]">
            <li
              onClick={() => setTabValue("Profile")}
              className={
                tabValue === "Profile"
                  ? "flex gap-2 items-center bg-[#F1F1F1] rounded-[4px] py-3 md:py-2 px-4 cursor-pointer  mb-1"
                  : "flex gap-2 items-center py-3 md:py-2 px-4 cursor-pointer mb-1"
              }
            >
              <img
                src={tabValue === "Profile" ? profile_active : profile}
                alt="profile"
              />
              <p
                className={
                  tabValue === "Profile"
                    ? "text-[#235643] text-sm font-Satoshi400"
                    : "text-[#909090] text-sm font-Satoshi400"
                }
              >
                Profile
              </p>
            </li>
            <li
              onClick={() => setTabValue("Security")}
              className={
                tabValue === "Security"
                  ? "flex gap-2 items-center bg-[#F1F1F1] rounded-[4px]  py-3 md:py-2 px-4 cursor-pointer  mb-1"
                  : "flex gap-2 items-center  py-3 md:py-2 px-4 cursor-pointer mb-1"
              }
            >
              <img
                src={tabValue === "Security" ? security_active : security}
                alt="security"
              />
              <p
                className={
                  tabValue === "Security"
                    ? "text-[#235643] text-sm font-Satoshi400"
                    : "text-[#909090] text-sm font-Satoshi400"
                }
              >
                Security
              </p>
            </li>
            <li
              onClick={() => setTabValue("KYC")}
              className={
                tabValue === "KYC"
                  ? "flex gap-2 items-center bg-[#F1F1F1] rounded-[4px] py-3 md:py-2 px-4 cursor-pointer  mb-1"
                  : "flex gap-2 items-center  py-3 md:py-2  px-4 cursor-pointer mb-1"
              }
            >
              <img
                src={tabValue === "KYC" ? account_active : account}
                alt="account"
              />
              <p
                className={
                  tabValue === "KYC"
                    ? "text-[#235643] text-sm font-Satoshi400"
                    : "text-[#909090] text-sm font-Satoshi400"
                }
              >
                Account Details
              </p>
            </li>
            <li
              onClick={() => setTabValue("Referrals")}
              className={
                tabValue === "Referrals"
                  ? "flex gap-2 items-center bg-[#F1F1F1] rounded-[4px] py-3 md:py-2 px-4 cursor-pointer  mb-1"
                  : "flex gap-2 items-center py-3 md:py-2  px-4 cursor-pointer mb-1"
              }
            >
              <img
                src={tabValue === "Referrals" ? referrals : referrals}
                alt="referrals"
              />
              <p
                className={
                  tabValue === "Referrals"
                    ? "text-[#235643] text-sm font-Satoshi400"
                    : "text-[#909090] text-sm font-Satoshi400"
                }
              >
                Referrals
              </p>
            </li>
          </ul>

          {/* profile section */}
          {tabValue === "Profile" && (
            <div className="mt-10">
              <h6 className="text-[#212121] font-Satoshi500 text-base">
                Profile Settings
              </h6>
              <div className="mt-4 flex gap-4 items-center">
                {!imageLoader && (
                  <div className="relative">
                    {userInfo && userInfo.image_url !== "" ? (
                      <img
                        src={userInfo.image_url}
                        className="w-[104px] h-[104px] rounded-full"
                      />
                    ) : (
                      <img
                        src={avatar}
                        alt="avatar"
                        className="w-[104px] h-[104px]"
                      />
                    )}
                    <div
                      className="bg-[#235643] w-[26px] h-[26px] flex items-center 
                                absolute right-1 top-1 cursor-pointer
                                justify-center rounded-full"
                    >
                      <img src={edit} alt="edit" />
                    </div>
                  </div>
                )}

                {imageLoader && (
                  <div className="animate-pulse  mt-4">
                    <div className="w-[104px] h-[104px] rounded-full bg-gray-200"></div>
                  </div>
                )}

                <div className="cursor-pointer">
                  <label className="text-[#C30000] cursor-pointer font-Satoshi400 text-sm underline">
                    <input
                      ref={fileInputRef}
                      type="file"
                      accept="image/*"
                      onChange={() => changedHandler()}
                      className="hidden"
                    />
                    Change Picture
                  </label>
                </div>
              </div>

              <Formik
                onSubmit={(values, { setSubmitting }) =>
                  handleProfileUpdate(values, setSubmitting)
                }
                enableReinitialize={true}
                validationSchema={profileValidator}
                initialValues={{
                  firstname:
                    userInfo && userInfo.firstname ? userInfo.firstname : "",
                  lastname:
                    userInfo && userInfo.lastname ? userInfo.lastname : "",
                  email: userInfo && userInfo.email ? userInfo.email : "",
                  phonenumber:
                    userInfo && userInfo.phonenumber
                      ? userInfo.phonenumber
                      : "",
                  username:
                    userInfo && userInfo.username ? userInfo.username : "",
                }}
              >
                {({
                  handleChange,
                  isSubmitting,
                  handleSubmit,
                  handleBlur,
                  values,
                  touched,
                  errors,
                }) => (
                  <Form className="mt-10 md:mt-16" onSubmit={handleSubmit}>
                    <div className="grid md:grid-cols-2 gap-6 mt-10 max-w-3xl">
                      <div>
                        <label
                          htmlFor="firstname"
                          className="block mb-2 font-Satoshi500 text-[#909090] text-xs"
                        >
                          First Name
                        </label>
                        <input
                          type="text"
                          name="firstname"
                          placeholder="Enter your first name"
                          className={
                            touched.firstname && errors.firstname
                              ? "appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4"
                              : "appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4"
                          }
                          value={values.firstname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.firstname && errors.firstname ? (
                          <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                            {touched.firstname && errors.firstname}
                          </small>
                        ) : null}
                      </div>
                      <div>
                        <label
                          htmlFor="lastname"
                          className="block mb-2 font-Satoshi500 text-[#909090] text-xs"
                        >
                          Last Name
                        </label>
                        <input
                          type="text"
                          name="lastname"
                          placeholder="Enter your last name"
                          className={
                            touched.lastname && errors.lastname
                              ? "appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4"
                              : "appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4"
                          }
                          value={values.lastname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.lastname && errors.lastname ? (
                          <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                            {touched.lastname && errors.lastname}
                          </small>
                        ) : null}
                      </div>

                      <div className="mt-4">
                        <label
                          htmlFor="username"
                          className="block mb-2 font-Satoshi500 text-[#909090] text-xs"
                        >
                          Username
                        </label>
                        <input
                          type="text"
                          name="username"
                          disabled
                          placeholder="Khenny"
                          className={
                            touched.username && errors.username
                              ? "appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4"
                              : "appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4 disabled:opacity-60 disabled:cursor-not-allowed"
                          }
                          value={values.username}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.username && errors.username ? (
                          <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                            {touched.username && errors.username}
                          </small>
                        ) : null}
                      </div>
                      <div className="mt-4">
                        <label
                          htmlFor="email"
                          className="block mb-2 font-Satoshi500 text-[#909090] text-xs"
                        >
                          Email Address
                        </label>
                        <input
                          type="email"
                          name="email"
                          disabled
                          placeholder="kkmichaellll@gmail.com"
                          className={
                            touched.email && errors.email
                              ? "appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4"
                              : "appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4 disabled:opacity-60 disabled:cursor-not-allowed"
                          }
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.email && errors.email ? (
                          <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                            {touched.email && errors.email}
                          </small>
                        ) : null}
                      </div>
                      <div className="mt-4">
                        <label
                          htmlFor="phonenumber"
                          className="block mb-2 font-Satoshi500 text-[#909090] text-xs"
                        >
                          Phone Number
                        </label>
                        <input
                          type="text"
                          name="phonenumber"
                          placeholder="+234 913 727 8220"
                          className={
                            touched.phonenumber && errors.phonenumber
                              ? "appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4"
                              : "appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4"
                          }
                          value={values.phonenumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.phonenumber && errors.phonenumber ? (
                          <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                            {touched.phonenumber && errors.phonenumber}
                          </small>
                        ) : null}
                      </div>

                      {/* Country */}
                      <div className="mt-4">
                        <label
                          htmlFor="country"
                          className="block mb-2 font-Satoshi500 text-[#909090] text-xs"
                        >
                          Nationality{" "}
                        </label>
                        <input
                          type="text"
                          name="country"
                          disabled
                          className={
                            touched.country && errors.country
                              ? "appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4"
                              : "appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4 disabled:opacity-60 disabled:cursor-not-allowed"
                          }
                          value={userInfo.nationality}
                          // onChange={handleChange}
                          // onBlur={handleBlur}
                        />
                        <span className="text-red-500 text-sm">
                          (If your nationality is wrong, please react out to the
                          admin through our live chat)
                        </span>
                        {/* {touched.country && errors.country ? (
                          <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                            {touched.country && errors.country}
                          </small>
                        ) : null} */}
                      </div>
                    </div>

                    <div className="mt-10 flex gap-1">
                      <button
                        disabled={updateLoader}
                        className="bg-[#235643] rounded-[4px]
                                disabled:bg-[#235643] 
                                disabled:opacity-[0.7]
                                text-sm
                                font-Satoshi400 w-full
                                md:min-w-[248px] md:w-auto
                                py-4 px-6 text-[#FFFFFF]
                                hover:bg-[#235643] hover:opacity-[0.9] transition-all"
                      >
                        Save Changes
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>

              <div className="my-8">
                <KYCStatus />
              </div>
            </div>
          )}

          {/* security section */}
          {tabValue === "Security" && (
            <div className="mt-10 max-w-2xl">
              <Formik
                onSubmit={(values, { setSubmitting }) =>
                  handlePasswordUpdate(values, setSubmitting)
                }
                validationSchema={ChangePasswordValidator}
                initialValues={{
                  current_password: "",
                  password: "",
                  password_confirmation: "",
                }}
              >
                {({
                  handleChange,
                  isSubmitting,
                  handleSubmit,
                  handleBlur,
                  values,
                  touched,
                  errors,
                }) => (
                  <Form className="mt-10 md:mt-16" onSubmit={handleSubmit}>
                    <div className="flex flex-col md:flex-row gap-6 md:gap-10">
                      <div className="min-w-[120px]">
                        <h6 className="text-[#212121] font-Satoshi500 text-base">
                          Password
                        </h6>
                      </div>
                      <div className="w-full">
                        <div className="">
                          <label
                            htmlFor="current_password"
                            className="block mb-2 font-Satoshi500 text-[#909090] text-xs"
                          >
                            Existing Password
                          </label>
                          <input
                            type="password"
                            name="current_password"
                            placeholder="******"
                            className={
                              touched.current_password &&
                              errors.current_password
                                ? "appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4"
                                : "appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4"
                            }
                            value={values.current_password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.current_password &&
                          errors.current_password ? (
                            <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                              {touched.current_password &&
                                errors.current_password}
                            </small>
                          ) : null}
                        </div>
                        <div className="mt-4">
                          <label
                            htmlFor="password"
                            className="block mb-2 font-Satoshi500 text-[#909090] text-xs"
                          >
                            New Password
                          </label>
                          <input
                            type="password"
                            name="password"
                            placeholder="******"
                            className={
                              touched.password && errors.password
                                ? "appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4"
                                : "appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4"
                            }
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.password && errors.password ? (
                            <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                              {touched.password && errors.password}
                            </small>
                          ) : null}
                        </div>
                        <div className="mt-4">
                          <label
                            htmlFor="password_confirmation"
                            className="block mb-2 font-Satoshi500 text-[#909090] text-xs"
                          >
                            Confirm New Password
                          </label>
                          <input
                            type="password"
                            name="password_confirmation"
                            placeholder="******"
                            className={
                              touched.password_confirmation &&
                              errors.password_confirmation
                                ? "appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4"
                                : "appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4"
                            }
                            value={values.password_confirmation}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.password_confirmation &&
                          errors.password_confirmation ? (
                            <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                              {touched.password_confirmation &&
                                errors.password_confirmation}
                            </small>
                          ) : null}
                        </div>

                        <div className="mt-6 flex gap-1">
                          <button
                            disabled={updateLoader}
                            className="bg-[#235643] rounded-[4px]
                                        disabled:bg-[#235643] 
                                        disabled:opacity-[0.7]
                                        text-sm
                                        font-Satoshi400 w-full md:w-auto
                                        md:min-w-[248px]
                                        py-4 px-6 text-[#FFFFFF]
                                        hover:bg-[#235643] hover:opacity-[0.9] transition-all"
                          >
                            Update Password
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>

              <div className="w-full h-[1px] bg-[#F1F1F1] mt-10"></div>

              <div className="flex flex-col md:flex-row gap-6 md:gap-10 mt-10">
                <div className="min-w-[120px]">
                  <h6 className="text-[#212121] font-Satoshi500 text-base">
                    Transaction PIN
                  </h6>
                </div>

                <Formik
                  onSubmit={(values, { setSubmitting }) =>
                    handlePinUpdate(values, setSubmitting)
                  }
                  validationSchema={ChangePinValidator}
                  initialValues={{
                    current_pin: "",
                    pin: "",
                    pin_confirm: "",
                  }}
                >
                  {({
                    handleChange,
                    isSubmitting,
                    handleSubmit,
                    handleBlur,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form className="mt-5 md:mt-16" onSubmit={handleSubmit}>
                      <div className="w-full">
                        <div className="">
                          <label
                            htmlFor="current_pin"
                            className="block mb-2 font-Satoshi500 text-[#909090] text-xs"
                          >
                            Existing PIN
                          </label>
                          <input
                            type="password"
                            name="current_pin"
                            placeholder="******"
                            className={
                              touched.current_pin && errors.current_pin
                                ? "appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4"
                                : "appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4"
                            }
                            value={values.current_pin}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.current_pin && errors.current_pin ? (
                            <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                              {touched.current_pin && errors.current_pin}
                            </small>
                          ) : null}
                        </div>
                        <div className="mt-4">
                          <label
                            htmlFor="pin"
                            className="block mb-2 font-Satoshi500 text-[#909090] text-xs"
                          >
                            New PIN
                          </label>
                          <input
                            type="password"
                            name="pin"
                            placeholder="******"
                            className={
                              touched.pin && errors.pin
                                ? "appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4"
                                : "appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4"
                            }
                            value={values.pin}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.pin && errors.pin ? (
                            <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                              {touched.pin && errors.pin}
                            </small>
                          ) : null}
                        </div>
                        <div className="mt-4">
                          <label
                            htmlFor="pin_confirm"
                            className="block mb-2 font-Satoshi500 text-[#909090] text-xs"
                          >
                            Confirm New PIN
                          </label>
                          <input
                            type="password"
                            name="pin_confirm"
                            placeholder="******"
                            className={
                              touched.pin_confirm && errors.pin_confirm
                                ? "appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4"
                                : "appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4"
                            }
                            value={values.pin_confirm}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.pin_confirm && errors.pin_confirm ? (
                            <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                              {touched.pin_confirm && errors.pin_confirm}
                            </small>
                          ) : null}
                        </div>

                        <div className="mt-6 flex gap-1">
                          <button
                            disabled={updateLoader}
                            className="bg-[#235643] rounded-[4px]
                                            disabled:bg-[#235643] 
                                            disabled:opacity-[0.7]
                                            text-sm
                                            font-Satoshi400
                                            min-w-[248px]
                                            py-4 px-6 text-[#FFFFFF]
                                            hover:bg-[#235643] hover:opacity-[0.9] transition-all"
                          >
                            Update PIN
                          </button>
                          <div
                            onClick={PINModal}
                            className="
                                            text-sm
                                            font-Satoshi400
                                            py-4 px-6 text-[#235643]
                                            underline cursor-pointer
                                            transition-all"
                          >
                            Reset PIN
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          )}

          {/* account details section */}
          {tabValue === "KYC" && (
            <div className="mt-10 max-w-4xl">
              <div className="bg-[#FBFBFB] mt-8 rounded-xl py-4 px-4">
                <div className="overflow-x-auto relative">
                  <table className="w-full text-center">
                    <thead className="text-[#010918] font-Satoshi700 bg-transparent text-xs">
                      <tr>
                        <th scope="col" className="py-2 px-6">
                          Account Name
                        </th>

                        <th scope="col" className="py-2 px-6">
                          Account Number
                        </th>
                        <th scope="col" className="py-2 px-6">
                          Bank Name
                        </th>
                        <th scope="col" className="py-2 px-6">
                          Actions
                        </th>
                      </tr>
                    </thead>

                    {!loader && (
                      <tbody>
                        {accountDetails.length > 0
                          ? accountDetails.map((item) => (
                              <tr
                                key={item.id}
                                className="bg-white text-xs font-Satoshi400 rounded-lg hover:bg-gray-100 hover:rounded-lg"
                              >
                                <td className="py-3 flex justify-center px-4 text-[#212121] whitespace-nowrap ">
                                  <div className="flex gap-2 items-center">
                                    <div className="flex justify-center items-center w-[36px] h-[36px] bg-white rounded-full">
                                      <img src={bank_icon} alt="bank icon" />
                                    </div>
                                    <span>{item.accountname}</span>
                                  </div>
                                </td>

                                <td className="py-3 px-4 text-center text-[#212121] font-Satoshi500">
                                  {item.banknumber}
                                </td>
                                <td className="py-3 px-4 text-center text-[#909090]">
                                  {item.bankname}
                                </td>
                                <td className="py-3 px-4 flex justify-center text-[#212121]">
                                  <div
                                    onClick={() => handleDeleteAccount(item.id)}
                                    className="cursor-pointer"
                                  >
                                    <img
                                      src={trash_outline}
                                      alt="trash outline"
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                          : ""}
                      </tbody>
                    )}
                  </table>
                </div>

                {loader && (
                  <div className="animate-pulse w-full mt-4">
                    <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                    <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                    <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                    <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                    <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                    <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
                  </div>
                )}

                {/* no account details */}
                {!loader && accountDetails.length === 0 ? (
                  <div className="flex flex-col min-h-[500px] justify-center items-center">
                    <div>
                      <img
                        src={nobank}
                        alt="no bank icon"
                        className="w-[130px] h-[130px]"
                      />
                    </div>

                    <div className="mt-3">
                      <p className="text-[#909090] text-center text-sm font-Satoshi400">
                        You have not added any bank account yet.
                      </p>
                    </div>
                    <div className="mt-8">
                      <button
                        onClick={AccountModal}
                        className="bg-[#235643] rounded-md
                                        disabled:bg-[#235643] 
                                        disabled:opacity-[0.7]
                                        text-sm
                                        font-Satoshi400
                                        justify-center
                                        flex items-center min-w-[248px]
                                        py-4 px-6 text-[#FFFFFF]
                                        hover:bg-[#235643] hover:opacity-[0.9] transition-all"
                      >
                        Add Account
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>

              {!loader && accountDetails.length > 0 ? (
                <div className="mt-8 flex justify-center">
                  <button
                    onClick={AccountModal}
                    className="bg-[#235643] rounded-md
                                        disabled:bg-[#235643] 
                                        disabled:opacity-[0.7]
                                        text-sm
                                        font-Satoshi400
                                        justify-center
                                        flex items-center min-w-[248px]
                                        py-4 px-6 text-[#FFFFFF]
                                        hover:bg-[#235643] hover:opacity-[0.9] transition-all"
                  >
                    Add Account
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          )}

          {/* referrals section */}
          {tabValue === "Referrals" && (
            <div className="mt-10 max-w-5xl">
              <h6 className="text-[#212121] font-Satoshi500 text-base">
                Refer & Earn
              </h6>
              <p className="text-[#909090] font-Satoshi500 text-sm mt-1">
                Invite your friends & family and receive a bonus when they
                initiate their first trade.
              </p>

              <div className="flex flex-col md:flex-row items-center gap-6 mt-12">
                <div className="text-center">
                  <div className="flex justify-center">
                    <div className="w-[80px] h-[80px] rounded-full flex justify-center items-center bg-[#F1F1F1]">
                      <img src={sms_tracking} alt="sms_tracking" />
                    </div>
                  </div>
                  <h6 className="text-[#000000] font-Satoshi700 mt-2">
                    Send Invitation
                  </h6>
                  <p className="text-[#000000] text-sm font-Satoshi400 mt-2">
                    Send your referral link to your friends, and tell them how
                    cool and seamless trading on CardVest is.
                  </p>
                </div>

                <div className="bg-line w-[400px] h-[1px]"></div>

                <div className="text-center">
                  <div className="flex justify-center">
                    <div className="w-[80px] h-[80px] rounded-full flex justify-center items-center bg-[#F1F1F1]">
                      <img src={sms_tracking} alt="sms_tracking" />
                    </div>
                  </div>
                  <h6 className="text-[#000000] font-Satoshi700 mt-2">
                    Registration
                  </h6>
                  <p className="text-[#000000] text-sm font-Satoshi400 mt-2">
                    Let them create their accounts on CardVest using your
                    generated unique referral link.
                  </p>
                </div>

                <div className="bg-line w-[400px] h-[1px]"></div>

                <div className="text-center">
                  <div className="flex justify-center">
                    <div className="w-[80px] h-[80px] rounded-full flex justify-center items-center bg-[#F1F1F1]">
                      <img src={sms_tracking} alt="sms_tracking" />
                    </div>
                  </div>
                  <h6 className="text-[#000000] font-Satoshi700 mt-2">
                    Get Your Reward
                  </h6>
                  <p className="text-[#000000] text-sm font-Satoshi400 mt-2">
                    Once they create their accounts and complete all necessary
                    registration process, you get rewarded.
                  </p>
                </div>
              </div>

              <div className="w-full h-[1px] bg-[#F1F1F1] mt-10"></div>

              <div className="mt-10">
                <h6 className="text-[#212121] font-Satoshi500 text-base">
                  Send Invite
                </h6>
                <p className="text-[#909090] text-sm font-Satoshi400 mt-1">
                  Enter the recipients’ email(s) to get started.
                </p>
                <Formik
                  onSubmit={(values, { setSubmitting }) =>
                    handleSendReferral(values, setSubmitting)
                  }
                  validationSchema={sendReferralValidator}
                  initialValues={{
                    email: "",
                  }}
                >
                  {({
                    handleChange,
                    isSubmitting,
                    handleSubmit,
                    handleBlur,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form
                      ref={ref}
                      className="mt-10 md:mt-16"
                      onSubmit={handleSubmit}
                    >
                      <div className="flex flex-col md:flex-row mt-4 gap-4">
                        <div className="md:w-[80%]">
                          <input
                            type="email"
                            name="email"
                            placeholder="Enter email"
                            className={
                              touched.email && errors.email
                                ? "appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4"
                                : "appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4"
                            }
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.email && errors.email ? (
                            <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                              {touched.email && errors.email}
                            </small>
                          ) : null}
                        </div>
                        <div className="md:w-[20%]">
                          <button
                            disabled={updateLoader}
                            className="bg-[#235643] rounded-[4px]
                                            disabled:bg-[#235643] 
                                            disabled:opacity-[0.7]
                                            text-sm
                                            font-Satoshi400
                                            w-full
                                            py-4 px-6 text-[#FFFFFF]
                                            hover:bg-[#235643] hover:opacity-[0.9] transition-all"
                          >
                            Send Invite
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>

              <div className="w-full h-[1px] bg-[#F1F1F1] mt-10"></div>

              <div className="mt-10">
                <h6 className="text-[#212121] font-Satoshi500 text-base">
                  Share Referral Link
                </h6>
                <p className="text-[#909090] text-sm font-Satoshi400 mt-1">
                  Enter the recipients’ email(s) to get started.
                </p>

                <div className="flex flex-col md:flex-row mt-4 gap-4">
                  <div className="md:w-[80%]">
                    <div className="rounded-[4px]  bg-[#F7F9FB] py-4 px-4 text-[#212121] text-sm ">
                      <p>
                        {referralCode
                          ? referralCode
                          : "No referral code generated yet"}
                      </p>
                    </div>
                  </div>
                  <div className="md:w-[20%]">
                    <CopyToClipboard
                      text={referralCode ? referralCode : "N/A"}
                      onCopy={() => handleCopy()}
                    >
                      <button
                        className="bg-[#235643] rounded-[4px]
                                                disabled:bg-[#235643] 
                                                disabled:opacity-[0.7]
                                                text-sm
                                                font-Satoshi400
                                                w-full
                                                flex justify-center items-center gap-2
                                                py-4 px-6 text-[#FFFFFF] max-h-[52px]
                                                hover:bg-[#235643] hover:opacity-[0.9] transition-all"
                      >
                        <img src={copy_icon} alt="copy icon" />
                        Copy Link
                      </button>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>

              <div className="mt-10">
                <h6 className="text-[#212121] font-Satoshi500 text-base">
                  Your Referrals
                </h6>

                <div className="bg-[#F7F9FB] mt-6 rounded-md min-h-[450px] flex flex-col justify-center text-center items-center">
                  <div>
                    <div className="flex justify-center">
                      <img src={referral_icon} alt="referral icon" />
                    </div>

                    <h6 className="text-[#212121] font-Satoshi500 text-base mt-2">
                      Wanna Earn Cool Cash?
                    </h6>
                    <p className="text-[#8D8D8D] text-sm font-Satoshi400 mt-1">
                      Share your referral link to invite friends and family
                    </p>

                    <div className="mt-4 flex justify-center">
                      <button
                        className="bg-[#235643] rounded-[4px]
                                                    disabled:bg-[#235643] 
                                                    disabled:opacity-[0.7]
                                                    text-sm
                                                    font-Satoshi400
                                                
                                                    flex justify-center items-center gap-2
                                                    py-4 px-6 text-[#FFFFFF]
                                                    hover:bg-[#235643] hover:opacity-[0.9] transition-all"
                      >
                        <img src={copy_icon} alt="copy icon" />
                        Copy Referral Link
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SettingsPage;
