import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUrl } from "../config";
import cogoToast from "cogo-toast";
import { Mixpanel } from "../../components/Helpers/MixPanel";
import "../../components/Helpers/firebase";
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

export const getWithdrawals = createAsyncThunk(
  "getWithdrawals",
  async ({ page }, { rejectWithValue, dispatch, getState }) => {
    const currency = getState().auth.currency;
    try {
      const res = await axios.get(
        `${apiUrl}withdrawals?currency=${currency}&page=${page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

export const getWithdrawalAccount = createAsyncThunk(
  "getWithdrawalAccount",
  async ({ currency }, { rejectWithValue, getState, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}bank-accounts?currency=${currency}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

export const withdrawToFiat = createAsyncThunk(
  "withdrawToFiat",
  async (
    { bank, amount, currency },
    { rejectWithValue, getState, dispatch }
  ) => {
    const userId = getState().auth.userInfo.id;
    try {
      const res = await axios.post(
        `${apiUrl}withdrawals`,
        {
          bank,
          amount,
          currency,
          type: "fiat",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        Mixpanel.identify(userId);
        Mixpanel.track("Withdraw To Fiat", {
          Bank: bank,
          Amount: amount,
          Currency: currency,
          Type: "fiat",
        });
        firebase
          .analytics()
          .logEvent("withdrawal", {
            user_id: userId,
            amount: amount,
            type: "fiat",
            bank_name: bank,
            wallet_type: currency,
          });
        cogoToast.success("Your withdrawal request has been placed!", {
          position: "top-right",
        });
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 400 || error.response.status === 422) {
        cogoToast.error(
          "Please check that you have insufficient funds in wallet!",
          {
            position: "top-right",
          }
        );
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

export const withdrawUSDT = createAsyncThunk(
  "withdrawUSDT",
  async (
    { amount, currency, wallet_address, network },
    { rejectWithValue, getState, dispatch }
  ) => {
    const userId = getState().auth.userInfo.id;
    try {
      const res = await axios.post(
        `${apiUrl}withdrawals`,
        {
          amount,
          currency,
          type: "crypto",
          wallet_address,
          network,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        Mixpanel.identify(userId);
        Mixpanel.track("Withdraw To Crypto", {
          "Wallet Address": wallet_address,
          Amount: amount,
          Currency: currency,
          Type: "crypto",
          Network: network,
        });
        firebase
          .analytics()
          .logEvent("withdrawal", {
            user_id: userId,
            amount: amount,
            type: "crypto",
            network: network,
            wallet_address: wallet_address,
            wallet_type: currency,
          });
        cogoToast.success("Your withdrawal request has been placed!", {
          position: "top-right",
        });
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 400 || error.response.status === 422) {
        cogoToast.error(
          "Please check that you have insufficient funds in wallet!",
          {
            position: "bottom-right",
          }
        );
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

export const getUSDTWithdrawalRate = createAsyncThunk(
  "getUSDTWithdrawalRate",
  async ({ currency }, { rejectWithValue, getState, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}withdrawals/rate?currency=${currency}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

export const getUSDTNetworks = createAsyncThunk(
  "getUSDTNetworks",
  async (arg, { rejectWithValue, getState, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}withdrawals/crytpo/networks`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);
