import React,{useEffect, useState} from 'react'
import { Form, Formik } from 'formik'
import { step1Validator, step2Validator, step3Validator } from '../../validationSchema/validator'
import { Link, useNavigate, useLocation} from 'react-router-dom'
import eye_off from '../../assets/icons/Hide.svg'
import logo from '../../assets/icons/logo.svg'
import chevron_grey from '../../assets/icons/chevron_grey.svg'
import { useDispatch, useSelector } from 'react-redux'
import { userRegister } from '../../redux/Auth/authActions'
import { clearSignUpSuccess } from '../../redux/Auth/auth'
import OnboardingSidebar from '../../components/OnboardingSidebar/sidebar'

const SignUpPage = () => {

    const [passwordShown, setPasswordShown] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {loading, signup_success} = useSelector((state) => state.auth);

    const search = useLocation().search;

    const [step, setStep] = useState(1)

    const [val, setVal] = useState({})

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true)
    }

    const handleStep1 = (values) => {
        setStep(2)
        setVal({
            ...val,
            username: values.username,
            email: values.email,
            password: values.password
        })
    }

    const handleStep2 = (values) =>{
        setStep(3)
        setVal({
            ...val,
            country: values.country,
            phoneNumber: values.phoneNumber
        })
    }

    const handleStep3 = (values) =>{
        let resp = {
            ...val,
            referralCode: values.referralCode,
            hearAbout: values.hearAbout
        }
        setVal({
            ...val,
            referralCode: values.referralCode,
            hearAbout: values.hearAbout
        })
        dispatch(userRegister({
            email: resp.email,
            password: resp.password,
            username: resp.username,
            phonenumber: resp.phoneNumber,
            nationality: resp.country,
            referrer: resp.referralCode
        }))
     }


    useEffect(()=>{
        if(signup_success){
            navigate('/verify-info')
            setTimeout(()=>{
                dispatch(clearSignUpSuccess())
            },1000)
        }   
    },[dispatch,navigate, signup_success])



    return ( 
        <>
              <div className='grid md:grid-cols-2 font-Satoshi400'>
                <OnboardingSidebar />
                <div className='px-6 md:px-20 2xl:px-40 py-6 flex flex-col h-full min-h-screen justify-center'>
                     <div className='flex justify-center md:hidden'>
                         <img src={logo} alt="logo" />
                    </div>

                    <div className='text-center mt-5'>
                        <h6 className='text-[#212121] font-Satoshi700 text-xl md:text-2xl'>Create Account</h6>
                            <p className='text-[#909090] text-xs mt-1'>Enter your details to get started</p>
                    </div>

                    {/* steps progress circle */}

                    <div className='flex justify-center mt-8 items-center gap-2'>
                            <div  
                            className={
                                step === 1 ?
                                'bg-[#235643] flex justify-center items-center rounded-full w-[36px] h-[36px]'
                                :
                                'bg-[#E8E8E8] cursor-pointer flex justify-center items-center rounded-full w-[36px] h-[36px]'
                                }>
                                <p className='text-white font-Satoshi500'>1</p>
                            </div>
                            <div className='bg-[#E8E8E8] w-[54px] h-[2px]'></div>
                            <div
                             className={
                                step === 2 ?
                                'bg-[#235643] flex justify-center items-center rounded-full w-[36px] h-[36px]'
                                :
                                'bg-[#E8E8E8] cursor-pointer flex justify-center items-center rounded-full w-[36px] h-[36px]'
                                }>
                              <p className='text-white font-Satoshi500'>2</p>
                            </div>
                            <div className='bg-[#E8E8E8] w-[54px] h-[2px]'></div>
                            <div  
                            className={
                                step === 3 ?
                                'bg-[#235643] flex justify-center items-center rounded-full w-[36px] h-[36px]'
                                :
                                'bg-[#E8E8E8] cursor-pointer flex justify-center items-center rounded-full w-[36px] h-[36px]'
                                }>
                              <p className='text-white font-Satoshi500'>3</p>
                            </div>
                    </div>

                    {/* 1st step --- */}

                  {
                        step === 1 &&  <Formik
                        onSubmit={(values, { setSubmitting }) =>
                        handleStep1(values, setSubmitting)
                        }
                        validationSchema={step1Validator}
                        initialValues={{
                        username: '',
                        email: '',
                        password: '',
                        }}
                    >
                        {({
                        handleChange,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        values,
                        touched,
                        errors,
                        }) => (
                        <Form className="mt-10 md:mt-10" onSubmit={handleSubmit}>

                        <div>
                            <label htmlFor='username' className="block mb-2 text-[#909090] text-xs">
                                  Username
                            </label>
                            <input
                                type="text"
                                name="username"
                                placeholder="Enter your username"
                                className={
                                touched.username && errors.username
                                    ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                    : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                }
                                value={values.username}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {touched.username && errors.username ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.username && errors.username}
                                </small>
                            ) : null}
                            </div>
                            
                            <div className='mt-6'>
                            <label htmlFor='email' className="block mb-2 text-[#909090] text-xs">
                                  Email Address
                            </label>
                            <input
                                type="email"
                                name="email"
                                placeholder="Enter your email address"
                                className={
                                touched.email && errors.email
                                    ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                    : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                }
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {touched.email && errors.email ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.email && errors.email}
                                </small>
                            ) : null}
                            </div>

                            <div className="mt-6">
                            <label htmlFor='password' className="block mb-2 text-[#909090] text-xs">
                                Password
                            </label>
                            <div className="relative">
                                <input
                                type={passwordShown ? 'text' : 'password'}
                                name="password"
                                placeholder="Enter your password"
                                className={
                                    touched.password && errors.password
                                        ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                        : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                    }
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                />
                                <img
                                onClick={togglePasswordVisiblity}
                                src={passwordShown ? eye_off : eye_off}
                                alt="eye"
                                className="absolute w-[18px] h-[18px] top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
                                />
                            </div>
                            {touched.password && errors.password ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.password && errors.password}
                                </small>
                            ) : null}
                            </div>
                              
                            <div className="mt-8">
                            <button
                                type="submit"
                                disabled={false}
                                className="bg-[#235643] 
                                disabled:bg-[#235643] 
                                disabled:opacity-[0.7]
                                text-sm
                                rounded-md w-full py-4 text-[#fff] font-medium hover:bg-[#235643] hover:opacity-[0.9] transition-all"
                            >
                                Next
                            </button>
                            </div>
                        
                        </Form>
                        )}
                    </Formik>
                        }

                    {/* --- Second Step */}
                   {
                    step === 2 &&
                    <Formik
                        onSubmit={(values, { setSubmitting }) =>
                        handleStep2(values, setSubmitting)
                        }
                        validationSchema={step2Validator}
                        initialValues={{
                            country: '',
                            phoneNumber: '',
                        }}
                    >
                        {({
                        handleChange,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        values,
                        touched,
                        errors,
                        }) => (
                        <Form className="mt-10 md:mt-10" onSubmit={handleSubmit}>

                        <div>
                            <label htmlFor='country' className="block mb-2 text-[#909090] text-xs">
                                  Country
                            </label>
                            <div className='relative'>
                            <select
                                type="text"
                                name="country"
                                placeholder="Select a country"
                                className={
                                touched.country && errors.country
                                    ? 'appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                    : 'appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                }
                                value={values.country}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            >
                                  <option value="" selected disabled>Select a country</option>
                                <option value="Nigeria">Nigeria</option>
                                <option value="Ghana">Ghana</option>
                            </select>

                             <img className='absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer' src={chevron_grey} alt="arrow" />
                            </div>
                          
                            {touched.country && errors.country ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.country && errors.country}
                                </small>
                            ) : null}
                            </div>
                            
                            <div className="mt-6">
                            <label htmlFor='phoneNumber' className="block mb-2 text-[#909090] text-xs">
                                Phone Number
                            </label>
                            <div className="relative">
                                <input
                                type='text'
                                name="phoneNumber"
                                placeholder="Enter your phone number"
                                className={
                                    touched.phoneNumber && errors.phoneNumber
                                        ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                        : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                    }
                                value={values.phoneNumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                />
                              
                            </div>
                            {touched.phoneNumber && errors.phoneNumber ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.phoneNumber && errors.phoneNumber}
                                </small>
                            ) : null}
                            </div>
                              
                            <div className="mt-8">
                            <button
                                type="submit"
                                disabled={false}
                                className="bg-[#235643] 
                                disabled:bg-[#235643] 
                                disabled:opacity-[0.7]
                                text-sm
                                rounded-md w-full py-4 text-[#fff] font-medium hover:bg-[#235643] hover:opacity-[0.9] transition-all"
                            >
                                Next
                            </button>
                            </div>

 
                        </Form>
                        )}
                    </Formik>
                    }

                    {/* third step ------ */}
                   {
                    step === 3 &&
                    <Formik
                        onSubmit={(values, { setSubmitting }) =>
                        handleStep3(values, setSubmitting)
                        }
                        validationSchema={step3Validator}
                        initialValues={{
                            referralCode: new URLSearchParams(search).get("ref") ? new URLSearchParams(search).get("ref") : "",
                            hearAbout: '',
                        }}
                    >
                        {({
                        handleChange,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        values,
                        touched,
                        errors,
                        }) => (
                        <Form className="mt-10 md:mt-10" onSubmit={handleSubmit}>

                        <div>
                            <label htmlFor='referralCode' className="block mb-2 text-[#909090] text-xs">
                            Referral Code (Optional)
                            </label>
                            <input
                                type="text"
                                name="referralCode"
                                placeholder="Referral Code (Optional)"
                                className={
                                touched.referralCode && errors.referralCode
                                    ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                    : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                }
                                value={values.referralCode}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {touched.referralCode && errors.referralCode ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.referralCode && errors.referralCode}
                                </small>
                            ) : null}
                            </div>
                            
                            <div className="mt-6">
                            <label htmlFor='hearAbout' className="block mb-2 text-[#909090] text-xs">
                               How did you hear about us?
                            </label>
                            <div className='relative'>
                            <select
                                type="text"
                                name="hearAbout"
                                placeholder="How did you hear about us?"
                                className={
                                touched.hearAbout && errors.hearAbout
                                    ? 'appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                    : 'appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                }
                                value={values.hearAbout}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            >
                                  <option value="" selected disabled>How did you hear about us?</option>
                                <option value="Referral">Referral</option>
                                <option value="From a friend/colleague">From a friend/colleague</option>
                                <option value="Social media">Social media</option>
                                <option value="Search engine">Search engine</option>
                                <option value="Others">Others</option>
                            </select>

                             <img className='absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer' src={chevron_grey} alt="arrow" />
                            </div>
                            {touched.hearAbout && errors.hearAbout ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.hearAbout && errors.hearAbout}
                                </small>
                            ) : null}
                            </div>
                              
                            <div className="mt-8">
                            <button
                                type="submit"
                                disabled={loading}
                                className="bg-[#235643] 
                                disabled:bg-[#235643] 
                                disabled:opacity-[0.7]
                                text-sm
                                rounded-md w-full py-4 text-[#fff] font-medium hover:bg-[#235643] hover:opacity-[0.9] transition-all"
                            >
                                Submit
                            </button>
                            </div>

 
                        </Form>
                        )}
                    </Formik>
                    }

                    <div className='text-center mt-10'>
                        <p className='text-[#235643] text-sm'>Already have an account? <Link to="/" className='text-[#235643] font-Satoshi500 text-sm'>Login</Link> </p>
                    </div>

                </div>
            </div>
        </>
     );
}
 
export default SignUpPage;