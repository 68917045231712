import * as Yup from 'yup'

export const loginValidator = Yup.object({
  email: Yup.string()
    .email('Enter a valid email')
    .required('Email is required'),
  password: Yup.string().required('Password is required'),
})

export const forgotValidator = Yup.object({
  email: Yup.string()
    .email('Enter a valid email')
    .required('Email is required')
})

export const step1Validator = Yup.object({
  username: Yup.string().required('Enter a username'),
  email: Yup.string()
    .email('Enter a valid email')
    .required('Email is required'),
  password: Yup.string().required('Password is required'),
})

export const step2Validator = Yup.object({
  country: Yup.string().required('Select a country'),
  phoneNumber: Yup.string()
  .required("Phone number is required")
  .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid phone number"),
})

export const step3Validator = Yup.object({
  referralCode: Yup.string(),
  hearAbout: Yup.string(),
})

export const resetPasswordValidator = Yup.object({
  password: Yup.string()
    .min(6, 'Password cannot be less than 6 characters')
    .required('Password is required'),
  confirm_password: Yup.string()
    .required('Passwords must match')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
})

export const profileValidator = Yup.object({
  firstname: Yup.string().required('Firstname is required'),
  lastname: Yup.string().required('Lastname is required'),
  username: Yup.string().required('Username is required'),
  phonenumber: Yup.string().required('Phonenumber is required'),
  email: Yup.string()
    .email('Enter a valid email')
    .required('Email is required'),
})

export const ChangePasswordValidator = Yup.object({
  current_password: Yup.string().required("Password is required"),
  password: Yup.string().required("Enter a new password"),
  password_confirmation: Yup.string()
    .required("Passwords must match")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export const ChangePinValidator = Yup.object({
  current_pin: Yup.string()
  .min(4, "Pin cannot be less than 4 characters")
  .max(4, "Pin cannot be more than 4 characters")
  .required("Pin is required")
  .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid pin"),
  pin: Yup.string()
  .min(4, "Pin cannot be less than 4 characters")
  .max(4, "Pin cannot be more than 4 characters")
  .required("Enter a new pin")
  .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid pin"),
  pin_confirm: Yup.string()
  .required("Pin must match")
  .oneOf([Yup.ref("pin"), null], "Pin must match"),
});

export const ResetPinPasswordValidator = Yup.object({
  password: Yup.string().required('Password is required'),
});


export const buyDataValidator = Yup.object({
  wallet: Yup.string().required("Select a wallet"),
  network: Yup.string().required("Select a network"),
  bundle: Yup.string().required("Select a bundle"),
  amount: Yup.string()
  .required("Amount is required")
  .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid amount"),
  phonenumber: Yup.string()
  .required("Phone number is required")
  .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid phone number"),
});

export const buyAirtimeValidator = Yup.object({
  wallet: Yup.string().required("Select a wallet"),
  network: Yup.string().required("Select a network"),
  amount: Yup.string()
  .required("Amount is required")
  .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid amount"),
  phonenumber: Yup.string()
  .required("Phone number is required")
  .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid phone number"),
});

export const buyCableValidator = Yup.object({
  wallet: Yup.string().required("Select a wallet"),
  provider: Yup.string().required("Select a provider"),
  package: Yup.string().required("Select a package"),
  amount: Yup.string()
  .required("Amount is required")
  .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid amount"),
  smartcardnumber: Yup.string()
  .required("Smart card number is required")
  .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid smart card number"),
  phonenumber: Yup.string()
  .required("Phone number is required")
  .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid phone number"),
});

export const buyElectricityValidator = Yup.object({
  wallet: Yup.string().required("Select a wallet"),
  provider: Yup.string().required("Select a provider"),
  amount: Yup.string()
  .required("Amount is required")
  .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid amount"),
  metertype: Yup.string().required("Select a meter type"),
  meternumber: Yup.string()
  .required("Meter number is required")
  .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid meter number"),
  phonenumber: Yup.string()
  .required("Phone number is required")
  .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid phone number"),
});


export const buyWifiValidator = Yup.object({
  wallet: Yup.string().required("Select a wallet"),
  provider: Yup.string().required("Select a provider"),
  wifiplan: Yup.string().required("Select a wifi plan"),
  amount: Yup.string()
  .required("Amount is required")
  .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid amount"),
  phonenumber: Yup.string()
  .required("Phone number is required")
  .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid phone number"),
});

export const bankAccountValidatorGH = Yup.object({
  bank: Yup.string().required("Bank is required"),
  accountNumber:  Yup.string()
  .min(10, "Mobile number cannot be less than 10 digits")
  .max(10, "Exceeded characters for mobile number")
  .required("Mobile Number is required")
  .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid mobile number"),
});

export const bankAccountValidatorNG = Yup.object({
  bank: Yup.string().required("Bank is required"),
  accountNumber:  Yup.string()
  .min(10, 'Account number cannot be less than 10 digits')
  .max(14, 'Exceeded characters for Account Number')
  .required("Account Number is required").matches(
    /^-?[0-9]+(.[0-9]{1-7})?$/,
    "Enter a valid Account Number"),
});

export const withdrawalAccountValidator = Yup.object({
    wallet: Yup.string().required("Select a wallet"),
    withdrawalAcct: Yup.string().required("Select an account"),
    amount: Yup.string()
    .required("Amount is required")
    .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid amount")
});

export const withdrawUSDTAccountValidator = Yup.object({
  network: Yup.string().required("Select a network"),
  wallet_address:  Yup.string()
  .min(30, 'Wallet Address cannot be less than 30 digits').required("Wallet Address is required"),
});

export const SellCardValidator = Yup.object({
  category: Yup.string().required("Select a category"),
  giftcard: Yup.string().required("Select a giftcard"),
  amount: Yup.string()
  .required("Amount is required")
  .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid amount"),
  wallet: Yup.string().required("Select a wallet"),
  comment: Yup.string(),
});

export const BuyCardValidator = Yup.object({
  giftcard: Yup.string().required("Select a giftcard"),
  wallet: Yup.string().required("Select a wallet"),
  country: Yup.string().required("Select a country"),
  email:  Yup.string()
  .email('Enter a valid email')
  .required('Email is required')
});

export const BuyCardRateValidator = Yup.object({
  giftcard: Yup.string().required("Select a giftcard"),
  wallet: Yup.string().required("Select a wallet"),
  country: Yup.string().required("Select a country"),
  cardUnit: Yup.string().required("Select a card unit"),
  quantity: Yup.string()
  .required("Quantity is required")
  .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid quantity")
});

export const sendReferralValidator = Yup.object({
  email: Yup.string()
    .email('Enter a valid email')
    .required('Email is required'),
})

export const verifyKYCValidator = Yup.object({
  firstname: Yup.string().required('Firstname is required'),
  lastname: Yup.string().required('Lastname is required'),
  bvn: Yup.string()
  .min(11, 'BVN cannot be less than 11 digits')
  .required("BVN is required").matches(
    /^-?[0-9]+(.[0-9]{1-7})?$/,
    "Enter a valid BVN"),
})