import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "../../ui/input";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo, levelFourKYC } from "../../../redux/Auth/kycActions";
import cogoToast from "cogo-toast";
import Button from "../../ui/button";

const validator = Yup.object({
  state: Yup.string().required("Your state is required").label("State"),
  city: Yup.string().required("Your city is required").label("City"),
  street: Yup.string()
    .required("Street address is important")
    .label("Street Address"),
  house_no: Yup.string()
    .required("Please enter your house number or apartment description")
    .label("House No"),
});

function LevelFour({ onComplete }) {
  const dispatch = useDispatch();
  const { userInfo, isLoading } = useSelector((state) => state.auth);

  const { handleChange, handleSubmit, values, isValid } = useFormik({
    initialValues: {
      firstname: userInfo.firstname,
      lastname: userInfo.lastname,
      phonenumber: userInfo.phonenumber,
      state: "",
      lga: "",
      city: "",
      street: "",
      house_no: "",
    },
    validationSchema: validator,
    onSubmit: async (values) => {
      try {
        const response = await dispatch(
          levelFourKYC({
            firstname: values.firstname,
            lastname: values.lastname,
            phonenumber: values.phonenumber,
            state: values.state,
            city: values.city,
            lga: values.lga,
            street: values.street,
            house_no: values.house_no,
          })
        ).unwrap();

        console.log(response);
        cogoToast.success(response.message, {
          position: "top-right",
        });

        dispatch(getUserInfo()).then(() => {
          onComplete && onComplete();
        });
      } catch (error) {
        cogoToast.error(error.message, {
          position: "top-right",
        });
      }
    },
  });

  return (
    <form className="mt-10 " onSubmit={handleSubmit}>
      <div className="flex flex-col gap-4 gap-y-8 min-h-[300px]">
        {/* State */}
        <Input
          type="text"
          id="state"
          name="state"
          placeholder="Enter State e.g. Lagos"
          label={"State"}
          value={values.state}
          onChange={handleChange}
        />

        {/* City */}
        <Input
          type="text"
          id="city"
          name="city"
          placeholder="Enter City e.g. Ikeja"
          label={"City"}
          value={values.city}
          onChange={handleChange}
        />

        {/* LGA */}
        <Input
          type="text"
          id="lga"
          name="lga"
          placeholder="Enter Local Government e.g Ikeja"
          label={"Local Government"}
          value={values.lga}
          onChange={handleChange}
        />

        {/* Enter Street Address */}
        <Input
          type="text"
          id="street"
          name="street"
          placeholder="Enter Street Address"
          label={"Street Address"}
          value={values.street}
          onChange={handleChange}
        />

        {/* Enter House No */}
        <Input
          type="text"
          id="house_no"
          name="house_no"
          placeholder="Enter house no"
          label="House No./Description"
          value={values.house_no}
          onChange={handleChange}
        />

        {/* First Name */}
        <Input
          type="hidden"
          id="firstname"
          name="firstname"
          placeholder="Enter First Name"
          label={"First Name"}
          value={values.firstname}
          onChange={handleChange}
        />

        {/* Last Name */}
        <Input
          type="hidden"
          id="lastname"
          name="lastname"
          placeholder="Enter Last Name"
          label={"Last Name"}
          value={values.lastname}
          onChange={handleChange}
        />
      </div>

      <div className="mt-8">
        <Button type="submit" disabled={!isValid || isLoading}>
          Upgrade Account
        </Button>
      </div>
    </form>
  );
}

export default LevelFour;
