import { createSlice } from '@reduxjs/toolkit'
import { BuyCard, getAvailableCountries, getBuyGiftcardByCountry, getBuyGiftcards, getCategories, getGiftcardCategories, getSpecificGiftcard, SellGiftcard, uploadGiftcard } from './tradeActions'

const initialState = {
  categories: [],
  giftcards: [],
  buygiftcards: [],
  buygiftcard: [],
  giftcard: {},
  terms: "",
  loader: false,
  cardImages: [],
  tradeLoader: false,
  isTradePlaced: false,
  countries: []
}

export const tradeSlice = createSlice({
  name: 'trade',
  initialState,
  reducers: {
    uploadCard: (state, {payload}) =>{
      state.cardImages =[...state.cardImages, payload.data.filename]
    },
    removeCard: (state, {payload}) =>{
      let result = [...state.cardImages];
      // removing the element using splice
      result.splice(payload, 1);
      state.cardImages = result
    },
    clearTradePlaced: (state) =>{
      state.isTradePlaced = false
      state.cardImages = []
      state.terms = ""
      state.giftcards = []
      state.buygiftcards = []
    },
    clearBuyGiftcards: (state) =>{
      state.buygiftcards = []
    },  
    clearTerms: (state) =>{
      state.terms = ""
    }
  },
  extraReducers:{

     //   get giftcard categories
    [getCategories.pending]: (state)=>{
      
    },
    [getCategories.fulfilled]: (state, { payload }) => {
        state.categories = payload.data
     },
     [getCategories.rejected]: (state, { payload }) => {
       
     },

    //  get available countries
    [getAvailableCountries.pending]: (state) =>{

    },
    [getAvailableCountries.fulfilled]: (state, {payload}) =>{
      state.countries = payload.data
    },
    [getAvailableCountries.rejected]: (state) =>{

    },

    // get giftcard catgories by id and type
    [getGiftcardCategories]: (state) =>{

    },
    [getGiftcardCategories.fulfilled]: (state, { payload }) => {
        state.giftcards = payload.data
     },
     [getGiftcardCategories.rejected]: (state, { payload }) => {
     },

    //  get giftcards users want to buy
     [getBuyGiftcards.pending]: (state) =>{

     },
     [getBuyGiftcards.fulfilled]: (state, {payload}) =>{
        state.buygiftcard = payload.data
     }, 
     [getBuyGiftcards.rejected]: (state) =>{

     },
    //  get specific giftcard
    [getSpecificGiftcard.pending]: (state) =>{

    },
    [getSpecificGiftcard.fulfilled]: (state, {payload}) =>{
        state.giftcard = payload.data
        state.terms = payload.data.terms
    },
    [getSpecificGiftcard.rejected]: (state) =>{

    },

    // get buy gifcards by country
    [getBuyGiftcardByCountry.pending]: (state) =>{

    },
    [getBuyGiftcardByCountry.fulfilled]: (state,{payload}) =>{
       state.buygiftcards = payload.data
    },
    [getBuyGiftcardByCountry.rejected]: (state) =>{

    },

    //  upload giftcard images
    [uploadGiftcard.pending]:  (state) =>{
        state.loader = true
    },
    [uploadGiftcard.fulfilled]: (state, {payload}) =>{
      state.loader = false
      state.cardImages = [...state.cardImages, payload.data.filename]
    },
    [uploadGiftcard.rejected]: (state) =>{
      state.loader = false
    },

    // sell giftcard
    [SellGiftcard.pending]: (state) =>{
      state.tradeLoader = true
    },
    [SellGiftcard.fulfilled] : (state) =>{
      state.tradeLoader = false
      state.isTradePlaced = true
    },
    [SellGiftcard.rejected]: (state) =>{
      state.tradeLoader = false
    },

    // buy giftcard
    [BuyCard.pending]: (state) =>{
        state.tradeLoader = true
    },
    [BuyCard.fulfilled] : (state) =>{
        state.tradeLoader = false
        state.isTradePlaced = true
    },
    [BuyCard.rejected]: (state) =>{
        state.tradeLoader = false
    }

  }
})

// Action creators are generated for each case reducer function
export const {increment, uploadCard, removeCard, clearTradePlaced,clearTerms,clearBuyGiftcards } = tradeSlice.actions

export default tradeSlice.reducer