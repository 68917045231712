import React, { useEffect } from 'react'
import mail_icon from '../../assets/icons/mail.svg'
import logo_icon from '../../assets/icons/logo_icon.svg'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { verifyEmail } from '../../redux/Auth/authActions'

const VerifySuccessPage = () => {
    const dispatch = useDispatch()
    const search = useLocation().search;

    const {isVerified} = useSelector((state) => state.auth);


    useEffect(()=>{
        const verifyUrl = new URLSearchParams(search).get("verify_url");
        dispatch(verifyEmail({
            url: verifyUrl
        }))
    },[dispatch, search])


    return (  
        <>
             <div className='flex justify-center pt-24'>
                    <img src={logo_icon} alt="logo" />
                </div>

            <div className='max-w-lg py-10 mx-auto'>
                <div className='md:bg-[#F8F8F8] rounded-[16px] py-16 px-10 md:px-16'>
                    <div className='flex justify-center'>
                        <img src={mail_icon} alt="mail" className='' />
                    </div>
                    <div className='text-center'>
                        <h3 className='text-[#235643] font-Satoshi500 mt-4'>Email Verification</h3>
                        <p className='text-[#909090] text-sm mt-2'>
                           {isVerified ?
                            'The link is either invalid or has expired, please request again!'
                            :
                            'Your email has been verified successfully!'} 
                        </p>
                    </div>

                </div>

            </div>
        </>
    );
}
 
export default VerifySuccessPage;