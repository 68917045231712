import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUrl } from "../config";
import cogoToast from "cogo-toast";
import "../../components/Helpers/firebase";
import "firebase/compat/analytics";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

export const getUserInfo = createAsyncThunk(
  "getUserInfo",
  async (arg, { rejectWithValue, dispatch, getState }) => {
    try {
      const res = await axios.get(`${apiUrl}users/me`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const getKYCSettings = createAsyncThunk(
  "getKYCSettings",
  async (arg, { rejectWithValue, dispatch, getState }) => {
    const currency = getState().auth.currency;
    try {
      const res = await axios.get(`${apiUrl}kyc/levels`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        params: {
          currency,
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const levelTwoKYC = createAsyncThunk(
  "levelTwoKYC",
  async ({ bvn }, { rejectWithValue, getState, dispatch }) => {
    try {
      const res = await axios.post(
        `${apiUrl}kyc/level-two`,
        {
          bvn,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );

      return res.data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const levelThreeKYC = createAsyncThunk(
  "levelThreeKYC",
  async (
    {
      id_type,
      id_number,
      id_image,
      selfie,
      dob,
      firstname,
      lastname,
      lga,
      state,
    },
    { rejectWithValue, getState, dispatch }
  ) => {
    try {
      const res = await axios.post(
        `${apiUrl}kyc/level-three`,
        {
          id_type,
          id_number,
          id_image,
          selfie,
          dob,
          firstname,
          lastname,
          lga,
          state,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        cogoToast.success(res.data.message, {
          position: "top-right",
        });
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const levelFourKYC = createAsyncThunk(
  "levelFourKYC",
  async (
    { firstname, lastname, phonenumber, state, city, lga, street, house_no },
    { rejectWithValue, getState, dispatch }
  ) => {
    try {
      const res = await axios.post(
        `${apiUrl}kyc/level-four`,
        {
          firstname,
          lastname,
          phonenumber,
          state,
          city,
          lga,
          street,
          house_no,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        cogoToast.success(res.data.message, {
          position: "top-right",
        });
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

// For Ghana
export const ghanaLevelTwoKYC = createAsyncThunk(
  "ghanaLevelTwoKYC",
  async (
    { id_type, id_number, id_image, selfie, votercard_type, dob },
    { rejectWithValue, getState, dispatch }
  ) => {
    try {
      const res = await axios.post(
        `${apiUrl}kyc/gh/level-two`,
        {
          id_type,
          id_number,
          id_image,
          selfie,
          votercard_type,
          dob,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        cogoToast.success(res.data.message, {
          position: "top-right",
        });
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const ghanaLevelThreeKYC = createAsyncThunk(
  "ghanaLevelThreeKYC",
  async (
    { passport_number, passport_image },
    { rejectWithValue, getState, dispatch }
  ) => {
    try {
      const res = await axios.post(
        `${apiUrl}kyc/gh/level-three`,
        {
          passport_number,
          passport_image,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        cogoToast.success(res.data.message, {
          position: "top-right",
        });
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const ghanaLevelFourKYC = createAsyncThunk(
  "ghanaLevelFourKYC",
  async (
    { state, city, district, street, house_no },
    { rejectWithValue, getState, dispatch }
  ) => {
    try {
      const res = await axios.post(
        `${apiUrl}kyc/gh/level-four`,
        {
          state,
          city,
          district,
          street,
          house_no,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        cogoToast.success(res.data.message, {
          position: "top-right",
        });
        return res.data;
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response.data);
      }
    }
  }
);
