import React from 'react'
import { Form, Formik } from 'formik'
import { forgotValidator } from '../../validationSchema/validator'
import { Link } from 'react-router-dom'
import logo from '../../assets/icons/logo.svg'
import { useDispatch, useSelector } from 'react-redux'
import { userForgot } from '../../redux/Auth/authActions'
import OnboardingSidebar from '../../components/OnboardingSidebar/sidebar'

const ForgotPasswordPage = () => {

    const dispatch = useDispatch()
    const {loading} = useSelector((state) => state.auth);
   
    const handleSubmit = (values) => {
        console.log(values)
        dispatch(userForgot(values)) 
    }

    return ( 
        <>
            <div className='grid md:grid-cols-2 font-Satoshi400'>
               <OnboardingSidebar />
                <div className='px-6 md:px-20 2xl:px-40 flex flex-col h-full min-h-screen justify-center'>
                <div className='flex justify-center md:hidden'>
                         <img src={logo} alt="logo" />
                    </div>

                    <div className='text-center mt-5'>
                        <h6 className='text-[#212121] font-Satoshi700 text-xl md:text-2xl'>Forgot Password</h6>
                            <p className='text-[#909090] text-xs mt-1'>Enter your email address to reset password</p>
                    </div>

                    <Formik
                        onSubmit={(values, { setSubmitting }) =>
                        handleSubmit(values, setSubmitting)
                        }
                        validationSchema={forgotValidator}
                        initialValues={{
                        email: '',
                        }}
                    >
                        {({
                        handleChange,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        values,
                        touched,
                        errors,
                        }) => (
                        <Form className="mt-10 md:mt-16" onSubmit={handleSubmit}>
                            <div>
                            <label htmlFor='email' className="block mb-2 text-[#909090] text-xs">
                                  Email Address
                            </label>
                            <input
                                type="text"
                                name="email"
                                placeholder="Enter your email address"
                                className={
                                touched.email && errors.email
                                    ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                    : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                }
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {touched.email && errors.email ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.email && errors.email}
                                </small>
                            ) : null}
                            </div>
    
                         <div className="mt-6">
                            <button
                                type="submit"
                                disabled={loading}
                                className="bg-[#235643] 
                                disabled:bg-[#235643] 
                                disabled:opacity-[0.7]
                                text-sm
                                rounded-md w-full py-4 text-[#fff] font-medium hover:bg-[#235643] hover:opacity-[0.9] transition-all"
                            >
                                Submit
                            </button>
                            </div>

                           
                            <div className='text-center mt-10'>
                                <p className='text-[#235643] text-sm'>Remember password? <Link to="/" className='text-[#235643] font-Satoshi500 text-sm'>Login</Link> </p>
                            </div>

                        
                        </Form>
                        )}
                    </Formik>

                </div>
            </div>
        </>
     );
}
 
export default ForgotPasswordPage;