import { createSlice } from "@reduxjs/toolkit";
import {
  addAccountDetails,
  changeTransactionPin,
  confirmTransactionPin,
  createVBAAccount,
  deleteAccountDetails,
  getAccountDetails,
  getBanks,
  getReferralsCode,
  getVBADetails,
  getWalletBalance,
  resendVerifyEmail,
  resetPinWithPassword,
  resetTransactionPin,
  sendReferralInvite,
  setTransactionPin,
  updateProfilePicture,
  updateUserPassword,
  updateUserProfile,
  userForgot,
  userLogin,
  userRegister,
  userResetPassword,
  verifyBankAccount,
  verifyEmail,
  verifyKYC,
} from "./authActions";
import "../../components/Helpers/firebase";
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import moment from "moment";
import {
  getKYCSettings,
  getUserInfo,
  ghanaLevelFourKYC,
  ghanaLevelThreeKYC,
  ghanaLevelTwoKYC,
  levelFourKYC,
  levelThreeKYC,
  levelTwoKYC,
} from "./kycActions";

const initialState = {
  value: 0,
  loading: false,
  token: "",
  isAuthenticated: false,
  isVerified: false,
  resendLoader: false,
  userInfo: null,
  pin_set: false,
  email_verified: false,
  banking_verified: false,
  has_virtual_account: false,
  setPinLoader: false,
  useremail: "",
  signup_success: false,
  isResetSuccess: false,
  referralCode: null,
  currency: "NGN",
  walletbalance: 0,
  updateLoader: false,
  accountDetails: [],
  banks: [],
  accountName: "",
  addLoader: false,
  imageLoader: false,
  loader: false,
  isFirstReset: false,
  isSecondReset: false,
  isPinConfirmed: false,
  confirmWithdrawLoader: false,
  inviteSent: true,
  kycLoader: false,
  vbaLoader: false,
  hasVBA: false,
  bankDetails: null,
  vbaCreated: false,
  kycVerified: false,

  isLoading: false,
  kycSettings: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    clearSignUpSuccess: (state) => {
      state.signup_success = false;
    },
    clearIsVerified: (state) => {
      state.isVerified = false;
    },
    clearAccountName: (state) => {
      state.accountName = "";
    },
    clearIsFirstReset: (state) => {
      state.isFirstReset = false;
    },
    clearIsSecondReset: (state) => {
      state.isSecondReset = false;
    },
    clearIsResetSuccess: (state) => {
      state.isResetSuccess = false;
    },
    clearPinConfirm: (state) => {
      state.isPinConfirmed = false;
    },
    switchCurrency: (state, { payload }) => {
      state.currency = payload;
    },
    UserEmail: (state, { email }) => {
      state.useremail = email;
    },
    clearInviteSent: (state) => {
      state.inviteSent = false;
    },
    clearvbaCreated: (state) => {
      state.vbaCreated = false;
    },
    clearKYCVerified: (state) => {
      state.kycVerified = false;
    },
    Logout: (state) => {
      firebase.analytics().logEvent("logout", {
        user_id: state.userInfo ? state.userInfo.id : "",
        logout_time: moment().format("h:mm:ss a"),
        logout_date: moment().format("MMMM Do YYYY"),
      });
      state.isAuthenticated = false;
      localStorage.setItem("token", "");
      localStorage.removeItem("persist:root");
    },
  },
  extraReducers: {
    // login user -------------------- ---------------------------------------------------------------
    [userLogin.pending]: (state) => {
      state.loading = true;
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.token = payload.data.token;
      state.isAuthenticated = true;
      localStorage.setItem("token", payload.data.token);
      state.userInfo = payload.data.user;
      state.pin_set = payload.data.user.pin_set;
      state.email_verified = payload.data.user.email_verified;
      state.banking_verified = payload.data.user.banking_verified;
      state.has_virtual_account = payload.data.user.has_virtual_account;
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isAuthenticated = false;
    },

    // Forgot Passowrd ------------------ --------------------------------------------------------
    [userForgot.pending]: (state) => {
      state.loading = true;
    },
    [userForgot.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [userForgot.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    // resend verify email
    [resendVerifyEmail.pending]: (state) => {
      state.resendLoader = true;
    },
    [resendVerifyEmail.fulfilled]: (state) => {
      state.resendLoader = false;
    },
    [resendVerifyEmail.rejected]: (state) => {
      state.resendLoader = false;
    },

    // user reset password
    [userResetPassword.pending]: (state) => {
      state.loading = true;
    },
    [userResetPassword.fulfilled]: (state) => {
      state.loading = false;
      state.isResetSuccess = true;
    },
    [userResetPassword.rejected]: (state) => {
      state.loading = false;
    },

    // verify email
    [verifyEmail.pending]: (state) => {},
    [verifyEmail.fulfilled]: (state) => {},
    [verifyEmail.rejected]: (state) => {
      state.isVerified = true;
    },

    // Register User ------------------ --------------------------------------------------------
    [userRegister.pending]: (state) => {
      state.loading = true;
    },
    [userRegister.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.signup_success = true;
    },
    [userRegister.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.signup_success = false;
    },

    // update profile details -----
    [updateUserProfile.pending]: (state) => {
      state.updateLoader = true;
    },
    [updateUserProfile.fulfilled]: (state, { payload }) => {
      state.updateLoader = false;
      state.userInfo.phonenumber = payload.data.phonenumber;
      state.userInfo.username = payload.data.username;
      state.userInfo.firstname = payload.data.firstname;
      state.userInfo.lastname = payload.data.lastname;
    },
    [updateUserProfile.rejected]: (state) => {
      state.updateLoader = false;
    },

    // update users password
    [updateUserPassword.pending]: (state) => {
      state.updateLoader = true;
    },
    [updateUserPassword.fulfilled]: (state) => {
      state.updateLoader = false;
    },
    [updateUserPassword.rejected]: (state) => {
      state.updateLoader = false;
    },

    [updateProfilePicture.pending]: (state) => {
      state.imageLoader = true;
    },
    [updateProfilePicture.fulfilled]: (state, { payload }) => {
      state.imageLoader = false;
      state.userInfo.image_url = payload.data;
    },
    [updateProfilePicture.rejected]: (state) => {
      state.imageLoader = false;
    },
    // set transaction pin
    [setTransactionPin.pending]: (state) => {
      state.setPinLoader = true;
    },
    [setTransactionPin.fulfilled]: (state, { payload }) => {
      state.pin_set = true;
      state.setPinLoader = false;
    },
    [setTransactionPin.rejected]: (state) => {
      state.setPinLoader = false;
    },

    [confirmTransactionPin.pending]: (state) => {
      state.confirmWithdrawLoader = true;
    },
    [confirmTransactionPin.fulfilled]: (state) => {
      state.isPinConfirmed = true;
      state.confirmWithdrawLoader = false;
    },
    [confirmTransactionPin.rejected]: (state) => {
      state.confirmWithdrawLoader = false;
    },
    // change transaction pin
    [changeTransactionPin.pending]: (state) => {
      state.updateLoader = true;
    },
    [changeTransactionPin.fulfilled]: (state) => {
      state.updateLoader = false;
    },
    [changeTransactionPin.rejected]: (state) => {
      state.updateLoader = false;
    },

    // reset pin with password
    [resetPinWithPassword.pending]: (state) => {
      state.updateLoader = true;
    },
    [resetPinWithPassword.fulfilled]: (state) => {
      state.updateLoader = false;
      state.isFirstReset = true;
    },
    [resetPinWithPassword.rejected]: (state) => {
      state.updateLoader = false;
    },

    // reset transaction pin
    [resetTransactionPin.pending]: (state) => {
      state.updateLoader = true;
    },
    [resetTransactionPin.fulfilled]: (state) => {
      state.updateLoader = false;
      state.isSecondReset = true;
    },
    [resetTransactionPin.rejected]: (state) => {
      state.updateLoader = false;
    },
    //   get users referral code
    [getReferralsCode.pending]: (state) => {},
    [getReferralsCode.fulfilled]: (state, { payload }) => {
      state.referralCode = payload.data.link;
    },
    [getReferralsCode.rejected]: (state, { payload }) => {},

    // get wallet balance
    [getWalletBalance.pending]: (state) => {},
    [getWalletBalance.fulfilled]: (state, { payload }) => {
      state.walletbalance = payload.data.balance;
    },
    [getWalletBalance.rejected]: (state) => {},

    // get account details
    [getBanks.pending]: (state) => {},
    [getBanks.fulfilled]: (state, { payload }) => {
      state.banks = payload.data;
    },
    [getBanks.rejected]: (state) => {
      state.banks = [];
    },

    [verifyBankAccount.pending]: (state) => {},
    [verifyBankAccount.fulfilled]: (state, { payload }) => {
      state.accountName = payload.data.account_name;
    },
    [verifyBankAccount.rejected]: (state) => {},

    [getAccountDetails.pending]: (state) => {
      state.loader = true;
    },
    [getAccountDetails.fulfilled]: (state, { payload }) => {
      state.loader = false;
      state.accountDetails = payload.data;
    },
    [getAccountDetails.rejected]: (state) => {
      state.loader = false;
    },
    [addAccountDetails.pending]: (state) => {
      state.addLoader = true;
    },
    [addAccountDetails.fulfilled]: (state) => {
      state.addLoader = false;
    },
    [addAccountDetails.rejected]: (state) => {
      state.addLoader = false;
    },
    [deleteAccountDetails.pending]: (state) => {
      state.addLoader = true;
    },
    [deleteAccountDetails.fulfilled]: (state) => {
      state.addLoader = false;
    },
    [deleteAccountDetails.rejected]: (state) => {
      state.addLoader = false;
    },
    [sendReferralInvite.pending]: (state) => {
      state.updateLoader = true;
    },
    [sendReferralInvite.fulfilled]: (state) => {
      state.updateLoader = false;
      state.inviteSent = true;
    },
    [sendReferralInvite.rejected]: (state) => {
      state.updateLoader = false;
    },
    // verify KYC
    [verifyKYC.pending]: (state) => {
      state.kycLoader = true;
    },
    [verifyKYC.fulfilled]: (state) => {
      state.kycLoader = false;
      state.banking_verified = true;
      state.kycVerified = true;
    },
    [verifyKYC.rejected]: (state) => {
      state.kycLoader = false;
    },
    // create VBA Account
    [createVBAAccount.pending]: (state) => {
      state.vbaLoader = true;
    },
    [createVBAAccount.fulfilled]: (state, { payload }) => {
      state.vbaLoader = false;
      state.bankDetails = payload.data;
      state.vbaCreated = true;
    },
    [createVBAAccount.rejected]: (state) => {
      state.vbaLoader = false;
    },

    // get a user virtual account details
    [getVBADetails.pending]: (state) => {},
    [getVBADetails.fulfilled]: (state, { payload }) => {
      state.hasVBA = true;
      state.bankDetails = payload.data;
    },
    [getVBADetails.rejected]: (state) => {
      state.hasVBA = false;
    },

    /** Author: Joseph */

    // Get the user details
    [getUserInfo.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.userInfo = payload.data;
    },
    [getUserInfo.pending]: (state) => {
      state.isLoading = true;
    },
    [getUserInfo.rejected]: (state) => {
      state.isLoading = false;
    },

    // upgrading to level two KYC
    [levelTwoKYC.pending]: (state) => {
      state.isLoading = true;
    },
    [levelTwoKYC.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [levelTwoKYC.rejected]: (state) => {
      state.isLoading = false;
    },

    // upgrading to level three KYC
    [levelThreeKYC.pending]: (state) => {
      state.isLoading = true;
    },
    [levelThreeKYC.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [levelThreeKYC.rejected]: (state) => {
      state.isLoading = false;
    },

    // upgrading to level four KYC
    [levelFourKYC.pending]: (state) => {
      state.isLoading = true;
    },
    [levelFourKYC.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [levelFourKYC.rejected]: (state) => {
      state.isLoading = false;
    },

    // upgrading to level two KYC Ghana
    [ghanaLevelTwoKYC.pending]: (state) => {
      state.isLoading = true;
    },
    [ghanaLevelTwoKYC.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [ghanaLevelTwoKYC.rejected]: (state) => {
      state.isLoading = false;
    },

    // upgrading to level three KYC Ghana
    [ghanaLevelThreeKYC.pending]: (state) => {
      state.isLoading = true;
    },
    [ghanaLevelThreeKYC.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [ghanaLevelThreeKYC.rejected]: (state) => {
      state.isLoading = false;
    },

    // upgrading to level four KYC Ghana
    [ghanaLevelFourKYC.pending]: (state) => {
      state.isLoading = true;
    },
    [ghanaLevelFourKYC.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [ghanaLevelFourKYC.rejected]: (state) => {
      state.isLoading = false;
    },

    // get KYC settings
    [getKYCSettings.pending]: (state) => {
      state.isLoading = true;
    },
    [getKYCSettings.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.kycSettings = payload.data;
    },
    [getKYCSettings.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  increment,
  decrement,
  Logout,
  UserEmail,
  clearSignUpSuccess,
  clearAccountName,
  clearIsFirstReset,
  clearIsSecondReset,
  clearIsResetSuccess,
  clearPinConfirm,
  switchCurrency,
  clearIsVerified,
  clearInviteSent,
  clearvbaCreated,
  clearKYCVerified,
} = authSlice.actions;

export default authSlice.reducer;
