import React from 'react'
import logo from '../../assets/icons/logo-green.svg'
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import  './slider.css'

const OnboardingSidebar = () => {

    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        fade: true,
        speed: 500,
        autoplaySpeed: 5000,
        cssEase: "linear"
      };

      
    return (
        <>
         <div className='hidden md:block relative h-full min-h-screen '>
                    <Slider {...settings}>
                    <div className='bg-slide3 w-full h-full bg-no-repeat bg-cover min-h-screen'>
                          <div className='absolute left-28 top-8'>
                             <img src={logo} className='' alt="logo" />
                            </div>
                            <div className='absolute bottom-20 w-full'>
                                <div className='flex flex-col items-center justify-center'>
                                    <h3 className='text-[#fff] font-Satoshi500 md:text-2xl text-lg'>Lightening fast Payment</h3>
                                        <p className='text-[#86B9A6] text-sm text-center mt-1 max-w-md mx-auto'>
                                        We don't joke with how fast you receive your payment. Once your card is verified, we settle payments within seconds!
                                    </p>
                                </div>   
                            </div>
                        </div>
                   
                   
                        <div className='bg-slide1 w-full h-full bg-no-repeat bg-cover min-h-screen'>
                          <div className='absolute left-28 top-8'>
                             <img src={logo} className='' alt="logo" />
                            </div>
                            <div className='absolute bottom-20 w-full'>
                                <div className='flex flex-col items-center justify-center'>
                                    <h3 className='text-[#fff] font-Satoshi500 md:text-2xl text-lg'>Real Time Rates</h3>
                                        <p className='text-[#86B9A6] text-sm text-center mt-1 max-w-md mx-auto'>
                                        The rates displayed on CardVest are constantly updated to align with current market rates.
                                    </p>
                                </div>   
                            </div>
                        </div>

                        <div className='bg-slide2 w-full h-full bg-no-repeat bg-cover min-h-screen'>
                          <div className='absolute left-28 top-8'>
                             <img src={logo} className='' alt="logo" />
                            </div>
                            <div className='absolute bottom-20 w-full'>
                                <div className='flex flex-col items-center justify-center'>
                                    <h3 className='text-[#fff] font-Satoshi500 md:text-2xl text-lg'>Customer Friendly</h3>
                                        <p className='text-[#86B9A6] text-sm text-center mt-1 max-w-md mx-auto'>
                                        Cardvest is made easy for both newbies and pro gift card traders.
                                    </p>
                                </div>   
                            </div>
                        </div>

                      
                    </Slider>
    
          </div> 
        </>
     );
}
 
export default OnboardingSidebar;