import { useFormik } from "formik";
import ImageUploader from "../../../ui/image-uploader";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserInfo,
  ghanaLevelThreeKYC,
} from "../../../../redux/Auth/kycActions";
import cogoToast from "cogo-toast";
import Button from "../../../ui/button";
import Input from "../../../ui/input";

const validator = Yup.object({
  passport_number: Yup.string()
    .required("You passport number is required")
    .label("Passport Number"),
  passport_image: Yup.string()
    .required("Please upload the image of your Passport Document")
    .label("Passport Image"),
});

function LevelThreeGH({ onComplete }) {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.auth);

  const {
    setFieldValue,
    handleChange,
    handleSubmit,
    values,
    touched,
    errors,
    isValid,
  } = useFormik({
    initialValues: {
      passport_number: "",
      passport_image: "",
    },
    validationSchema: validator,
    onSubmit: async (values) => {
      try {
        const response = await dispatch(
          ghanaLevelThreeKYC({
            passport_number: values.passport_number,
            passport_image: values.passport_image,
          })
        ).unwrap();

        cogoToast.success(response.message, {
          position: "top-right",
        });

        dispatch(getUserInfo()).then(() => {
          onComplete && onComplete();
        });
      } catch (error) {
        cogoToast.error(error.message, {
          position: "top-right",
        });
      }
    },
  });

  return (
    <form className="mt-10 " onSubmit={handleSubmit}>
      <div className="flex flex-col gap-4 gap-y-8 min-h-[300px]">
        {/* Passport Number Input */}
        <Input
          type="text"
          id="passport_number"
          name="passport_number"
          placeholder="Enter Passport Number"
          label={"Passport Number"}
          value={values.passport_number}
          onChange={handleChange}
        />

        {/* Passport Image */}
        <div className="">
          <label
            htmlFor="passport_image"
            className="inline-block mb-2 font-Satoshi500 text-[#909090] text-xs"
          >
            Upload Passport Image
          </label>
          <ImageUploader
            id={"passport_image"}
            helperText="Upload a copy of your International Passport."
            onChange={(value) => setFieldValue("passport_image", value)}
          />
          {touched.passport_image && errors.passport_image ? (
            <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
              {touched.passport_image && errors.passport_image}
            </small>
          ) : null}
        </div>
      </div>

      <div className="mt-8">
        <Button
          type="submit"
          disabled={!isValid || isLoading}
          loading={isLoading}
        >
          Upgrade Account
        </Button>
      </div>
    </form>
  );
}

export default LevelThreeGH;
