import React,{useState, useEffect} from 'react'
import nowithdrawal from '../../assets/images/no-withdrawal.svg'
import arrow_down_red from '../../assets/icons/arrow-down-red.svg'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { Link } from 'react-router-dom'
import Modal from '../../components/Modals/modal'
// import card_sample from '../../assets/images/cardsample.svg'
// import convert_card from '../../assets/icons/convert-card.svg'
import wallet_icon from '../../assets/icons/money-recive.svg'
// import tag_icon from '../../assets/icons/tag.svg'
import { getWithdrawals } from '../../redux/Withdrawals/withdrawalActions'
import PaginationComponent from '../../components/Pagination/Pagination';


const WithdrawalPage = () => {
    const dispatch = useDispatch()

    const {withdrawals, loading,total} = useSelector((state) => state.withdrawal);
    const {currency} = useSelector((state) => state.auth);

    const badgeFormat = (val) => {
        let result
        switch (val) {
          case 'pending':
            result = 'pending'
            break;
          case 'succeed':
            result = 'success'
            break;
        case 'failed':
            result = 'failed'
            break;
          default:
            result = 'pending'
            break
        }
        return result
      }

   

    const capitalizeFirstLetterFormat = (str) => {
        let res;
        if(str  === "succeed"){
            res = 'success';
        }
        else{
            res = str
        }

        let val = res.toLowerCase()
        let words = val.split(" ");
       for (let i = 0; i < words.length; i++) {
           words[i] = words[i][0].toUpperCase() +  words[i].substr(1);
       }
        return words.join(" ");
    }

    const numberWithCommas = (x) => { 
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); 
    }

    let PageSize = 25;
    const [currentPage, setCurrentPage] = useState(1);
    

    const [showWithdrawalModal, setShowWithdrawalModal] = useState(false)
    const WithdrawalModal = () =>{
        setShowWithdrawalModal(!showWithdrawalModal)
    }

    const [withdrawalInfo, setWithdrawalInfo] = useState({})

    const viewWithdrawal = (id) =>{
        setShowWithdrawalModal(true)
        let res = withdrawals.find((val)=> val.id === id)
        setWithdrawalInfo(res)
    }

    const getMoreItemsByOffset = (page) => {
        setCurrentPage(page)
        dispatch(getWithdrawals({
            page: page
        }))
    }

   
    useEffect(()=>{
        dispatch(getWithdrawals({
            page: 1
        }))
    },[dispatch])

    return ( 
        <>
           {/* withdrawal Details Modal */}
           <Modal show={showWithdrawalModal} showModal={WithdrawalModal} 
         title="Withdrawal Details"
         >
            <div className='mt-10 grid md:grid-cols-2 gap-4'>
               <div>
                    <div className='bg-[#F9F9F9] rounded-lg py-4 px-4'>
                        <div>
                            <p className='text-[#909090] font-Satoshi500 text-xs'>Reference</p>
                            <h6 className='text-[#212121] font-Satoshi500 text-sm mt-1'>{withdrawalInfo && withdrawalInfo.reference ? withdrawalInfo.reference: ''}</h6>
                        </div>
                        <div className='mt-4'>
                            <p className='text-[#909090] font-Satoshi500 text-xs'>Transaction Date</p>
                            <h6 className='text-[#212121] font-Satoshi500 text-sm mt-1'>{withdrawalInfo && withdrawalInfo.created_at ? moment(withdrawalInfo.created_at).format('MMMM Do YYYY, h:mm:ss a'): ''}</h6>
                        </div>
                        <div className='mt-4'>
                            <p className='text-[#909090] font-Satoshi500 text-xs'>Status</p>
                            <div className='border border-[#39A307] text-[#39A307] w-fit text-sm font-Satoshi400 rounded-[4px] flex justify-center bg-transparent px-4 py-1 mt-2'>
                            {withdrawalInfo && withdrawalInfo.status ? capitalizeFirstLetterFormat(withdrawalInfo.status): ''}
                            </div>
                        </div>
                    </div>
                    
                    <h5 className='text-[#235643] font-Satoshi400 text-sm mt-6'>TRANSACTION FEEDBACK</h5>
                    <div className='bg-[#F9F9F9] rounded-lg py-4 px-4 mt-1'>
                        <div>
                            <p className='text-[#909090] font-Satoshi500 text-xs'>Admin’s Feedback</p>
                            <h6 className='text-[#212121] font-Satoshi500 text-sm mt-1'>
                            {withdrawalInfo && withdrawalInfo.admin_comment ? 
                            withdrawalInfo.admin_comment == null ? 'NIL' : withdrawalInfo.admin_comment : ''}
                            </h6>
                        </div>
                        
                    </div>

                 
               </div>

               <div>
                     <h5 className='text-[#235643] font-Satoshi400 text-sm'>TRANSACTION INFO</h5>
                    <div className='bg-[#F9F9F9] rounded-lg py-4 px-4 mt-1'>
                        <div>
                            <p className='text-[#909090] font-Satoshi500 text-xs'>Type</p>
                            <div className='border border-[#39A307] text-[#39A307] uppercase w-fit text-sm font-Satoshi400 rounded-[4px] flex justify-center bg-transparent px-6 py-1 mt-2'>
                            {withdrawalInfo && withdrawalInfo.type ? withdrawalInfo.type: ''}
                            </div>
                        </div>

                     {
                        withdrawalInfo.type === 'fiat' &&
                        <>
                      <div className='mt-4'>
                            <p className='text-[#909090] font-Satoshi500 text-xs'>Bank Name</p>
                            <h6 className='text-[#212121] font-Satoshi500 text-sm mt-1'>{withdrawalInfo && withdrawalInfo.bank ? withdrawalInfo.bank.bankname: ''}</h6>
                        </div>
                        <div className='mt-4'>
                            <p className='text-[#909090] font-Satoshi500 text-xs'>Account Number</p>
                            <h6 className='text-[#212121] font-Satoshi500 text-sm mt-1'> {withdrawalInfo && withdrawalInfo.bank ? withdrawalInfo.bank.banknumber: ''}</h6>
                        </div>
                        <div className='mt-4'>
                            <p className='text-[#909090] font-Satoshi500 text-xs'>Account Name</p>
                            <h6 className='text-[#212121] font-Satoshi500 text-sm mt-1'>{withdrawalInfo && withdrawalInfo.bank ? withdrawalInfo.bank.accountname: ''}</h6>
                        </div>
                        </>
                        }

                        <div className='mt-4'>
                            <p className='text-[#909090] font-Satoshi500 text-xs'>Amount</p>
                            <h6 className='text-[#212121] font-Satoshi500 text-sm mt-1'>{currency === "NGN" ? '₦' : '₵'}{withdrawalInfo && withdrawalInfo.amount ? numberWithCommas(withdrawalInfo.amount) : ''}</h6>
                        </div>

                       {
                        withdrawalInfo.type === 'crypto' &&
                        <div className='mt-4'>
                            <p className='text-[#909090] font-Satoshi500 text-xs'>Wallet Address</p>
                            <h6 className='text-[#212121] font-Satoshi500 break-words text-sm mt-1'>{withdrawalInfo && withdrawalInfo.wallet_address ? withdrawalInfo.wallet_address : ''}</h6>
                        </div>
                        }
                      
                    </div>
               </div>
                
             </div>            
        </Modal>

            <div>
                   <h4 className='text-[#212121] font-Satoshi700 text-sm md:text-lg'>Withdrawals</h4>

                   <div className='mt-8'>
                   
                      {/* withrawals table */}
                        <div className='bg-[#FBFBFB] mt-8 rounded-xl py-4 px-4'>
                            <div className="overflow-x-auto relative">
                            <table className="w-full text-left">
                                <thead className="text-[#010918] font-Satoshi700 bg-transparent text-xs">
                                <tr>
                                    <th scope="col" className="py-2 px-6">
                                     Withdrawal Account
                                    </th>
                                   
                                    <th scope="col" className="py-2 px-6">
                                      Amount
                                    </th>
                                    <th scope="col" className="py-2 px-6">
                                    Date/Time
                                    </th>
                                    <th scope="col" className="py-2 px-6">
                                    Status
                                    </th>
                                </tr>
                            </thead>

                            {!loading && (
                            <tbody>
                                {withdrawals.length > 0
                                ? withdrawals.map((item) => (
                              <tr
                              onClick={()=> viewWithdrawal(item.id)}
                               key={item.id} className="bg-white text-xs font-Satoshi400 rounded-lg hover:bg-gray-100 hover:rounded-lg cursor-pointer">
                                <td
                                className="py-3 px-4 text-[#212121] whitespace-nowrap "
                                >
                                    <div className='flex gap-2 items-center'>
                                           <div className = 'flex justify-center items-center w-[36px] h-[36px] bg-white rounded-full'>
                                            <img src={wallet_icon} alt="wallet_icon" />
                                        </div>
                                
                                        <span>{item.type === 'fiat' ? 'Bank Account' : 'Crypto Wallet'}</span>
                                    </div>
                            
                                </td>
                               
                                <td className="py-3 px-4 text-[#212121] font-Satoshi500">
                                    <div className='flex gap-1 items-center'>
                                        <img src={arrow_down_red} alt="arrow" />
                                        <span>{currency === "NGN" ? '₦' : '₵'}{numberWithCommas(item.amount)}</span>
                                    </div>
                                
                                </td>
                                <td className="py-3 px-4 text-[#909090]">
                                    {moment(item.created_at).format('MMMM Do YYYY, h:mm:ss a')}
                                </td>
                                <td className="py-3 px-4  text-[#212121]">
                                    <div className='flex items-center max-w-[120px] justify-center bg-[#F7F7F7] rounded-[36px] px-6 py-2'>
                                        <p className={badgeFormat(item.status)}>{capitalizeFirstLetterFormat(item.status)}</p>
                                    </div>
                                </td>     
                                </tr>
                                    ))
                                : ''}
                            </tbody>
                            )} 
                           
                                
                            </table>
                             </div>

                             {loading && (
                                <div className="animate-pulse w-full mt-4">
                                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                                <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
                                </div>
                            )}

                            
                             {/* no transactions */}
                             {!loading && withdrawals.length === 0 ? (
                                  <div className='flex flex-col min-h-[500px] justify-center items-center'>
                                  <div>
                                      <img src={nowithdrawal} alt="no withdrawal icon" className='w-[130px] h-[130px]' />
                                  </div>
                                
                                  <div className='mt-3'>
                                      <p className='text-[#909090] text-center text-sm font-Satoshi400'>
                                      You haven not made any withdrawal.<br/>
                                      Trade now to earn instantly.
                                      </p>
                                  </div>
                                  <div className='mt-8'>
                                    <Link to="/trade-now" className='bg-[#235643] rounded-md
                                        disabled:bg-[#235643] 
                                        disabled:opacity-[0.7]
                                        text-sm
                                        font-Satoshi500
                                        justify-center
                                        flex items-center min-w-[248px]
                                        py-4 px-6 text-[#FFFFFF]
                                        hover:bg-[#235643] hover:opacity-[0.9] transition-all'> 
                                        Trade Now
                                    </Link>
                                  </div>
  
                              </div>
                            ) : (
                                ''
                          )}
                        </div>

                        {/* pagination */}
                        {
                            loading ?
                                ""
                                :
                            withdrawals.length > 0 && withdrawals ?
                            <div className="flex justify-center mt-5 mb-10">
                                <PaginationComponent
                                    currentPage={currentPage}
                                    totalCount={total}
                                    pageSize={PageSize}
                                    onPageChange={(page) => getMoreItemsByOffset(page)}
                                />
                            </div>
                            :
                            ""
                        }

                    </div>

            </div>
        </>
     );
}
 
export default WithdrawalPage;