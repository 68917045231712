import clx from "classnames";
import React from "react";

export default function Input({
  type = "text",
  id,
  name,
  placeholder,
  value,
  onChange,
  className,
  error,
  label,
}) {
  return (
    <div
      className={clx({
        hidden: type === "hidden",
      })}
    >
      {label && type !== "hidden" && (
        <label
          htmlFor={id}
          className="block mb-2 font-Satoshi500 text-[#909090] text-xs"
        >
          {label}
        </label>
      )}
      <input
        type={type}
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className={clx(
          "appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm border-none focus:outline-none rounded-md bg-[#F7F9FB] py-4 px-4",
          {
            "focus:border-[#DB2424] border border-[#DB2424]": error,
          }
        )}
      />
      {error ? (
        <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
          {error}
        </small>
      ) : null}
    </div>
  );
}
