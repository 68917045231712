import { createSlice } from "@reduxjs/toolkit";
import { fetchAvailableCoins, sellCrypto } from "./cryptoActions";

const status = {
  IDLE: "idle",
  LOADING: "loading",
  SUCCESS: "succeeded",
  FAILED: "failed",
};

const initialState = {
  selectedWallet: "",
  selectedCoin: "",
  exchangeRateToUSD: 1,
  exchangeRulesToSelectedCurrency: null,
  availableCoins: [],
  coinAmount: "",
  amountInDollars: "",
  amountInSelectedCurrency: "",
  tradeDetails: null,

  status: "idle", // Possible values: 'idle', 'loading', 'succeeded', 'failed'
  error: null,
  showPaymentModal: true,
};

export const cryptoSlice = createSlice({
  name: "crypto",
  initialState,
  reducers: {
    setShowPaymentWallet: (state, action) => {
      state.showPaymentModal = action.payload;
    },
    setWallet: (state, action) => {
      state.selectedWallet = action.payload;

      state.amountInDollars = "";
      state.amountInSelectedCurrency = "";
      state.coinAmount = "";
    },
    setCoin: (state, action) => {
      const coinData = action.payload;

      if (!coinData) {
        state.selectedCoin = "";
        state.exchangeRateToUSD = "";
        state.exchangeRulesToSelectedCurrency = "";
      } else {
        state.selectedCoin = coinData.name;
        state.exchangeRateToUSD = coinData.rate;
        state.exchangeRulesToSelectedCurrency = coinData.rules;
      }

      state.amountInDollars = "";
      state.amountInSelectedCurrency = "";
      state.coinAmount = "";
    },
    setCoinAmount: (state, action) => {
      state.coinAmount = action.payload;

      // calculate other amounts
      const rate = state.exchangeRateToUSD;
      const amountInUSD = action.payload * rate;
      state.amountInDollars = amountInUSD;

      // Calculate amount in selected currency based on rules
      const rules = state.exchangeRulesToSelectedCurrency;
      const selectedCurrency = state.selectedWallet;
      const rule = rules.find(
        (r) =>
          parseFloat(amountInUSD) >= parseFloat(r.min) &&
          parseFloat(amountInUSD) <= parseFloat(r.max)
      );

      if (rule && rule.rates[selectedCurrency]) {
        const rateInSelectedCurrency = rule.rates[selectedCurrency];
        state.amountInSelectedCurrency = (
          amountInUSD * rateInSelectedCurrency
        ).toFixed(2);
      } else {
        state.amountInSelectedCurrency = "";
      }
    },
    setAmountInDollars: (state, action) => {
      state.amountInDollars = action.payload;

      // Calculate coinAmount based on the inverse of the exchange rate
      const rate = state.exchangeRateToUSD;
      if (rate !== 0) {
        const coinAmount = (action.payload / rate).toFixed(8);
        state.coinAmount = coinAmount;
      } else {
        state.coinAmount = "";
      }

      // Calculate amount in selected currency based on rules
      const rules = state.exchangeRulesToSelectedCurrency;
      const selectedCurrency = state.selectedWallet;
      const rule = rules.find(
        (r) =>
          parseFloat(action.payload) >= parseFloat(r.min) &&
          parseFloat(action.payload) <= parseFloat(r.max)
      );

      if (rule && rule.rates[selectedCurrency]) {
        const rateInSelectedCurrency = rule.rates[selectedCurrency];
        state.amountInSelectedCurrency = (
          action.payload * rateInSelectedCurrency
        ).toFixed(2);
      } else {
        state.amountInSelectedCurrency = "";
      }
    },
    setAmountInSelectedCurrency: (state, action) => {
      state.amountInSelectedCurrency = action.payload;
    },
    resetSellForm: (state) => {
      state.selectedWallet = "";
      state.selectedCoin = "";
      state.exchangeRateToUSD = 1;
      state.exchangeRulesToSelectedCurrency = null;
      state.coinAmount = "";
      state.amountInDollars = "";
      state.amountInSelectedCurrency = "";
      state.tradeDetails = null;

      state.status = status.IDLE;
      state.error = null;
      state.showPaymentModal = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAvailableCoins.pending, (state) => {
        state.status = status.LOADING;
      })
      .addCase(fetchAvailableCoins.fulfilled, (state, action) => {
        state.status = status.SUCCESS;
        state.availableCoins = action.payload;
      })
      .addCase(fetchAvailableCoins.rejected, (state, action) => {
        state.status = status.FAILED;
        state.error = action.error.message;
      })

      .addCase(sellCrypto.pending, (state) => {
        state.status = status.LOADING;
        state.error = null;
      })
      .addCase(sellCrypto.fulfilled, (state, action) => {
        state.status = status.SUCCESS;
        state.tradeDetails = action.payload;

        console.log("crypto sell transaction: ", action.payload);
      })
      .addCase(sellCrypto.rejected, (state, action) => {
        state.status = status.FAILED;
        state.error = action.payload;
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  setWallet,
  setCoin,
  setCoinAmount,
  setAmountInDollars,
  setAmountInSelectedCurrency,
  resetSellForm,
  setShowPaymentWallet,
} = cryptoSlice.actions;

export default cryptoSlice.reducer;
