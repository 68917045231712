import { createSlice } from '@reduxjs/toolkit'
import { getCablePlans, getDataBundles, getWifiPlans, purchaseAirtime, purchaseCable, purchaseDataBundles, purchaseElectricity, purchaseWifi } from './dashboardActions'

const initialState = {
  value: 0,
  databundles: [],
  purchaseLoader: false,
  isPurchaseSuccess: false,
  cableplans: [],
  wifiplans: []
}

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    clearPurchaseSuccess: (state)=>{
        state.isPurchaseSuccess = false
        state.databundles = []
        state.cableplans = []
        state.wifiplans = []
    }
  },
  extraReducers:{
    
    //   get data bundles
    [getDataBundles.pending]: (state) => {
     
      },
      [getDataBundles.fulfilled]: (state, { payload }) => {
         state.databundles = payload.data.categories
      },
      [getDataBundles.rejected]: (state, { payload }) => {
       
      },

    //   purchase data bundles
    [purchaseDataBundles.pending]:(state)=>{
        state.purchaseLoader = true
    },
    [purchaseDataBundles.fulfilled]:(state)=>{
        state.isPurchaseSuccess = true
        state.purchaseLoader = false
    },
    [purchaseDataBundles.rejected]:(state)=>{
        state.isPurchaseSuccess = false
        state.purchaseLoader = false
    },

    // purchase airtime
    [purchaseAirtime.pending]:(state)=>{
        state.purchaseLoader = true
    },
    [purchaseAirtime.fulfilled]:(state)=>{
        state.isPurchaseSuccess = true
        state.purchaseLoader = false
    },
    [purchaseAirtime.rejected]:(state)=>{
        state.isPurchaseSuccess = false
        state.purchaseLoader = false
    },

    // get cable plans
    [getCablePlans.pending]: (state) =>{

    },
    [getCablePlans.fulfilled]: (state, {payload}) =>{
        state.cableplans = payload.data.categories

    },
    [getCablePlans.rejected]: (state) =>{

    },
    
    // purchase cable
    [purchaseCable.pending]: (state) =>{
        state.purchaseLoader = true
    },
    [purchaseCable.fulfilled]: (state) =>{
        state.isPurchaseSuccess = true
        state.purchaseLoader = false
    },
    [purchaseCable.rejected]: (state) =>{
        state.purchaseLoader = false
    },

    // purchase elecrtricity
    [purchaseElectricity.pending]: (state) =>{
        state.purchaseLoader = true
    },
    [purchaseElectricity.fulfilled]: (state) =>{
        state.isPurchaseSuccess = true
        state.purchaseLoader = false
    },
    [purchaseElectricity.rejected]: (state) =>{
        state.purchaseLoader = false
    },

    // get wifi plans
    [getWifiPlans.pending]: (state) =>{

    },
    [getWifiPlans.fulfilled]: (state,{payload}) =>{
        state.wifiplans = payload.data.categories

    },
    [getWifiPlans.rejected]: (state) =>{
        
    },
    // wifi purchase
    [purchaseWifi.pending]: (state) =>{
        state.purchaseLoader = true
    },
    [purchaseWifi.fulfilled]: (state) =>{
        state.isPurchaseSuccess = true
        state.purchaseLoader = false
    },
    [purchaseWifi.rejected]: (state) =>{
        state.purchaseLoader = false
    }

  }
})

// Action creators are generated for each case reducer function
export const {increment, clearPurchaseSuccess } = dashboardSlice.actions

export default dashboardSlice.reducer