import React from 'react'
import mail_icon from '../../assets/icons/mail.svg'
import logo_icon from '../../assets/icons/logo_icon.svg'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
// import { resendVerifyEmail } from '../../redux/Auth/authActions'

const VerifyInfoPage = () => {
    const navigate = useNavigate()
    const {useremail} = useSelector((state) => state.auth);

    // const handleResend = () =>{
    //     dispatch(resendVerifyEmail())
    // }

    const handleBack = () =>{
         navigate('/')
    }

    return (  
        <>
             <div className='flex justify-center pt-24'>
                    <img src={logo_icon} alt="logo" />
                </div>

            <div className='max-w-lg py-10 mx-auto'>
                <div className='md:bg-[#F8F8F8] rounded-[16px] py-16 px-10 md:px-16'>
                    <div className='flex justify-center'>
                        <img src={mail_icon} alt="mail" className='' />
                    </div>
                    <div className='text-center'>
                        <h3 className='text-[#235643] font-Satoshi500 mt-4'>Verify Your Email</h3>
                        <p className='text-[#909090] text-sm mt-2'>A verification email has been sent to {useremail} <br/>
                            Check to verify your Cardvest Account.
                        </p>

                        {/* <div className='mt-8'>
                             <p className='text-[#212121] font-Satoshi400 text-sm'>Didn't get the mail? <button disabled={false} onClick={handleResend} className='text-[#235643] font-Satoshi700 disabled:text-[#235643] disabled:opacity-[0.7] underline'>Resend</button></p>
                        </div> */}

                        <div className='flex justify-center mt-10'>
                            <button onClick={handleBack}
                                className="bg-[#235643] 
                                disabled:bg-[#235643] 
                                disabled:opacity-[0.7]
                                text-sm px-10
                                rounded-[4px] py-3 text-[#fff] 
                                font-Satoshi400 hover:bg-[#235643] hover:opacity-[0.9] transition-all"
                            >
                                Go Back
                            </button>
                        </div>

                    </div>

                </div>

            </div>
        </>
    );
}
 
export default VerifyInfoPage;