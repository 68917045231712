export default function useCopyToClipboard() {
    const state = {
      copiedText: null,
    };
  
    const setCopiedText = (text) => {
      state.copiedText = text;
    };
  
    const copy = async (text) => {
      if (!navigator?.clipboard) {
        console.warn('Clipboard not supported');
        return false;
      }
  
      // Try to save to clipboard then save it in the state if it worked
      try {
        await navigator.clipboard.writeText(text);
        setCopiedText(text);
        return true;
      } catch (error) {
        console.warn('Copy failed', error);
        setCopiedText(null);
        return false;
      }
    };
  
    return [state.copiedText, copy];
  }